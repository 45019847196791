<template>
  <div class="category__widget__container" :class="{ 'is-reversed': data.section_layout === 'right' }">
    <div class="category__widget__column">
      <div class="image-wrapper banner-image">
        <nuxt-img :src="data.image" alt="category image" loading="lazy" />
      </div>
    </div>
    <div class="category__widget__column">
      <div class="banner-content-wrapper banner-content">
        <div class="content">
          <div class="cf-heading">
            <component :is="data.heading_type" class="cf-heading__heading">
              <span class="cf-heading__title" v-html="data.category_heading"></span>
              <br v-if="data.sub_heading" />
              <span v-if="data.sub_heading" class="cf-heading__subtitle" v-html="data.sub_heading"></span>
            </component>
          </div>

          <div class="desc" v-if="data.description" v-html="htmlEntitiesDecoder(data.description)"></div>

          <div class="listLinks" v-if="data.link_1 || data.link_2 || data.link_3">
            <ul>
              <li v-if="data.link_1">
                <a
                  :href="data.link_1"
                  class="sf-link btn btn-arrow btn-blank text-black align-items-center nuxt-link-active"
                >
                  {{ htmlEntitiesDecoder(data.link_1_label) }}
                </a>
              </li>
              <li v-if="data.link_2">
                <a
                  :href="data.link_2"
                  class="sf-link btn btn-arrow btn-blank text-black align-items-center nuxt-link-active"
                >
                  {{ htmlEntitiesDecoder(data.link_2_label) }}
                </a>
              </li>
              <li v-if="data.link_3">
                <a
                  :href="data.link_3"
                  class="sf-link btn btn-arrow btn-blank text-black align-items-center nuxt-link-active"
                >
                  {{ htmlEntitiesDecoder(data.link_3_label) }}
                </a>
              </li>
            </ul>
          </div>
          <div v-if="data.button_link">
            <a :href="data.button_link" class="sf-link btn btn-black nuxt-link-active" style="outline: none">
              {{ htmlEntitiesDecoder(data.button_link_label) }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useContext } from '@nuxtjs/composition-api';
import { htmlEntitiesDecoder } from '~/helpers/htmlEntitiesDecoder';

export default {
  name: 'PapierFischerWidgetBlockWidgetScategoryWidget',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    return {
      htmlEntitiesDecoder,
    };
  },
};
</script>

<style lang="scss" scoped>
.category__widget {
  &__container {
    width: 100%;
    @include for-desktop {
      display: flex;
    }

    &.is-reversed {
      @include for-desktop {
        flex-direction: row-reverse;
      }

      .category__widget {
        &__column {
          @include for-desktop {
            justify-content: flex-end;
          }

          .banner-content-wrapper {
            width: 100%;

            @include for-desktop {
              padding: 0 110px 0 0;
            }
          }
        }
      }
    }

    .banner-content-wrapper {
      max-width: 600px;

      @include for-desktop {
        padding: 0 20px 0 110px;
      }
    }
  }

  &__column {
    display: flex;
    align-items: center;
    @include for-desktop {
      width: 50%;
    }
  }
}

.banner-image {
  @include for-mobile {
    margin-bottom: 1.25rem;
  }

  img {
    max-width: 100%;
  }
}

.listLinks {
  margin-top: 30px;
  margin-bottom: 30px;
  ul {
    padding: 0;
    margin: 0;

    li {
      list-style: none;
      margin-bottom: 20px;
    }
    li:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
