<template>
  <div class="category__widget__container" v-if="product" :class="{ 'is-reversed': data.section_layout === 'right' }">
    <div class="category__widget__column">
      <div class="category__widget__product__image">
        <div class="product-image-wrapper" v-if="!loading && product">
          <nuxt-img :src="product.image.url" :alt="product.image.label" />
        </div>
        <SfSkeleton type="image" style="width: 100%; height: 65vh" v-else />
      </div>
    </div>
    <div class="category__widget__column">
      <div class="category__widget__product__info">
        <div class="banner-content-wrapper banner-content">
          <div class="content" v-if="!loading && product">
            <CustomHeading level="2" :title="product.name" :subtitle="lastCategoryOfProduct.name"></CustomHeading>

            <div>
              <span class="cat-name" v-if="product.product_top_category">
                <nuxt-link class="text-gray-200" :to="localePath(getCatLink(product.product_top_category))">
                  {{ product.category_type_main_txt || product.product_top_category.name || '' }}
                </nuxt-link>
              </span>
            </div>

            <div>
              <span class="cat-name" v-if="product.manufacturer_main_category">
                <nuxt-link class="text-gray-200" :to="localePath(getCatLink(product.manufacturer_main_category))">
                  {{ product.category_type_manufacturer_txt || product.manufacturer_main_category.name || '' }}
                </nuxt-link>
              </span>
            </div>

            <div class="description" v-html="getProdShortDescription(product)"></div>

            <div class="price-and-button">
              <SfPrice
                class="text-white"
                :regular="$fc(getProdPrice(product).regular)"
                :special="getProdPrice(product).special && $fc(getProdPrice(product).special)"
              />
              <SfButton
                :link="localePath(`/${getProdSlug(product, product.categories[0])}`)"
                type="button"
                aria-label="button"
                class="btn btn-white"
              >
                {{ $t('Produktdetails') }}
              </SfButton>
            </div>
          </div>
          <SfSkeleton type="image" style="width: 100%; height: 65vh" v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfImage, SfButton, SfSkeleton, SfPrice } from '@storefront-ui/vue';
import { useProduct, useUiHelpers } from '~/composables';
import { computed, ref, useFetch } from '@nuxtjs/composition-api';
import {
  getPrice as getProdPrice,
  getProductSku,
  getShortDescription as getProdShortDescription,
  getSlug as getProdSlug,
} from '~/modules/catalog/product/getters/productGetters';
import CustomHeading from '~/components/General/CustomHeading.vue';

export default {
  name: 'PapierFischerWidgetBlockWidgetProductDisplayWidget',
  components: {
    SfImage,
    SfButton,
    SfSkeleton,
    SfPrice,
    CustomHeading,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const { getProductList, getProductDetails, loading } = useProduct();
    const { getCatLink } = useUiHelpers();
    const product = ref(null);
    const productSku = props.data.pafi_product_sku;

    useFetch(async () => {
      if (!productSku) return;

      try {
        const fetchedProductResult = await getProductList({
          filter: {
            sku: {
              eq: productSku,
            },
          },
        });
        const productDetailsResult = await getProductDetails({
          filter: {
            sku: {
              eq: productSku,
            },
          },
        });

        const fetchedProduct = fetchedProductResult.items[0];
        const productDetails = productDetailsResult.items[0];

        // @ts-ignore
        product.value = { ...fetchedProduct, ...productDetails };
      } catch (e) {}
    });

    const lastCategoryOfProduct = computed(() => {
      let productCategories = product.value.categories;
      return productCategories[productCategories.length - 1];
    });

    return {
      lastCategoryOfProduct,
      getCatLink,
      getProdShortDescription,
      getProdPrice,
      getProductSku,
      getProdSlug,
      product,
      loading,
    };
  },
};
</script>

<style lang="scss" scoped>
.category__widget {
  &__container {
    width: 100%;

    @include for-desktop {
      display: flex;
    }

    &.is-reversed {
      @include for-desktop {
        flex-direction: row-reverse;
      }

      .category__widget {
        &__product {
          &__info {
            @include for-desktop {
              justify-content: flex-end;
            }

            .banner-content-wrapper {
              padding: 20px;
              width: 100%;

              @include for-desktop {
                padding: 0 110px 0 20px;
              }
            }
          }
        }
      }
    }

    .banner-content-wrapper {
      padding: 20px;
      max-width: 590px;

      @include for-desktop {
        padding: 0 20px 0 110px;
      }
    }

    .cat-name a:hover {
      color: #fff;
      text-decoration: none;
    }
  }

  &__column {
    @include for-desktop {
      width: 50%;
    }
  }

  &__product {
    &__info,
    &__image {
      display: flex;
      align-items: center;
    }

    &__info {
      background-color: #222222;
      color: #fff;
      height: 100%;

      .cat-name {
        a {
          color: #999999;
        }
      }

      .sf-price {
        .sf-price__special {
          font-size: 30px;
          line-height: 40px;
          color: white;
        }

        .sf-price__old {
          font-size: 16px;
          line-height: 26px;
          color: #999999;
        }
      }

      .price-and-button {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;

        @include for-desktop {
          flex-direction: row;
        }

        @include for-mobile {
          .sf-button {
            margin-top: 20px;
          }
        }
      }

      .description {
        font-size: 16px;
        line-height: 26px;
        margin: 40px 0;

        ::v-deep {
          ul {
            padding-left: 20px;
          }
        }
      }
    }

    &__image {
      background-color: #333333;
      height: 100%;

      img {
        width: 100%;

        @include for-desktop {
          max-width: 700px;
        }
      }

      .product-image-wrapper {
        width: 100%;
        text-align: center;
      }
    }
  }
}

::v-deep {
  .sf-price {
    --price-regular-color: white;
    --price-regular-font-weight: 500;
    --price-regular-font-size: 40px;
    --price-regular-font-line-height: 50px;
  }
}
</style>
