<template>
  <div class="pf-owl-carousel-wrapper">
    <!-- <div class="owl-carousel owl-theme owl-main-carousel owl-loaded" ref="main-carousel">
      <slot/>
    </div> -->

    <!-- <div class="owl-carousel owl-theme owl-carousel-thumbs" v-if="config.thumbs"  ref="carousel-thumbs">
      <slot/>
    </div> -->
    <VueSlickCarousel v-bind="config">
      <slot />
    </VueSlickCarousel>
  </div>
</template>

<script>
// import "~/app/code/Magezon/Core/view/base/web/css/fontawesome5.css";

// import carousel from 'vue-owl-carousel';

import { defineComponent, ref } from '@nuxtjs/composition-api';

import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default defineComponent({
  name: 'PfOwlCarousel',
  components: {
    VueSlickCarousel,
  },

  props: {
    config: {
      type: Object,
    },
  },

  // watch: {
  //   'mainContainer.owlCarousel': function () {
  //     console.log('asd')
  //   }
  // },

  setup(props) {
    const isVisible = ref('hidden');

    return {
      isVisible,
    };
  },
  mounted() {
    // let mainContainer = this.$refs['main-carousel'];
    // let mainContainer = document.querySelector(".pf-owl-carousel-wrapper .owl-carousel.owl-main-carousel");
    // let main = {};

    // if (mainContainer && mainContainer.length && mainContainer.owlCarousel) {
    // main = mainContainer.owlCarousel({
    //   nav: true,
    //   dots: !this.config.thumbs,
    //   items: 1,
    //   navText: [
    //     `<i class='fas mgz-fa-angle-left' />`,
    //     `<i class='fas mgz-fa-angle-right' />`,
    //   ],
    //   ...this.config
    // });

    // main.on('initialize.owl.carousel', (event) => this.$emit('initialize', event))
    // main.on('initialized.owl.carousel', (event) => this.$emit('initialized', event))
    // main.on('resize.owl.carousel', (event) => this.$emit('resize', event))
    // main.on('resized.owl.carousel', (event) => this.$emit('resized', event))
    // main.on('refresh.owl.carousel', (event) => this.$emit('refresh', event))
    // main.on('refreshed.owl.carousel', (event) => this.$emit('refreshed', event))
    // main.on('drag.owl.carousel', (event) => this.$emit('drag', event))
    // main.on('dragged.owl.carousel', (event) => this.$emit('dragged', event))
    // main.on('translate.owl.carousel', (event) => this.$emit('translate', event))
    // main.on('translated.owl.carousel', (event) => this.$emit('translated', event))
    // main.on('change.owl.carousel', (event) => this.$emit('change', event))
    // main.on('changed.owl.carousel', (event) => this.$emit('changed', event))
    // main.on('next.owl.carousel', (event) => this.$emit('next', event))
    // main.on('prev.owl.carousel', (event) => this.$emit('prev', event))
    // main.on('to.owl.carousel', (event) => this.$emit('to', event))
    // main.on('destroy.owl.carousel', (event) => this.$emit('translated', event))
    // main.on('replace.owl.carousel', (event) => this.$emit('replace', event))
    // main.on('add.owl.carousel', (event) => this.$emit('add', event))
    // main.on('remove.owl.carousel', (event) => this.$emit('next', event))

    // main.on('load.owl.lazy', (event) => this.$emit('load', event))
    // main.on('loaded.owl.lazy', (event) => this.$emit('loaded', event))
    // main.on('play.owl.autoplay', (event) => this.$emit('play', event))
    // main.on('stop.owl.autoplay', (event) => this.$emit('stop', event))
    // main.on('play.owl.video', (event) => this.$emit('add', event))
    // main.on('stop.owl.video', (event) => this.$emit('add', event))
    // }

    // let thumbsContainer = document.querySelector(this.$refs['carousel-thumbs']);
    // if (this.config.thumbs && thumbsContainer.length && thumbsContainer.owlCarousel) {
    //   const thumbs = thumbsContainer.owlCarousel({
    //     dots: false,
    //     nav: true,
    //     margin: 4,
    //     items: 3,
    //   });

    //   const items = thumbs.find('.owl-item');
    //   for (let i = 0; i < items.length; i++) {
    //     const item = items[i];
    //     (function (item, index) {
    //       // $(item).on('click', function () {
    //       //   main.trigger("to.owl.carousel", [index])
    //       // })
    //     })(item, i)
    //   }
    // }

    this.isVisible = 'visible';
  },
});
</script>

<style lang="scss" scoped>
.item-video {
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: #222222;
}

::v-deep {
  .owl-main-carousel {
    width: 100%;
    .owl-video-wrapper {
      height: auto;
      flex: 1 1 0;

      iframe {
        & ~ iframe {
          display: none;
        }
      }
    }

    .owl-nav {
      .owl {
        &-next,
        &-prev {
          background: var(--owl-background-color, transparent);
          color: var(--owl-color, #222222);

          &:hover {
            background: var(--owl-hover-background-color, #869791);
            color: var(--owl-hover-color, #ffffff);
          }
        }
      }
    }

    .owl-dots {
      margin-top: 10px;
      .owl-dot {
        span {
          background: var(--owl-background-color, #d6d6d6);
        }

        &:hover {
          span {
            background: var(--owl-hover-background-color, #869791);
          }
        }

        &.active {
          span {
            background: var(--owl-active-background-color, #869791);
          }
        }
      }
    }
  }

  .owl-carousel-thumbs {
    margin: 0 auto;
    max-width: 400px;
    padding: 0 15px;

    .owl-nav {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 0;
      margin-top: -15px;

      .owl-prev {
        float: left;
      }

      .owl-next {
        float: right;
      }
    }

    .owl-video-wrapper {
      display: none;
    }

    .item-video {
      width: 100%;
    }

    .owl-item {
      background-color: #222222;
      height: 100px;
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .owl-video-play-icon {
        margin: 0;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0.5);
      }
    }
  }
}
</style>
