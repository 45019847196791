export default `
mutation postContactForm(
      $name: String!
      $email: String!
      $telephone: String
      $comment: String!
      $topic: String
    ) {
    contactUs(input: {
        name: $name
        email: $email
        telephone: $telephone
        comment: $comment
        topic: $topic
    }) {
        status
    }
}`;
