export default `
mutation setPaymentMethod($cartId: String! $stateData: String!) {
  setPaymentMethodOnCart(
    input: {
      cart_id: $cartId
      payment_method: {
        code:"adyen_hpp",
        adyen_additional_data_hpp: {
          brand_code:"applepay",
          stateData: $stateData
        }
      }
    }
  ) {
    cart {
      selected_payment_method {
        code
        title
      }
    }
  }
  placeOrder(
    input: {
      cart_id: $cartId
    }
  ) {
    order {
      order_id
      adyen_payment_status {
        isFinal
        resultCode
        additionalData
        action
      }
    }
  }
}
`;
