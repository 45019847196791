var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeTabs",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],attrs:{"id":_vm.data.el_id || _vm.data.id}},[_c('PfTabs',{attrs:{"position":_vm.data.tab_position,"align":_vm.parseAlignment(_vm.data.tab_align),"active":_vm.data.active_tab,"titles":_vm.tabTitles,"mobile-accordion":_vm.data.mobile_accordion,"active-on-hover":_vm.data.hover_active,"no-fill-content-area":_vm.data.no_fill_content_area},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.data.elements),function(item,index){return _c('PfTabContent',{key:item.id,class:[
          item.el_class,
          item.animation_in ? ("animate__animated animate__" + (item.animation_in) + " animate__delay-1s") : '',
          item.animation_delay ? ("animate__delay-" + (item.animation_delay) + "s") : '',
          item.animation_infinite ? "animate__infinite" : '' ],attrs:{"index":index}},_vm._l((item.elements),function(element,key){return _c('LazyHydrate',{key:key,attrs:{"when-visible":""}},[_c('MagContentRenderer',{class:element.el_inner_class,attrs:{"data":element}})],1)}),1)})},proxy:true},{key:"mobile",fn:function(){return _vm._l((_vm.data.elements),function(item){return (!_vm.data.hide_empty_tab || item.elements.length)?_c('SfAccordionItem',{key:item.id,staticClass:"accordion-item",class:[
          item.el_class,
          item.animation_in ? ("animate__animated animate__" + (item.animation_in) + " animate__delay-1s") : '',
          item.animation_delay ? ("animate__delay-" + (item.animation_delay) + "s") : '',
          item.animation_infinite ? "animate__infinite" : '' ],attrs:{"header":item.title,"id":item.el_id},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var header = ref.header;
        var isOpen = ref.isOpen;
        var accordionClick = ref.accordionClick;
return [_c('div',{staticClass:"accordion-heading mgz-element",class:{ 'is-active': isOpen },on:{"click":accordionClick}},[(item.add_icon && item.icon_position === 'left')?[_c('i',{class:item.icon})]:_vm._e(),_vm._v("\n            "+_vm._s(header)+"\n            "),(item.add_icon && item.icon_position !== 'left')?[_c('i',{class:item.icon})]:_vm._e(),_vm._v(" "),_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"icon-chevron-down"})])],2)]}}],null,true)},[_vm._v(" "),_c('div',{staticClass:"accordion-body"},_vm._l((item.elements),function(element,key){return _c('LazyHydrate',{key:key,attrs:{"when-visible":""}},[_c('MagContentRenderer',{class:item.el_inner_class,attrs:{"data":element}})],1)}),1)]):_vm._e()})},proxy:true}])},_vm._l((_vm.data.elements),function(item,index){return (!_vm.data.hide_empty_tab || item.elements.length)?_c('PfTabTitle',{key:item.id,attrs:{"title":item.title,"index":index,"id":item.el_id || item.id},scopedSlots:_vm._u([{key:"title",fn:function(ref){
        var title = ref.title;
        var isOpen = ref.isOpen;
        var tabClick = ref.tabClick;
        var tabHover = ref.tabHover;
return [_c('div',{staticClass:"pf-tabs__title",class:{ 'is-active': isOpen },on:{"click":tabClick,"mouseenter":tabHover}},[(item.add_icon && item.icon_position === 'left')?[_c('i',{class:item.icon})]:_vm._e(),_vm._v("\n          "+_vm._s(title)+"\n          "),(item.add_icon && item.icon_position !== 'left')?[_c('i',{class:item.icon})]:_vm._e()],2)]}}],null,true)}):_vm._e()}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }