<template>
  <div
    class="MagCeGmap"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      '--mag-map-width': getPixelValue(data.map_width),
      '--mag-map-height': getPixelValue(data.map_height),
      '--mag-map-label-background': data.infobox_background_color,
      '--mag-map-label-color': data.infobox_text_color,

      ...containerStyles,
    }"
  >
    <template v-if="center">
      <GMap
        ref="gMap"
        language="de"
        :center="{ lat: parseFloat(center.lat), lng: parseFloat(center.lng) }"
        :zoom="Number(data.map_zoom)"
        :options="mapOptions"
      >
        <GMapMarker
          v-for="location in data.items"
          :key="location.id"
          :position="{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) }"
          :options="{ icon: location.image ? baseUrl + location.image : '' }"
        >
          <GMapInfoWindow
            v-if="data.infobox_opened"
            :style="{
              width: data.infobox_width,
            }"
          >
            {{ location.info }}
          </GMapInfoWindow>
        </GMapMarker>
      </GMap>
    </template>
  </div>
</template>
<script>
import 'animate.css';
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeGMaps',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    const getPixelValue = (num) => {
      if (Number.isInteger(num) || !isNaN(num)) return num + 'px';
      return num;
    };

    return {
      getPixelValue,
      baseUrl,
    };
  },
  computed: {
    center() {
      return this.data.items.find((x) => x.center) || null;
    },
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-ce-map-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-ce-map-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-ce-map-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-ce-map-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-ce-map-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-ce-map-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-ce-map-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-ce-map-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-ce-map-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-ce-map-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-ce-map-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-ce-map-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-ce-map-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-ce-map-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-ce-map-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-ce-map-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-ce-map-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-ce-map-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-ce-map-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-ce-map-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-ce-map-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-ce-map-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-ce-map-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-ce-map-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-ce-map-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-ce-map-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-ce-map-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
    mapOptions() {
      let options = {
        mapTypeId: this.data.map_type,
        disableDefaultUI: this.data.map_ui,
        scrollwheel: this.data.map_scrollwheel,
        gestureHandling: this.data.map_draggable ? 'cooperative' : 'none',
      };

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.MagCeGmap {
  @include DesignOptions(map);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(map, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(map, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(map, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(map, -xs_);
  }

  @media (max-width: $lg) {
    margin: 20px 0;
  }
}

::v-deep {
  .GMap__Wrapper {
    width: var(--mag-map-width, 100%);
    height: var(--mag-map-height);
    max-width: 100%;
  }

  .gm-style-iw {
    padding: 10px !important;
  }

  .gm-style-iw-d {
    padding: 0 !important;
    overflow: visible !important;
    max-height: none !important;
    font-size: 13px;
  }

  .gm-style-iw,
  .gm-style-iw-d,
  .poi-info-window div,
  .poi-info-window a {
    background: var(--mag-map-label-background, #fff);
    color: var(--mag-map-label-color, #333);
  }

  .gm-ui-hover-effect {
    color: var(--mag-map-label-color, #333);
  }

  .gm-style-iw-tc {
    &:after {
      background: var(--mag-map-label-background, #fff);
    }
  }
}
</style>
