var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeImageCarousel",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, _vm.containerStyles)),attrs:{"id":_vm.data.id}},[_c('SfModal',{style:({
      '--modal-content-padding': 0,
      '--modal-index': 9999,
    }),attrs:{"title":_vm.selectedSlide.popup_title,"visible":_vm.isImageModalOpened,"persistent":false,"overlay":""},on:{"close":function($event){_vm.isImageModalOpened = false}}},[(_vm.selectedSlide.popup_title)?_c('div',{staticClass:"img_popup_title"},[_vm._v("\n      "+_vm._s(_vm.selectedSlide.popup_title)+"\n    ")]):_vm._e(),_vm._v(" "),_c('nuxt-img',{style:({ width: '100%' }),attrs:{"src":("" + (_vm.baseUrl + _vm.selectedSlide.popup_image)),"alt":_vm.selectedSlide.popup_title}})],1),_vm._v(" "),(_vm.data.title)?_c('div',{staticClass:"pf-separator-content",style:({
      '--divider-border-position': _vm.data.line_position === 'bottom' ? 'end' : 'center',
      '--mag-image-carousel-widget-align': _vm.data.title_align,
    })},[(_vm.data.title_align !== 'left' && _vm.data.show_line)?_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-weight': _vm.data.line_weight,
      })}):_vm._e(),_vm._v(" "),_c(_vm.data.title_tag,{tag:"component",staticClass:"pf-separator-label",class:_vm.data.el_inner_class},[_c('div',{staticClass:"mag-ce-image-carousel-title"},[_vm._v(_vm._s(_vm.data.title))]),_vm._v(" "),_c('div',{staticClass:"mag-ce-image-carousel-description"},[_vm._v("\n        "+_vm._s(_vm.data.description)+"\n      ")])]),_vm._v(" "),(_vm.data.title_align !== 'right' && _vm.data.show_line)?_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-weight': _vm.data.line_weight,
      })}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('PfOwlCarousel',{class:[
      ("mgz-carousel-nav-size-" + (_vm.data.owl_nav_size)),
      ("mgz-carousel-nav-position-" + (_vm.data.owl_nav_position)),
      _vm.data.el_inner_class,
      _vm.extraClasses ],style:({
      '--owl-color': _vm.data.owl_color,
      '--owl-background-color': _vm.data.owl_background_color,
      '--owl-hover-color': _vm.data.owl_hover_color,
      '--owl-hover-background-color': _vm.data.owl_hover_background_color,
      '--owl-active-color': _vm.data.owl_active_color,
      '--owl-active-background-color': _vm.data.owl_active_background_color,
      '--owl-margin': ((_vm.data.owl_margin) + "px") || '0px',
    }),attrs:{"config":{
      dots: _vm.data.owl_dots || false,
      infinite: _vm.data.owl_loop || false,
      speed: 500,
      autoplay: _vm.data.owl_autoplay || false,
      autoplaySpeed: _vm.data.owl_autoplay_speed || 2000,
      slidesToShow: Number(_vm.data.owl_item_xl) || 5,
      slidesToScroll: Number(_vm.data.owl_slide_by) || 1,
      arrows: _vm.data.owl_nav || false,
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            slidesToShow: _vm.data.owl_item_lg || _vm.data.owl_item_xl || 5,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: _vm.data.owl_item_md || _vm.data.owl_item_lg || _vm.data.owl_item_xl || 5,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: _vm.data.owl_item_sm || _vm.data.owl_item_md || _vm.data.owl_item_lg || _vm.data.owl_item_xl || 5,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow:
              _vm.data.owl_item_xs || _vm.data.owl_item_sm || _vm.data.owl_item_md || _vm.data.owl_item_lg || _vm.data.owl_item_xl || 5,
          },
        } ],
    }},on:{"changed":_vm.changeSlide}},[_vm._l((_vm.data.items),function(item,index){return [_c('div',{key:index,staticClass:"slider-item",style:(Object.assign({}, _vm.position(_vm.data)))},[_c(item.custom_link ? 'a' : 'div',_vm._b({tag:"component",staticClass:"slider-item__content",on:{"click":function($event){return _vm.openImageModal(item, !item.custom_link)}}},'component',_vm.paths[index],false),[_c('div',{staticClass:"slider-item__content__outer",style:({
              '--mag-ce-image-slider-item-padding': _vm.data.content_padding,
              '--mag-ce-image-slider-item-width': _vm.data.content_fullwidth ? '100%' : 'auto',
              '--mag-ce-image-slider-item-color': _vm.data.content_color,
              '--mag-ce-image-slider-item-background-color': _vm.data.content_background,
              '--mag-ce-image-slider-item-border-width': _vm.data.image_border_width,
              '--mag-ce-image-slider-item-border-radius': _vm.data.image_border_radius,
              '--mag-ce-image-slider-item-border-style': _vm.data.image_border_style,
              '--mag-ce-image-slider-item-border-color': _vm.data.image_border_color,
            })},[_c('div',{staticClass:"slider-item__content__inner",class:[_vm.data.hover_effect]},[_c('div',{staticClass:"slide-item-background"},[_c('nuxt-img',{attrs:{"src":("" + (_vm.baseUrl + item.image)),"alt":""}})],1),_vm._v(" "),(_vm.data.content_position !== 'below')?_c('div',{staticClass:"slider-item__content__wrapper",class:{
                  'display-on-hover': _vm.data.display_on_hover,
                }},[(item.title)?_c('div',{staticClass:"slider-item__heading",style:({
                    '--mag-ce-image-slider-item-title-font-size': _vm.data.title_font_size,
                    '--mag-ce-image-slider-item-title-font-weight': _vm.data.title_font_weight,
                  })},[_vm._v("\n                  "+_vm._s(item.title)+"\n                ")]):_vm._e(),_vm._v(" "),(item.description)?_c('div',{staticClass:"slider-item__description",style:({
                    '--mag-ce-image-slider-item-description-font-size': _vm.data.description_font_size,
                    '--mag-ce-image-slider-item-description-font-weight': _vm.data.description_font_weight,
                  })},[_vm._v("\n                  "+_vm._s(item.description)+"\n                ")]):_vm._e()]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"slider-item__content__overlay",style:({
              '--mag-ce-image-slider-item-overlay': _vm.data.overlay_color,
            })})]),_vm._v(" "),(_vm.data.content_position === 'below')?_c('div',{staticClass:"slider-item__wrapper",class:{
            'display-on-hover': _vm.data.display_on_hover,
          }},[(item.title)?_c('div',{staticClass:"slider-item__heading",style:({
              '--mag-ce-image-slider-item-title-font-size': _vm.data.title_font_size,
              '--mag-ce-image-slider-item-title-font-weight': _vm.data.title_font_weight,
            })},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")]):_vm._e(),_vm._v(" "),(item.description)?_c('div',{staticClass:"slider-item__description",style:({
              '--mag-ce-image-slider-item-description-font-size': _vm.data.description_font_size,
              '--mag-ce-image-slider-item-description-font-weight': _vm.data.description_font_weight,
            })},[_vm._v("\n            "+_vm._s(item.description)+"\n          ")]):_vm._e()]):_vm._e()],1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }