<template>
  <div class="iconTextWrap" :class="{ mobileAccord: mobileAccord, open: openAccord }">
    <div class="iconWrap" v-if="data.image">
      <nuxt-img :src="data.image" alt="" loading="lazy" />
    </div>
    <div class="txtWrap">
      <div class="heading" @click="toggleAccord()">
        <component
          :is="`${data.heading_type}`"
          class="cf-heading__heading mb-lg-3"
          v-if="data.heading"
          v-html="data.heading"
        ></component>
      </div>

      <div class="descWrap" v-if="data.description" v-html="htmlEntitiesDecoder(data.description)"></div>

      <div class="link">
        <a class="btn btn-arrow btn-blank text-black align-items-center" :href="data.link" v-if="data.link">{{
          data.link_label ? data.link_label : 'Mehr erfahren'
        }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { useContext, ref } from '@nuxtjs/composition-api';
import { htmlEntitiesDecoder } from '~/helpers/htmlEntitiesDecoder';

export default {
  name: 'PapierFischerWidgetBlockWidgetIconboxWidget',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const { app } = useContext();
    // @ts-ignore
    const mobileAccord = ref(props.data.mobile_accordion == 'yes');
    const openAccord = ref(false);

    const toggleAccord = function () {
      if (!mobileAccord) return;

      if (!app.$device.isMobile) return;

      openAccord.value = !openAccord.value;
    };

    return {
      mobileAccord,
      openAccord,
      toggleAccord,
      htmlEntitiesDecoder,
    };
  },
};
</script>

<style lang="scss" scoped>
.iconTextWrap {
  margin-right: 45px;
  height: 100%;

  &.mobileAccord {
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media (min-width: $md) {
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .txtWrap {
      .heading {
        .cf-heading__heading {
          position: relative;

          &::after {
            font-family: '#{$icomoon-font-family}' !important;
            speak: never;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            font-size: 1.25rem;
            content: $icon-arrow-down;
            position: absolute;
            right: 0;
            top: 0;
            display: block;
          }
        }
      }
    }

    @media (max-width: $md) {
      .txtWrap {
        .heading {
          ~ p {
            padding-top: 30px;

            &:last-child {
              padding-top: 0;
            }
          }
        }
      }

      .iconWrap {
        display: none;

        img {
          width: 100%;
        }
      }

      .txtWrap {
        p,
        .descWrap,
        .link {
          display: none;
        }
      }
    }

    @media (min-width: $md) {
      .txtWrap {
        .heading {
          .cf-heading__heading {
            margin-bottom: 30px;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: $md) {
    margin-right: 0;
    height: auto;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $border-color;

    &.open {
      .iconWrap {
        display: block;
      }

      .txtWrap {
        .heading {
          .cf-heading__heading {
            &::after {
              transform: rotate(180deg);
            }
          }
        }

        p,
        .descWrap,
        .link {
          display: block;
        }
      }
    }
  }
}

.iconWrap {
  margin-bottom: 20px;

  img {
    max-width: 100%;
  }
}

.txtWrap {
  height: calc(100% - 45px);
  display: flex;
  flex-direction: column;

  p:last-child {
    margin-top: auto;
    margin-bottom: 0;
  }

  @media (max-width: $md) {
    height: inherit;
    display: block;

    p:last-child {
      margin-top: inherit;
    }
  }
  a {
    margin: 10px 0;
  }
}
</style>
