export default `
query getCategoryMetaData($uid: String!){
  categories(
    filters: {
      category_uid: {eq: $uid}
    }
  ) {
    items {
      meta_title
      meta_description
      meta_keywords
      canonical_url
      meta_robots

      mw_canonical_url {
          url
          code
      }
      mw_hreflangs {
        items {
          url
          code
        }
      }

      mw_seo_markup {
        social_markup
        rich_snippets {
            website
            seller
        }

      }
    }
  }
}`;
