var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeTabs",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, _vm.containerStyles(_vm.data, 'icon-list'))),attrs:{"id":_vm.data.el_id || _vm.data.id}},[_c('div',{staticClass:"pf-list",class:[_vm.data.el_inner_class, ("orientation__" + (_vm.data.layout))],style:({
      '--mag-ce-icon-list-spacing': _vm.data.spacing,
      '--mag-ce-icon-list-icon-size': _vm.data.icon_size,
      '--mag-ce-icon-list-text-size': _vm.data.text_size,
      '--mag-ce-icon-list-text-font-weight': _vm.data.text_font_weight,
      '--mag-ce-icon-list-icon-background-color': _vm.data.icon_background_color,
      '--mag-ce-icon-list-icon-hover-background-color': _vm.data.icon_hover_background_color,
      '--mag-ce-icon-list-icon-color': _vm.data.icon_color || '#E31927',
      '--mag-ce-icon-list-icon-hover-color': _vm.data.icon_hover_color,
      '--mag-ce-icon-list-text-color': _vm.data.text_color,
      '--mag-ce-icon-list-text-hover-color': _vm.data.text_hover_color,
    })},_vm._l((_vm.data.items),function(item,idx){return _c('div',{key:idx,staticClass:"pf-list__item"},[_c(_vm.link(item).url ? 'a' : 'div',_vm._b({tag:"component"},'component',_vm.link(item),false),[_c('i',{staticClass:"pf-list__icon",class:item.icon}),_vm._v("\n        "+_vm._s(item.link_text)+"\n      ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }