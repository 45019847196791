import { defineStore } from 'pinia';
import { CategoryResult } from '~/composables';
import categoryListGql from './graphql/categoryList.gql';
import { useApi } from '~/composables';
import MagHeaderMenuGql from '~/customQueries/MagHeaderMenu.gql';
import { MagHeaderMenuQueryResponse } from '~/customQueries/types/MagHeaderMenu';

interface CategoryState {
  rawCategories: CategoryResult | null;
}

export const useCategoryStore = defineStore('category', {
  state: (): CategoryState => ({
    rawCategories: null,
  }),
  actions: {
    async load() {
      const { data }: { data: { categories?: CategoryResult } } = await this.$nuxt.app.$vsf.$magento.api.customQuery({
        query: categoryListGql,
      });
      this.rawCategories = data?.categories ?? null;
    },
  },
  getters: {
    categories(state) {
      if (state.rawCategories === null) {
        return null;
      }

      return state.rawCategories?.items[0];
    },
  },
});

interface MenuState {
  rawHtml: String | null;
}

export const useMenuStore = defineStore('menu', {
  state: (): MenuState => ({
    rawHtml: null,
  }),
  actions: {
    async load() {
      const { query } = useApi();
      const { data } = await query<MagHeaderMenuQueryResponse>(MagHeaderMenuGql);

      if (data) {
        this.rawHtml = data.pafiMenu?.content ?? '';
      }
    },
  },
  getters: {
    menu(state) {
      if (state.rawHtml === null) {
        return null;
      }

      return state.rawHtml;
    },
  },
});
