export default `
query ($sku:String!) {
    products(
        filter: {
            sku: {eq: $sku},
        }
    ) {
        items {
            product_top_category
            
            categories {
                uid
                name
            }
            breadcrumbs {
                category_id
                category_uid
                category_level
                category_name
                category_url_key
                category_url_path
            }
        }
    }
}`;
