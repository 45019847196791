import { render, staticRenderFns } from "./CurrencySelector.vue?vue&type=template&id=7a405174&scoped=true&"
import script from "./CurrencySelector.vue?vue&type=script&lang=ts&"
export * from "./CurrencySelector.vue?vue&type=script&lang=ts&"
import style0 from "./CurrencySelector.vue?vue&type=style&index=0&id=7a405174&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a405174",
  null
  
)

export default component.exports