import { useContext, useRoute, useRouter } from '@nuxtjs/composition-api';
import type { CategoryTree, AggregationOption } from '~/modules/GraphQL/types';
import type { UseUiHelpersInterface } from '~/composables';
import type { Params, QueryParams, FilterParams, ChangeFilterParams } from './Params';
import type { FacetInterface } from '~/modules/catalog/category/types';

import { useUrlData } from '~/stores/urldata';
import { useFilterdata } from '~/stores/filterdata';

const nonFilters = new Set(['page', 'sort', 'term', 'itemsPerPage']);

function reduceFilters(query: QueryParams) {
  return (prev: FilterParams, curr: string): FilterParams => {
    const makeArray = Array.isArray(query[curr]) || nonFilters.has(curr);

    return {
      ...prev,
      [curr]: makeArray ? (query[curr] as string[]) : [query[curr] as string],
    };
  };
}

/**
 * Allows handling the parameters for filtering,
 * searching, sorting and pagination in the URL search/query params.
 *
 * See the {@link UseUiHelpersInterface} for a list of methods and values available in this composable.
 */
export function useUiHelpers(): UseUiHelpersInterface {
  const route = useRoute();
  const router = useRouter();
  let { query: routerQuery } = route.value;
  const context = useContext();
  const urlData = useUrlData();
  const filterStore = useFilterdata();

  const resolveQuery = (): QueryParams => {
    if (typeof window !== 'undefined') {
      routerQuery = router.resolve((window.location.pathname + window.location.search).slice(1)).route.query;
    }

    return routerQuery;
  };

  const getBaseUrl = (): String => {
    // @ts-ignore
    const { magentoBaseUrl } = context.$vsf.$magento.config;
    return magentoBaseUrl;
  };

  const getFiltersDataFromUrl = (onlyFilters = false): FilterParams => {
    // const currentQuery = resolveQuery();
    let filterData = null;
    let resultData = {};

    try {
      filterData = filterStore.filterData;
      if (filterData) {
        for (let key in filterData) {
          if (filterData[key] && filterData[key].length > 0) {
            if (!(key in resultData)) {
              resultData[key] = [];
            }
            filterData[key].forEach((el) => {
              resultData[key].push(el.value);
            });
          }
        }
      }
    } catch (e) {}

    return resultData;
  };

  const getFacetsFromURL = (): Params => {
    const currentQuery = resolveQuery();

    let sortData = filterStore.sortData;
    let sortParamData = '';
    if (sortData) {
      sortParamData = sortData.sort_by + '_' + sortData.sort_direction.toUpperCase();
    }

    return {
      filters: getFiltersDataFromUrl(true),
      itemsPerPage: Number.parseInt(currentQuery.itemsPerPage, 10) || 10,
      page: Number.parseInt(currentQuery.page, 10) || 1,
      sort: sortParamData,
      term: currentQuery.term,
    };
  };

  const changeSearchTerm = (term: string) => term;

  const getSearchTermFromUrl = (): Params => {
    const currentQuery = resolveQuery();

    return {
      page: Number.parseInt(currentQuery.page, 10) || 1,
      sort: currentQuery.sort ?? '',
      filters: getFiltersDataFromUrl(true),
      itemsPerPage: Number.parseInt(currentQuery.itemsPerPage, 10) || 10,
      term: currentQuery.term,
    };
  };

  const getCatLink = (category: CategoryTree): string => `/${category.url_path}${category.url_suffix || ''}`;

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param sort
   * @param forcePush
   */
  const changeSorting = async (sort: string, forcePush = true): Promise<void> => {
    if (sort && sort.indexOf('_') !== -1) {
      let sortPart = sort.toLowerCase().split('_');
      let sortObj = {
        sort_by: sortPart[0],
        sort_direction: sortPart[1],
      };
      filterStore.setSortData(sortObj);
    } else {
      filterStore.setSortData(null);
    }

    pushNewBuildUrl(forcePush);
    // if (forcePush) {
    //   await router.push({ query: { ...routerQuery, sort } });
    // } else {
    //   let query =  { ...routerQuery, sort };
    //   const routeData = router.resolve({
    //     path: window.location.pathname,
    //     query: {
    //       // ...getFiltersDataFromUrl(),
    //       sort,
    //     },
    //   });
    //   window.history.pushState({}, null, routeData.href);
    // }
  };

  const transformUrlString = (str: string): string => {
    const rules = [
      { symbol: "\\", substitute: "" },
      { symbol: "_", substitute: "-" },
      { symbol: "'", substitute: "" },
      { symbol: ":", substitute: "-" },
      { symbol: "%", substitute: "" },
      { symbol: "#", substitute: "" },
      { symbol: "?", substitute: "" },
      { symbol: "&", substitute: "+" },
      { symbol: " ", substitute: "-" }
    ];

    let transformedStr = str.toLowerCase();
    rules.forEach(rule => {
      const regex = new RegExp('\\' + rule.symbol, 'g');
      transformedStr = transformedStr.replace(regex, rule.substitute);
    });

    return transformedStr;
  };

  const buildFilterUrl = (additionalOption = null): string => {
    let filters = { ...filterStore.filterData };
    let sortData = filterStore.sortData;

    if (additionalOption !== null) {
      let aCode = additionalOption.attrCode;
      if (filters.hasOwnProperty(aCode)) {
        let hasOption = false;

        for (let i in filters[aCode]) {
          // @ts-ignore
          if (filters[aCode][i].value == additionalOption.value) {
            hasOption = true;
            break;
          }
        }

        if (!hasOption) {
          filters[aCode].push(additionalOption);
        }
      } else {
        filters[aCode] = [];
        filters[aCode].push(additionalOption);
      }
    }
    //console.log("buildFilterUrl: ", filters, additionalOption);

    let filterUrl = '';

    if (filters) {
      // sorting process
      // attribute sorting
      let filterKeys = Object.keys(filters);
      // let filterValues = Object.values(filters);

      filterKeys.sort(function (a, b) {
        // @ts-ignore
        let posA = filters[a][0].pos;
        // @ts-ignore
        let posB = filters[b][0].pos;

        if (posA == posB) {
          // @ts-ignore
          let idA = parseInt(filters[a][0].attrId);
          // @ts-ignore
          let idB = parseInt(filters[b][0].attrId);

          return idA - idB;
        }

        return posA - posB;
      });

      // values sorting
      for (let i in filters) {
        filters[i].sort(function (a, b) {
          // @ts-ignore
          return a.value - b.value;
        });
      }

      filterKeys.forEach((key) => {
        let obj = filters[key];
        let attrStr = '';
        if (obj && obj.length > 0) {
          obj.forEach((el) => {
            // @ts-ignore
            if (el.label) {
              // @ts-ignore
              attrStr += (attrStr != '' ? '-' : '') + transformUrlString(el.label);
            } else {
              // @ts-ignore
              attrStr += (attrStr != '' ? '-' : '') + el.value;
            }
          });
        }

        if (attrStr) {
          filterUrl += `/${key}/${attrStr}`;
        }
      });
    }

    if (sortData) {
      filterUrl += `/sort-by/${sortData.sort_by}/sort-direction/${sortData.sort_direction}`;
    }

    filterUrl = filterUrl.replace(/[\s_]/g, '-');
    return filterUrl;
  };

  const pushNewBuildUrl = async (forcePush = true): Promise<void> => {
    let catUrl = filterStore.catUrl;
    let filterUrl = buildFilterUrl();

    let fullUrl = catUrl.replace('.html', filterUrl) + '.html';
    fullUrl = fullUrl.replace(/[\s_]/g, '-');

    if (forcePush) {
      // await router.push({ query });
      await router.push(fullUrl);
    } else {
      // const routeData = router.resolve({ query });
      // window.history.pushState({}, null, routeData.href);

      window.history.pushState({}, null, fullUrl);
    }
  };

  const buildUrlWithAdditionalFilters = (additionalOption) => {
    let catUrl = filterStore.catUrl;
    let filterUrl = buildFilterUrl(additionalOption);

    let fullUrl = catUrl.replace('.html', filterUrl) + '.html';
    fullUrl = fullUrl.replace(/[\s_]/g, '-');

    return fullUrl;
  };

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param filters
   * @param forcePush
   */
  const changeFilters = async (filters: ChangeFilterParams, forcePush = true): Promise<void> => {
    // const query = {
    //   ...getFiltersDataFromUrl(false),
    //   ...filters,
    // };

    // build url
    let catUrl = filterStore.catUrl;
    // let filterObj = {...filters};

    let filterObj = {};
    for (let key in filters) {
      if (filters[key] && filters[key].length > 0) {
        filterObj[key] = [...filters[key]];
      }
    }

    // sort by position
    // let filterKeysData = [];
    // for (let key in filterObj) {
    //   let obj = filterObj[key];
    //   filterKeysData.push({
    //     "code" : key,
    //     // @ts-ignore
    //     "id" : obj?.[0]?.attribute_id || 0,
    //     // @ts-ignore
    //     "position" : obj?.[0]?.position || 0,
    //   });
    // }

    // console.log("filterKeysData : ", filterKeysData);

    // first sort the array
    // for (let i in filterObj) {
    //   filterObj[i].sort(function(a, b) {
    //     // @ts-ignore
    //     return a.value - b.value;
    //   });
    // }

    filterStore.setFilterData(filterObj);

    pushNewBuildUrl(forcePush);

    // let filterUrl = "";

    // if (filters) {
    //   for (let key in filters) {
    //     let obj = filters[key];
    //     let attrStr = "";
    //     if (obj && obj.length > 0) {
    //       obj.forEach(el => {
    //         // @ts-ignore
    //         if (el.label) {
    //           // @ts-ignore
    //           attrStr += ((attrStr!="") ? "-" : "") +el.label.toLowerCase();
    //         } else {
    //           // @ts-ignore
    //           attrStr += ((attrStr!="") ? "-" : "") +el.value;
    //         }
    //       });
    //     }

    //     if (attrStr) {
    //       filterUrl += `/${key}/${attrStr}`;
    //     }
    //   }
    // }

    // let filterUrl = buildFilterUrl();

    // let fullUrl = catUrl.replace(".html", filterUrl)+".html";
    // fullUrl = fullUrl.replace(/[\s_]/g, "-");

    // if (forcePush) {
    //   // await router.push({ query });
    //   await router.push(fullUrl);
    // } else {
    //   // const routeData = router.resolve({ query });
    //   // window.history.pushState({}, null, routeData.href);

    //   window.history.pushState({}, null, fullUrl);
    // }
  };

  const clearFilters = async (forcePush = true): Promise<void> => {
    let catUrl = filterStore.catUrl;
    filterStore.setFilterData(null);
    // window.history.pushState({}, null, catUrl);

    pushNewBuildUrl(forcePush);

    // if (forcePush) {
    //   await router.push({
    //     query: {},
    //   });
    // } else {
    //   const routeData = router.resolve({
    //     query: {},
    //   });
    //   window.history.pushState({}, null, routeData.href);
    // }
  };

  /**
   * Force push for a backward compatibility in other places, should be removed
   *
   * @param itemsPerPage
   * @param forcePush
   */
  const changeItemsPerPage = async (itemsPerPage: number, forcePush = true): Promise<void> => {
    const query = {
      ...getFiltersDataFromUrl(false),
      itemsPerPage: itemsPerPage.toString(10),
      page: '0',
    };

    if (forcePush) {
      await router.push({ query });
    } else {
      const routeData = router.resolve({ query });
      window.history.pushState({}, null, routeData.href);
    }
  };

  const changePage = async (page: number, forcePush = true): Promise<void> => {
    const query = {
      ...getFiltersDataFromUrl(false),
      page: page.toString(),
    };

    if (forcePush) {
      await router.push({ query });
    } else {
      const routeData = router.resolve({ query });
      window.history.pushState({}, null, routeData.href);
    }
  };

  const setTermForUrl = async (term: string): Promise<void> => {
    await router.push({
      query: {
        ...getFiltersDataFromUrl(false),
        term: term || undefined,
      },
    });
  };

  const isFacetColor = (facet: FacetInterface): boolean => facet.id === 'color' || facet.id === 'colorswatch_color';

  const isFacetCheckbox = (): boolean => false;

  return {
    changeFilters,
    changeItemsPerPage,
    changeSearchTerm,
    changeSorting,
    clearFilters,
    getCatLink,
    getFacetsFromURL,
    getSearchTermFromUrl,
    isFacetCheckbox,
    isFacetColor,
    setTermForUrl,
    changePage,
    getBaseUrl,
    buildFilterUrl,
    buildUrlWithAdditionalFilters,
  };
}

export * from './Params';
export * from './useUiHelpers';
export default useUiHelpers;
