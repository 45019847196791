var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeGmap",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, {'--mag-map-width': _vm.getPixelValue(_vm.data.map_width),
    '--mag-map-height': _vm.getPixelValue(_vm.data.map_height),
    '--mag-map-label-background': _vm.data.infobox_background_color,
    '--mag-map-label-color': _vm.data.infobox_text_color},

    _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[(_vm.center)?[_c('GMap',{ref:"gMap",attrs:{"language":"de","center":{ lat: parseFloat(_vm.center.lat), lng: parseFloat(_vm.center.lng) },"zoom":Number(_vm.data.map_zoom),"options":_vm.mapOptions}},_vm._l((_vm.data.items),function(location){return _c('GMapMarker',{key:location.id,attrs:{"position":{ lat: parseFloat(location.lat), lng: parseFloat(location.lng) },"options":{ icon: location.image ? _vm.baseUrl + location.image : '' }}},[(_vm.data.infobox_opened)?_c('GMapInfoWindow',{style:({
            width: _vm.data.infobox_width,
          })},[_vm._v("\n          "+_vm._s(location.info)+"\n        ")]):_vm._e()],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }