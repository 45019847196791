<template>
  <div>
    <VueSlickCarousel
      v-bind="slideSettings"
      ref="slick"
      class="content-slider"
      :class="[data.image_hover_effect ? `mgz-carousel-hover-effect-${data.image_hover_effect} ` : '']"
    >
      <div class="slide-item" v-for="(item, index) in data.items" :key="item.heading">
        <component :is="getWrapperComponent(item)" v-bind="link(item.slide_link)">
          <figure class="image mb-0">
            <nuxt-img
              :src="`${baseUrl + item.image}`"
              :alt="item.type"
              class="slider-img"
              :loading="index > 0 ? 'lazy' : null"
              :preload="index === 0"
              width="1030"
              height="600"
            />
          </figure>
          <div class="content-slider--content" :style="contentStyling(item)">
            <component v-if="item.heading" :is="item.heading_type" :style="{ color: item.heading_color }">
              {{ item.heading }}
            </component>

            <component v-if="item.caption1" :is="item.caption1_type" :style="{ color: item.caption1_color }">
              {{ item.caption1 }}
            </component>

            <component v-if="item.caption2" :is="item.caption2_type" :style="{ color: item.caption2_color }">
              {{ item.caption2 }}
            </component>

            <p class="button-wrapper" v-if="item.button1 || item.button2">
              <a
                :href="item.button1_link ? link(item.button1_link).href : ''"
                v-bind="link(item.button1_link)"
                v-if="item.button1_link"
                class="btn d-inline-block btn-primary"
              >
                {{ item.button1 }}
              </a>
              <a
                :href="item.button2_link ? link(item.button2_link).href : ''"
                v-bind="link(item.button2_link)"
                v-if="item.button2_link"
                class="btn d-inline-block btn-secondary"
              >
                {{ item.button2 }}
              </a>
            </p>
          </div>
        </component>
      </div>

      <template #customPaging="page">
        <div class="custom-dot">
          <span v-if="page.length >= 10">
            {{ page + 1 }}
          </span>
          <span v-else> 0{{ page + 1 }} </span>
        </div>
      </template>
    </VueSlickCarousel>

    <template v-if="data.owl_nav">
      <div class="content-slider--controls-wrapper">
        <div class="content-slider--controls prev">
          <button type="button" @click="showPrev()">
            <span class="button-text"> Zurück </span>
            <i class="icon-chevron-left"></i>
          </button>
        </div>

        <div class="content-slider--controls next">
          <button type="button" @click="showNext()">
            <span class="button-text"> Weiter </span>
            <i class="icon-chevron-right"></i>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import 'animate.css';
import { ref, useContext } from '@nuxtjs/composition-api';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'MagCeSlider',
  components: {
    VueSlickCarousel,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    const slick = ref();

    function showPrev() {
      slick.value?.prev();
    }

    function showNext() {
      slick.value?.next();
    }

    return {
      baseUrl,
      slick,
      showPrev,
      showNext,
    };
  },
  mounted() {
    if (this.data && 'owl_loop' in this.data) {
      this.slideSettings.infinite = this.data.owl_loop == '1';
    }
    if (this.data && 'owl_autoplay' in this.data) {
      this.slideSettings.autoplay = this.data.owl_autoplay == '1';
    }
    if (this.data && 'owl_autoplay_timeout' in this.data) {
      this.slideSettings.autoplaySpeed = parseInt(this.data.owl_autoplay_timeout);
    }
    if (this.data && 'owl_autoplay_speed' in this.data) {
      this.slideSettings.speed = parseInt(this.data.owl_autoplay_speed);
    }
  },
  data() {
    return {
      slideSettings: {
        lazyLoad: 'ondemand',
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              autoplay: false,
              autoplaySpeed: 4500,
            },
          },
        ],
      },
    };
  },

  methods: {
    contentStyling(item) {
      let positionStyle = {};
      let contentPositionSplit = '';
      if ('content_position' in item) {
        contentPositionSplit = item.content_position.split('-');
      }
      if ('content_align' in item) {
        positionStyle['text-align'] = item.content_align;
      }
      if (
        contentPositionSplit.length > 0 &&
        contentPositionSplit[0] &&
        contentPositionSplit[0] != 'undefined' &&
        contentPositionSplit[1] &&
        contentPositionSplit[1] != 'undefined'
      ) {
        if (contentPositionSplit[0] != 'middle') {
          positionStyle[contentPositionSplit[0]] = '10%';
        }
        if (contentPositionSplit[1] != 'center') {
          positionStyle[contentPositionSplit[1]] = '10%';
        }
        if (contentPositionSplit[0] == 'middle' && contentPositionSplit[1] != 'center') {
          positionStyle['top'] = '50%';
          positionStyle['transform'] = 'translateY(-50%)';
        }
        if (contentPositionSplit[1] == 'center' && contentPositionSplit[0] != 'middle') {
          positionStyle[contentPositionSplit[0]] = '10%';
          positionStyle['transform'] = 'translateX(-50%)';
          positionStyle['left'] = '50%';
          positionStyle['padding'] = '0 0 0 0';
        }
        if (contentPositionSplit[0] == 'middle' && contentPositionSplit[1] == 'center') {
          positionStyle['top'] = '50%';
          positionStyle['left'] = '50%';
          positionStyle['transform'] = 'translate(-50%, -50%)';
          positionStyle['padding'] = '0 0 0 0';
        }
      }
      return positionStyle;
    },
    getWrapperComponent(item) {
      return item.slide_link ? 'a' : 'span';
    },
    link(component) {
      if (!component) return;

      const link = component;
      let linkDict = {};
      if (link) {
        // const matches = link.match(/url=".*?"|blank=\w+|title=".*?"|nofollow=\w+/gm)

        // for (let i = 0; i < matches.length; i++) {
        //   const keyValuePair = matches[i].split("=");
        //   switch (keyValuePair[0]) {
        //     case 'url':
        //       linkDict.href = JSON.parse(keyValuePair[1])
        //       break
        //     case 'blank':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.target = '_blank'
        //       break
        //     case 'nofollow':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.rel = 'nofollow'
        //       break
        //     default:
        //       linkDict[keyValuePair[0]] = keyValuePair[1].replace(/"/gi, "")
        //       break
        //   }
        // }

        linkDict.href = link?.url ?? '';
        linkDict.title = link?.title ?? '';
        linkDict.target = link?.target && link?.target == 'true' ? '_blank' : '';
        linkDict.rel = link?.nofollow && link?.nofollow == 'true' ? 'nofollow' : '';
      }

      return linkDict;
    },
  },
};
</script>

<style lang="scss" scoped>
.content-slider {
  @media (max-width: $lg) {
    // height: 250px !important;
    margin-bottom: 50px;
  }

  ::v-deep {
    .slick-track {
      display: flex !important;
      height: 100%;
    }

    .slick-list {
      height: 100%;

      .slick-slide {
        height: inherit !important;
        opacity: 0;
        transition: $transition-base;

        &.slick-current {
          opacity: 1;
        }

        > div {
          height: 100%;
        }
      }
    }

    .slick-dots {
      width: 100%;
      bottom: -44px;
      padding: 0 60px;

      li {
        margin: 0 5px;
        color: $grey-900;
        font-weight: 500;

        &.slick-active {
          color: $black;
        }
      }
    }
  }

  .slide-item {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .content-slider--content {
      @media (max-width: $lg) {
        position: relative;
        transform: translateX(0) !important;
        left: 0 !important;
      }
    }

    .image {
      height: 32.39vw;
      max-height: 37.5rem; // 600px
      width: auto;

      @media (max-width: $lg) {
        width: 100%;
        height: 100%;
      }

      .slider-img {
        height: 100%;
        width: auto;
        margin: 0 auto;
        display: block;

        @media (max-width: $lg) {
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  &--content {
    position: absolute;
    width: auto;
    z-index: 1;
    // padding: 80px 0 80px 16.67%;

    @media (max-width: $lg) {
      // padding: 80px 0 80px 20px;
    }

    @media (max-width: $sm) {
      // padding: 40px 20px;
    }
  }

  .button-wrapper {
    // display: flex;
    // flex-wrap: wrap;

    .btn {
      margin-bottom: 10px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }

  &--controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: inherit;
    padding: 20px 0;

    button {
      background: transparent;
      border: 0 none;
      padding: 0;
      font-weight: 500;

      i {
        display: none;
      }
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &-wrapper {
      position: relative;
      left: 0;
      bottom: 0;
      height: 64px;
      width: 100%;
    }
  }
}

.mgz-carousel-hover-effect {
  &-zoomin {
    .slide-item {
      transition: transform 0.4s;

      &:hover {
        transform: scale3d(1.1, 1.1, 1);
      }
    }
  }

  &-zoomout {
    .slide-item {
      transition: transform 0.4s;

      &:hover {
        transform: scale3d(0.9, 0.9, 1);
      }
    }
  }
}
</style>
