<script>
export default {
  name: 'MagContentRenderer',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    createContentElement() {
      let type = this.data.type;

      // content element are registered in PascalCase or kebab-case like "CeMyComponent" or "ce-my-component"
      // but the type is coming as "my_component" so we have to transform the name and the tag
      const typeNameCamelCase = type.replace(/_([a-z])/g, (g) => {
        return g[1].toUpperCase();
      });

      let elementTag = `mag-ce-${type.replace(/_/g, '-')}`;
      const componentName = `MagCe${typeNameCamelCase[0].toUpperCase() + typeNameCamelCase.slice(1)}`;

      // check if global component exist, if not display <mag-ce-default/>
      // if (!(this.$options.components[componentName] || this.$options.components[elementTag])) {
      if (!(this.$root.$options.components[componentName] || this.$root.$options.components[elementTag])) {
        elementTag = 'mag-ce-missing';
      }

      return [
        elementTag,
        {
          props: {
            data: this.data,
          },
        },
      ];
    },
  },
  render(createElement) {
    try {
      const [tag, data] = this.createContentElement();

      if (!data.props.data.disable_element) {
        return createElement(tag, data);
      }
    } catch (e) {
      console.log('Render Error: ', e);
    }

    return null;
  },
};
</script>
