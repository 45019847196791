export default `
query getFilterPagesSeoDataFull($id: String!, $filters: ProductAttributeFilterInput!) {
	categories(filters: {category_uid: {in: [$id]}}) {
		items {
			uid
			meta_title
			meta_keywords
			meta_description
			category_seo_name
            meta_robots
            canonical_url
		}
	}
	products(filter: $filters) {
		mw_seo_category_data {
			meta_title
			meta_description
			meta_keywords
			category_seo_name
			canonical_url
		}
	}
}`;
