export default `
query CategoryFilterData($url: String!){
    categoryFilterData(url: $url) {
        filters
        url
        category_uid
        category_id
        category_url
    }
}`;

export interface CategoryFilterDataQuery {
  categoryFilterData: {
    filters: string | null;
    url?: string | null;
    category_uid?: string | null;
    category_id?: string | null;
    category_url?: string | null;
  };
}
