<template>
  <div class="AtwixWidgetBlockWidgetSliderWidget">
    <div class="slide-skeleton" v-if="loading">
      <div class="left">
        <SfSkeleton class="headline" type="image" style="width: 400px; height: 60px" />
        <br />
        <SfSkeleton class="desc" type="image" style="width: 300px; height: 16px" />
        <SfSkeleton class="desc" type="image" style="width: 300px; height: 16px" />
        <SfSkeleton class="desc" type="image" style="width: 300px; height: 16px" />
        <br />
        <SfSkeleton type="image" style="width: 100px; height: 50px" />
      </div>
      <div class="right">
        <SfSkeleton type="image" style="width: 480px; height: 480px" />
      </div>
    </div>
    <div v-else>
      <Slide v-if="products.length" :products="products" :pageSize="PAGE_SIZE" />
    </div>
  </div>
</template>
<script>
import { defineComponent, useContext, useFetch, ref } from '@nuxtjs/composition-api';
import { useImage, useApi, useProduct } from '~/composables';
import { SfImage, SfSkeleton } from '@storefront-ui/vue';
import Slide from '~/components/HeroSlider/Slide.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import getProductSliderDataGql, { getProductSliderDataQuery } from '~/customQueries/getProductSliderData.gql';

export default defineComponent({
  name: 'AtwixWidgetBlockWidgetSliderWidget',
  components: {
    SfSkeleton,
    Slide,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const products = ref([]);
    const PAGE_SIZE = ref(0);
    const { query } = useApi();
    const loading = ref(false);

    const { getCategoriesByFilter } = useCategory();

    // const { getProductList, getProductDetails, loading } = useProduct();

    // let product_skus = ['24-WB04', 'MH11', 'MH10'];

    // async function fetchProductDetails(sku) {
    // 	const result = await getProductDetails({
    // 		filter: {
    // 			sku: {
    // 				eq: sku,
    // 			},
    // 		},
    // 	})

    // 	let product_details = result.items[0]

    // 	return product_details
    // }

    useFetch(async () => {
      let product_skus = props.data?.slider_pid ? props.data?.slider_pid?.split(',') : [];

      if (product_skus.length == 0) return;

      let queryVarData = {
        filters: {
          sku: {
            in: product_skus,
          },
        },
      };

      loading.value = true;

      try {
        const { data, errors } = await query(getProductSliderDataGql, queryVarData);

        if (data && data?.products?.items?.length > 0) {
          let tempData = data.products.items || [];
          let catIds = [];
          tempData.forEach((el) => {
            if (el.product_top_category) {
              if (!catIds.includes(el.product_top_category)) {
                catIds.push(el.product_top_category);
              }
            }
            if (el.manufacturer_main_category) {
              if (!catIds.includes(el.manufacturer_main_category)) {
                catIds.push(el.manufacturer_main_category);
              }
            }
          });

          if (catIds.length > 0) {
            let categoryIdsData = await getCategoriesByFilter(catIds);
            if (categoryIdsData && categoryIdsData.length > 0) {
              tempData.forEach((el) => {
                if (el.product_top_category) {
                  let ptcData = categoryIdsData.find((val) => val.id == el.product_top_category);
                  if (ptcData) {
                    el.product_top_category = { ...ptcData };
                  }
                }
                if (el.manufacturer_main_category) {
                  let mmcData = categoryIdsData.find((val) => val.id == el.manufacturer_main_category);
                  if (mmcData) {
                    el.manufacturer_main_category = { ...mmcData };
                  }
                }
              });
            }
          }

          PAGE_SIZE.value = tempData.length;
          products.value = tempData;
        }
      } catch (e) {
        console.log('Slider Fetch Error: ', e);
      } finally {
        loading.value = false;
      }

      // const fetchedProducts = await getProductList({
      // 	filter: {
      // 		sku: {
      // 			in: product_skus
      // 		},
      // 	},
      // });

      // if (fetchedProducts?.items?.length) {
      // 	PAGE_SIZE.value = fetchedProducts?.items?.length

      // 	for(let i in fetchedProducts.items) {
      // 		let product = fetchedProducts.items[i];
      // 		let details = await fetchProductDetails(product.sku)

      // 		products.value.push({ ...product, ...details});
      // 	}

      // 	// let productsWithDetails = Promise.all(
      // 	// 	fetchedProducts.items.map(async product =>  {
      // 	// 		let details = await fetchProductDetails(product.sku)
      // 	// 		return ({ ...product, ...details})
      // 	// 	})
      // 	// );

      // 	// productsWithDetails.then(data => {
      // 	// 	products.value = data
      // 	// });
      // }
    });
    return {
      products,
      loading,
      PAGE_SIZE,
    };
  },
});
</script>

<style scoped lang="scss">
.slide-skeleton {
  margin-top: 125px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}
@media (max-width: 1200px) {
  .slide-skeleton {
    margin-top: 75px;
  }
}
@media (max-width: 1023px) {
  .slide-skeleton {
    grid-template-columns: 1fr;
    margin-top: 0px;
    gap: 60px;

    .right {
      order: 1;
      .sf-skeleton--image {
        width: 300px !important;
        height: 300px !important;
        margin: 0 auto;
      }
    }
    .left {
      order: 2;

      .sf-skeleton--image {
      }
    }
  }
}

@media (max-width: 500px) {
  .slide-skeleton {
    .right {
      // .sf-skeleton--image {
      // 	max-width: 100% !important;
      // }
    }
    .left {
      .sf-skeleton--image.desc {
        width: 300px !important;
      }
      .sf-skeleton--image.headline {
        width: 350px !important;
      }
    }
  }
}
@media (max-width: 400px) {
  .slide-skeleton {
    .right {
      .sf-skeleton--image {
        width: 250px !important;
        height: 250px !important;
      }
    }
    .left {
      .sf-skeleton--image.desc {
        width: 180px !important;
      }
      .sf-skeleton--image.headline {
        width: 250px !important;
      }
    }
  }
}
</style>
