const fragmentCategory = `
  fragment CategoryFields on CategoryTree {
    is_anchor
    name
    position
    uid
    url_path
    url_suffix
    include_in_menu
  }
`;

// export default `
//   query categoryList {
//     categories {
//       items {
//         ...CategoryFields
//         children {
//           ...CategoryFields
//           children {
//             ...CategoryFields
//             children {
//               ...CategoryFields
//             }
//           }
//         }
//       }
//     }
//   }
//   ${fragmentCategory}
// `;

// fetching less level categories due to memory issues
export default `
  query categoryList {
    categories {
      items {
        ...CategoryFields
        children {
          ...CategoryFields
        }
      }
    }
  }
  ${fragmentCategory}
`;
