import { UseContextReturn } from '~/types/core';
import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import type { PaymentMethodParams } from '../usePaymentProvider';
import setApplePayPaymentMethodAndPlaceOrderGql from '~/customQueries/applepay/setApplePayPaymentMethodAndPlaceOrder.gql';
//import { useApi } from '~/composables';

export const setPaymentMethodOnCartCommand = {
  execute: async (context: UseContextReturn, params: PaymentMethodParams): Promise<AvailablePaymentMethod[]> => {
    //const { data } = await context.app.$vsf.$magento.api.setPaymentMethodOnCart(params, params?.customQuery ?? null);
    const { data } = await context.app.$vsf.$magento.api.customMutation({
      mutation: setApplePayPaymentMethodAndPlaceOrderGql,
      mutationVariables: params,
    });

    //const { mutate } = useApi();

    //const { data } = await mutate(setApplePayPaymentMethodAndPlaceOrderGql, params);
    console.log(data);
    // @ts-ignore
    return data.setPaymentMethodOnCart?.cart?.available_payment_methods ?? [];
  },
};
