<template>
  <div class="magCeText" :id="data.id">
    {{ data.type }}
  </div>
</template>
<script>
export default {
  name: 'MagCeWidget',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
};
</script>
