import type { ProductDetailsQuery } from '~/modules/GraphQL/types';

export default `
query getProductSliderData($filters: ProductAttributeFilterInput!){
	products(filter: $filters) {
	  items {
            uid
            sku
            name
            is_new
          	engraving_enabled
          	is_on_sale

            description {
                html
            }
            image {
                disabled
                label
                position
                url
            }

            options_container
            short_description {
                html
            }

            thumbnail {
                disabled
                label
                position
                url
            }
            price_range {
                maximum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
                minimum_price {
                    final_price {
                        currency
                        value
                    }
                    regular_price {
                        currency
                        value
                    }
                }
            }

            url_key
            url_rewrites {
                url
            }

            product_top_category
            manufacturer_main_category
            category_type_main_txt
            category_type_manufacturer_txt
        }
	}
}
`;

export type getProductSliderDataQuery = ProductDetailsQuery;
