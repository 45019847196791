var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();handleSubmit(function () {
        _vm.submit();
      })}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first_name","label":_vm.$t('First name'),"required":""},model:{value:(_vm.contact.first_name),callback:function ($$v) {_vm.$set(_vm.contact, "first_name", $$v)},expression:"contact.first_name"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-md"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last_name","label":_vm.$t('Last name'),"required":""},model:{value:(_vm.contact.last_name),callback:function ($$v) {_vm.$set(_vm.contact, "last_name", $$v)},expression:"contact.last_name"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","label":_vm.$t('Email'),"required":""},model:{value:(_vm.contact.email),callback:function ($$v) {_vm.$set(_vm.contact, "email", $$v)},expression:"contact.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"col-md"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfInput',{staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"telephone","label":_vm.$t('Phone')},model:{value:(_vm.contact.telephone),callback:function ($$v) {_vm.$set(_vm.contact, "telephone", $$v)},expression:"contact.telephone"}})]}}],null,true)})],1)]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfSelect',{attrs:{"label":_vm.$t('Your concern')},model:{value:(_vm.contact.topic),callback:function ($$v) {_vm.$set(_vm.contact, "topic", $$v)},expression:"contact.topic"}},_vm._l((_vm.reasons),function(reason,index){return _c('SfSelectOption',{key:index,attrs:{"value":_vm.$t(reason)}},[_vm._v("\n          "+_vm._s(reason)+"\n        ")])}),1)]}}],null,true)}),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('PfTextarea',{staticClass:"form__element",style:({
          '--textarea-width': '100%',
          '--textarea-height': '196px',
          '--textarea-resize': 'none',
        }),attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"comment","label":_vm.$t('Your message'),"wrap":"soft","required":""},model:{value:(_vm.contact.comment),callback:function ($$v) {_vm.$set(_vm.contact, "comment", $$v)},expression:"contact.comment"}})]}}],null,true)}),_vm._v(" "),_vm._t("error"),_vm._v(" "),_c('div',{staticClass:"checkbox-wrapper"},[_c('PfCheckbox',{attrs:{"selected":_vm.termsAccepted},on:{"change":function($event){_vm.termsAccepted = !_vm.termsAccepted}}}),_vm._v(" "),_c('label',{staticClass:"custom-label",domProps:{"innerHTML":_vm._s(_vm.$t('I agree to the terms of the privacy policy'))},on:{"click":function($event){_vm.termsAccepted = !_vm.termsAccepted}}})],1),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e(),_vm._v(" "),(_vm.errorMessage)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"actions"},[_c('SfButton',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.loading || invalid || !_vm.termsAccepted}},[_vm._v("\n        "+_vm._s(_vm.$t('Send message'))+"\n      ")])],1)],2)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }