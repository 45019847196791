<template>
  <div class="pf-tabs__trigger" :class="{ 'is-active': isActive }">
    <slot name="title" v-bind="{ tabClick, tabHover, isActive, title }">
      <SfButton class="sf-button--pure pf-tabs__title" @click="tabClick" @mouseenter="tabHover">
        {{ title }}
      </SfButton>
    </slot>
  </div>
</template>

<script>
import { SfButton } from '@storefront-ui/vue';
export default {
  name: 'PfTabTitles',
  inject: ['activeTab', 'activeOnHover'],
  components: { SfButton },
  props: {
    title: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isActive() {
      return this.activeTab.value === this.index;
    },
  },
  methods: {
    tabClick() {
      this.$parent.$emit('toggle', this.index);
    },
    tabHover() {
      if (this.activeOnHover.value) this.$parent.$emit('toggle', this.index);
    },
  },
};
</script>

<style lang="scss" scoped>
.pf-tabs {
  &__title {
    border-radius: var(--border-radius, 5px);
    background: #ebebeb;
    width: 100%;
    transition: none;
    cursor: pointer;
  }

  &__trigger {
    &.is-active {
      .pf-tabs {
        &__title {
          background: #f8f8f8;
        }
      }
    }
  }
}
</style>
