<template>
  <div
    class="MagCeAccordion"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      ...containerStyles,
    }"
  >
    <div
      class="pf-separator-content"
      v-if="data.title"
      :style="{
        '--mag-accordion-widget-align': data.title_align,
      }"
    >
      <SfDivider v-if="data.title_align !== 'left' && data.show_line" />
      <div class="pf-separator-label">
        <component :is="data.title_tag">
          <div class="mag-ce-accordion-title">{{ data.title }}</div>
        </component>
        <div class="mag-ce-accordion-description">
          {{ data.description }}
        </div>
      </div>
      <SfDivider v-if="data.title_align !== 'right' && data.show_line" />
    </div>

    <SfAccordion
      :class="data.el_inner_class"
      :open="data.active_sections ? data.active_sections.split(',') : []"
      multiple
    >
      <template v-for="element in data.elements">
        <SfAccordionItem
          :header="element.title"
          v-if="!data.hide_empty_section || element.elements.length"
          class="accordion-item"
          :id="element.el_id"
          :class="[
            element.el_class,
            element.animation_in ? `animate__animated animate__${element.animation_in} animate__delay-1s` : '',
            element.animation_delay ? `animate__delay-${element.animation_delay}s` : '',
            element.animation_infinite ? `animate__infinite` : '',
          ]"
          :style="{
            '--mag-accordion-item-gap': data.gap,
            '--mag-accordion-item-content-padding': data.section_content_padding,
            '--mag-accordion-item-content-border-style': data.no_fill_content_area ? 'none' : 'solid',
            '--mag-accordion-item-content-bg-color': data.section_content_background_color
              ? data.section_content_background_color
              : data.no_fill_content_area
                ? 'transparent'
                : '#f8f8f8',
            '--mag-accordion-item-heading-spacing': data.spacing,
            '--mag-accordion-item-heading-color': data.section_color,
            '--mag-accordion-item-heading-hover-color': data.section_hover_color,
            '--mag-accordion-item-heading-active-color': data.section_active_color,
            '--mag-accordion-item-heading-bg-color': data.section_background_color,
            '--mag-accordion-item-heading-hover-bg-color': data.section_hover_background_color,
            '--mag-accordion-item-heading-active-bg-color': data.section_active_background_color,
            '--mag-accordion-item-heading-border-width': data.section_border_width,
            '--mag-accordion-item-heading-border-style': data.section_border_style,
            '--mag-accordion-item-heading-border-color': data.section_border_color,
            '--mag-accordion-item-heading-hover-border-color': data.section_hover_border_color,
            '--mag-accordion-item-heading-active-border-color': data.section_active_border_color,
            '--mag-accordion-item-heading-border-radius': data.section_border_radius,
            '--mag-accordion-item-heading-font-size': data.title_font_size,
            '--mag-accordion-item-heading-align': data.section_align,
          }"
        >
          <template #header="{ header, isOpen, accordionClick }">
            <div class="accordion-heading mgz-element" :class="{ 'is-active': isOpen }" @click="accordionClick">
              <h4>{{ header }}</h4>
              <button type="button">
                <i class="icon-chevron-down"></i>
              </button>
            </div>
          </template>
          <LazyHydrate when-visible v-for="(item, key) in element.elements" :key="key">
            <MagContentRenderer :data="item" :class="element.el_inner_class" />
          </LazyHydrate>
        </SfAccordionItem>
      </template>
    </SfAccordion>
  </div>
</template>

<script>
import 'animate.css';
import '~/app/code/Magezon/PageBuilder/view/base/web/css/styles.css';

import { SfDivider, SfAccordion } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
export default {
  name: 'MagCeAccordion',
  components: {
    SfDivider,
    SfAccordion,
    LazyHydrate,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-accordion-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-accordion-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-accordion-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-accordion-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-accordion-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-accordion-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-accordion-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-accordion-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-accordion-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-accordion-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-accordion-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-accordion-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-accordion-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-accordion-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-accordion-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-accordion-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-accordion-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-accordion-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-accordion-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-accordion-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-accordion-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-accordion-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-accordion-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-accordion-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-accordion-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-accordion-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-accordion-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .sf-accordion {
    &-item {
      padding: 20px 0;
      border-bottom: 1px solid $border-color;

      .accordion-heading {
        display: flex;
        position: relative;
        cursor: pointer;

        h4 {
          margin-bottom: 0;
        }

        button {
          background: transparent;
          border: 0 none;
          padding: 0;
          position: absolute;
          right: 0;
          top: 5px;

          i {
            position: relative;

            &::before {
              font-size: 1.25rem;
              position: absolute;
              top: 0;
              right: 0;
              transition: $transition-base;
              color: var(--c-primary) !important;
            }
          }
        }

        &.is-active {
          button {
            i {
              &::before {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &:last-child {
        border: 0 none;
      }

      &__content {
        padding: 0;
        font-weight: 400;
        margin-top: 20px;
        border: 0 none;

        .MagCeText {
          line-height: 1.5;
        }
      }
    }
  }
}

.pf-separator-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin: 0 auto 10px;
  padding-bottom: 10px;

  > .sf-divider {
    flex: 1 1 0;
  }

  .pf-separator-label {
    text-align: var(--mag-accordion-widget-align);
    margin: 0 15px;

    & > * {
      margin: 0;
    }
  }
}
</style>
