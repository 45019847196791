<template>
  <div class="pf-tabs-container" :class="{ 'no-fill-content-area': noFillContentArea }">
    <div
      class="pf-tabs pf-tabs-wrapper"
      :class="[`has-position__${position}`, `has-alignment__${align}`]"
      v-if="!shouldShowAccordion"
    >
      <div class="pf-tabs__header">
        <slot />
      </div>

      <slot name="content" />
    </div>
    <SfAccordion :open="titles[activeTab]" v-else>
      <slot name="mobile" />
    </SfAccordion>
  </div>
</template>

<script>
import { SfAccordion } from '@storefront-ui/vue';
import Vue from 'vue';
import PfTabTitle from './PfTabs/PfTabTitles.vue';
import PfTabContent from './PfTabs/PfTabContent.vue';
import { computed } from '@nuxtjs/composition-api';

Vue.component('PfTabTitle', PfTabTitle);
Vue.component('PfTabContent', PfTabContent);

export default {
  name: 'PfTheTabs',
  emits: ['on-change'],
  components: { SfAccordion },
  provide() {
    return {
      activeTab: computed(() => this.activeTab),
      activeOnHover: computed(() => this.activeOnHover),
    };
  },
  props: {
    /**
     * Active tab index
     */
    active: {
      type: String | Number,
      default: 0,
    },
    /**
     * Needed to assign active index for accordion
     */
    titles: {
      type: Array,
      default: () => [],
    },
    /**
     * Tabs position
     */
    position: {
      type: String,
      default: 'top',
      validator: function (value) {
        return ['left', 'right', 'top', 'bottom'].indexOf(value) !== -1;
      },
    },
    /**
     * Tabs alignment
     */
    align: {
      type: String,
      default: 'left',
      validator: function (value) {
        return ['start', 'center', 'end'].indexOf(value) !== -1;
      },
    },
    /**
     * Enable accordion view (only visible on mobile)
     */
    mobileAccordion: {
      type: Boolean,
    },
    activeOnHover: {
      type: Boolean,
    },
    noFillContentArea: {
      type: Boolean,
    },
  },
  data() {
    return {
      activeTab: 0,
      shouldShowAccordion: false,
    };
  },
  watch: {
    active: function () {
      this.activeTab = parseInt(this.active) - 1;
    },
  },
  mounted() {
    this.activeTab = parseInt(this.active) - 1;
    this.$on('toggle', this.toggle);

    if (process.browser) {
      console.log();
      if (window.innerWidth < 768 && this.mobileAccordion) {
        this.shouldShowAccordion = true;
      }
    }
  },
  methods: {
    toggle(index) {
      this.activeTab = index;
      this.$emit('on-change', this.activeTab);
    },
  },
};
</script>

<style lang="scss" scoped>
.pf-tabs {
  &.has-position {
    &__left,
    &__right,
    &__top,
    &__bottom {
      display: flex;
    }

    &__left,
    &__right {
      .pf-tabs {
        &__header {
          flex-direction: column;
        }

        &__trigger {
          margin: 5px 0;
        }
      }
    }

    &__left {
      flex-direction: row;

      ::v-deep {
        .pf-tabs {
          &__title {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &.has-alignment {
        &__start {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: 0 var(--border-radius, 5px) var(--border-radius, 5px) var(--border-radius, 5px);
              }
            }
          }
        }

        &__end {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: 5px 5px 5px 0;
              }
            }
          }
        }
      }
    }

    &__right {
      flex-direction: row-reverse;

      ::v-deep {
        .pf-tabs {
          &__title {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &.has-alignment {
        &__start {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: var(--border-radius, 5px) 0 var(--border-radius, 5px) var(--border-radius, 5px);
              }
            }
          }
        }

        &__end {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: var(--border-radius, 5px) var(--border-radius, 5px) 0 var(--border-radius, 5px);
              }
            }
          }
        }
      }
    }

    &__top,
    &__bottom {
      ::v-deep {
        .pf-tabs {
          &__trigger {
            &:not(:last-child) {
              margin-right: 5px;
            }
          }
        }
      }
    }

    &__top {
      flex-direction: column;

      ::v-deep {
        .pf-tabs {
          &__title {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }

      &.has-alignment {
        &__start {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: 0 var(--border-radius, 5px) var(--border-radius, 5px) var(--border-radius, 5px);
              }
            }
          }
        }

        &__end {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: var(--border-radius, 5px) 0 var(--border-radius, 5px) var(--border-radius, 5px);
              }
            }
          }
        }
      }
    }

    &__bottom {
      flex-direction: column-reverse;

      ::v-deep {
        .pf-tabs {
          &__title {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }

      &.has-alignment {
        &__start {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: var(--border-radius, 5px) var(--border-radius, 5px) var(--border-radius, 5px) 0;
              }
            }
          }
        }

        &__end {
          ::v-deep {
            .pf-tabs {
              &__body {
                border-radius: var(--border-radius, 5px) var(--border-radius, 5px) 0 var(--border-radius, 5px);
              }
            }
          }
        }
      }
    }
  }

  &.has-alignment {
    &__start {
      .pf-tabs {
        &__header {
          justify-content: flex-start;
        }
      }
    }

    &__end {
      .pf-tabs {
        &__header {
          justify-content: flex-end;
        }
      }
    }

    &__center {
      ::v-deep {
        .pf-tabs {
          &__header {
            justify-content: center;
          }

          &__body {
            border-radius: var(--border-radius, 5px);
          }
        }
      }
    }
  }

  &__header {
    display: flex;
  }
}

::v-deep {
  .pf-tabs {
    &__title,
    &__body {
      border: 1px solid #e3e3e3;
      font-weight: normal;
    }

    &__title {
      padding: 14px 20px;
      margin-bottom: -1px;
    }
  }

  .sf-accordion {
    &-item {
      margin-bottom: 0;
      display: block;
      will-change: auto;

      &:not(:last-child) {
        .accordion-heading {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom: none;
        }

        .sf-accordion-item__content {
          border-bottom-style: none;
          border-radius: 0;
        }
      }

      &:not(:first-child) {
        .accordion-heading {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }
      }

      &:last-child {
        .sf-accordion-item__content {
          border-radius: 0 0 var(--border-radius, 5px) var(--border-radius, 5px);
        }

        .accordion-heading {
          &.is-active {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
          }
        }
      }

      .accordion-heading {
        font-size: 15px;
        padding: 14px 40px 14px 24px;
        position: relative;
        cursor: pointer;
        color: #222;
        border-radius: var(--border-radius, 5px);
        background: #f8f8f8;
        border: 1px solid #e3e3e3;

        &.is-active {
          button {
            i {
              transform: rotate(180deg);
            }
          }
        }

        h4 {
          margin-bottom: 0;
        }

        button {
          background: transparent;
          border: 0 none;
          padding: 0;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);

          i {
            display: block;
          }
        }
      }

      .accordion-body {
        padding: 14px 20px;
      }

      &__content {
        padding: 0;
        border: 1px solid #e3e3e3;
        background: #f8f8f8;
      }
    }
  }
}

.no-fill-content-area {
  .pf-tabs {
    &.has-position {
      &__left,
      &__right,
      &__top,
      &__bottom {
        ::v-deep {
          .pf-tabs {
            &__title {
              border-radius: var(--border-radius, 5px);
            }
          }
        }
      }
    }
  }

  ::v-deep {
    .sf-accordion-item {
      .accordion-heading {
        border-radius: var(--border-radius, 5px);

        &.is-active {
          border-radius: var(--border-radius, 5px);
        }
      }
    }
  }
}
</style>
