<template>
  <div
    class="MagCeRow"
    :class="[
      data.row_type === 'contained' ? 'container' : data.row_type,
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
  >
    <div :class="[buildClasses(), data.reverse_column ? 'reverse-on-mobile' : '']">
      <template v-for="(element, key) in data.elements">
        <MagContentRenderer :data="element" :key="key" :class="data.el_inner_class" />
      </template>
    </div>
  </div>
</template>
<script>
import 'animate.css';
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeRow',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    return {
      baseUrl,
    };
  },
  computed: {
    alignment() {
      return {
        justifyContent: this.data.max_width ? this.parseAlignment(this.data.content_align) : 'flex-start',
        alignItems: this.parseAlignment(this.data.content_position),
      };
    },
    styles() {
      let result = {};
      const breakpoints = [
        {
          size: '',
          width: '',
        },
        {
          size: 'lg_',
          width: '1024px',
        },
        {
          size: 'md_',
          width: '768px',
        },
        {
          size: 'sm_',
          width: '560px',
        },
        {
          size: 'xs_',
          width: '360px',
        },
      ];

      for (const key in breakpoints) {
        if (process.browser) {
          const media = window.matchMedia(`(max-width: ${breakpoints[key].width})`);

          if (media.matches || !breakpoints[key].width) {
            result = {
              float: this.data[`${breakpoints[key].size}padding_left`] || this.data.padding_left || 'none',
              paddingLeft: `${this.data[`${breakpoints[key].size}padding_left`] || this.data.padding_left || 0}px`,
              paddingRight: `${this.data[`${breakpoints[key].size}padding_right`] || this.data.padding_right || 0}px`,
              marginLeft:
                this.data[`${breakpoints[key].size}margin_left`] || this.data.margin_left
                  ? `${this.data[`${breakpoints[key].size}margin_left`] || this.data.margin_left || 0}px`
                  : 'auto',
              marginRight:
                this.data[`${breakpoints[key].size}margin_right`] || this.data.margin_right
                  ? `${this.data[`${breakpoints[key].size}margin_right`] || this.data.margin_right || 0}px`
                  : 'auto',
              minHeight: `${this.data[`${breakpoints[key].size}min_height`] || this.data.min_height || 0}px`,
              borderColor: this.data[`${breakpoints[key].size}border_color`] || this.data.border_color || '#222',
              borderStyle: this.data[`${breakpoints[key].size}border_style`] || this.data.border_style || 'none',
              borderWidth: `${
                this.data[`${breakpoints[key].size}border_top_width`] || this.data.border_top_width || 0
              }px ${this.data[`${breakpoints[key].size}border_right_width`] || this.data.border_right_width || 0}px ${
                this.data[`${breakpoints[key].size}border_bottom_width`] || this.data.border_bottom_width || 0
              }px ${this.data[`${breakpoints[key].size}border_left_width`] || this.data.border_left_width || 0}px`,
              borderRadius: `${
                this.data[`${breakpoints[key].size}border_top_left_radius`] || this.data.border_top_left_radius || 0
              }px ${
                this.data[`${breakpoints[key].size}border_top_right_radius`] || this.data.border_top_right_radius || 0
              }px ${
                this.data[`${breakpoints[key].size}border_bottom_right_radius`] ||
                this.data.border_bottom_right_radius ||
                0
              }px ${
                this.data[`${breakpoints[key].size}border_bottom_left_radius`] ||
                this.data.border_bottom_left_radius ||
                0
              }px`,
            };

            if (this.data.background_image) {
              result.backgroundImage = `url('${this.baseUrl}/${
                this.data[`${breakpoints[key].size}background_image`] || this.data.background_image
              }')`;
              result.backgroundColor =
                this.data[`${breakpoints[key].size}background_color`] || this.data.background_color || 'transparent';
              result.backgroundPosition =
                this.data[`${breakpoints[key].size}background_position`] || this.data.background_position
                  ? (this.data[`${breakpoints[key].size}background_position`] || this.data.background_position).replace(
                      '_',
                      ' ',
                    )
                  : 'top center';
              result.backgroundSize =
                this.data[`${breakpoints[key].size}background_style`] || this.data.background_style || 'auto';
            } else {
              result.background =
                this.data[`${breakpoints[key].size}background_color`] || this.data.background_color || 'transparent';
            }
          }
        }
      }

      return result;
    },
  },
  methods: {
    parseAlignment(align) {
      let result = '';

      switch (align) {
        case 'left':
        case 'top':
          result = 'flex-start';
          break;
        case 'right':
        case 'bottom':
          result = 'flex-end';
          break;
        default:
          result = 'center';
          break;
      }
      return result;
    },
    buildClasses() {
      let classes = ['row'];

      if (this.data.el_class) {
        classes.push(this.data.el_class);
      }
      return classes;
    },
  },
};
</script>
<style lang="scss" scoped>
.reverse-on-mobile {
  @include for-mobile {
    flex-direction: row-reverse;
  }
}
</style>
