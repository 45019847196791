<template>
  <div
    class="MagCeText"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.id"
    v-html="sourceData"
  ></div>
</template>
<script>
import 'animate.css';
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeText',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    return {
      baseUrl,
    };
  },
  computed: {
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-ce-text-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-ce-text-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-ce-text-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-ce-text-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-ce-text-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-ce-text-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-ce-text-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-ce-text-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-ce-text-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-ce-text-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-ce-text-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-ce-text-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-ce-text-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-ce-text-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-ce-text-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-ce-text-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-ce-text-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-ce-text-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-ce-text-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-ce-text-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-ce-text-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-ce-text-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-ce-text-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-ce-text-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-ce-text-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-ce-text-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-ce-text-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
    sourceData() {
      let result = this.data.content;
      const img = new RegExp(/<img .*? \/>/gm);
      const pattern = new RegExp(/url=[A-Za-z0-9@:%._\\+~#?&\/=]+/gm);
      let imageTagMatch = result.match(img);

      if (imageTagMatch) {
        for (let i = 0; i < imageTagMatch.length; i++) {
          let imageTag = imageTagMatch[i];

          const urlMatch = imageTag.match(pattern);
          if (urlMatch) {
            const url = this.baseUrl + urlMatch[0].split('=')[1];
            const newImageTag = imageTag.replace(/src=".*?"/gm, `src="${url}"`);
            result = result.replace(imageTag, newImageTag);
          }
        }
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';

.MagCeText {
  @include DesignOptions(text);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(text, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(text, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(text, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(text, -xs_);
  }
}
</style>
