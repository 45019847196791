var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeSeparator",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, {'--mag-ce-separator-width': _vm.data.el_width ? ((_vm.data.el_width || 0) + "px") : '100%',
    '--mag-ce-separator-label-align': _vm.data.title_align,
    '--mag-ce-separator-label-color': _vm.data.title_color},

    _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[_c('div',{staticClass:"pf-separator-content"},[_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-style': _vm.data.style,
        'border-width': _vm.data.line_weight + 'px',
      })}),_vm._v(" "),(_vm.data.title)?_c(_vm.data.title_tag,{tag:"component",staticClass:"pf-separator-label",class:_vm.data.el_inner_class},[(_vm.data.add_icon && _vm.data.icon_position === 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e(),_vm._v("\n      "+_vm._s(_vm.data.title)+"\n      "),(_vm.data.add_icon && _vm.data.icon_position !== 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.data.title || _vm.data.icon)?_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-style': _vm.data.style,
        'border-width': _vm.data.line_weight + 'px',
      })}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }