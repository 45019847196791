var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeAccordion",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[(_vm.data.title)?_c('div',{staticClass:"pf-separator-content",style:({
      '--mag-accordion-widget-align': _vm.data.title_align,
    })},[(_vm.data.title_align !== 'left' && _vm.data.show_line)?_c('SfDivider'):_vm._e(),_vm._v(" "),_c('div',{staticClass:"pf-separator-label"},[_c(_vm.data.title_tag,{tag:"component"},[_c('div',{staticClass:"mag-ce-accordion-title"},[_vm._v(_vm._s(_vm.data.title))])]),_vm._v(" "),_c('div',{staticClass:"mag-ce-accordion-description"},[_vm._v("\n        "+_vm._s(_vm.data.description)+"\n      ")])],1),_vm._v(" "),(_vm.data.title_align !== 'right' && _vm.data.show_line)?_c('SfDivider'):_vm._e()],1):_vm._e(),_vm._v(" "),_c('SfAccordion',{class:_vm.data.el_inner_class,attrs:{"open":_vm.data.active_sections ? _vm.data.active_sections.split(',') : [],"multiple":""}},[_vm._l((_vm.data.elements),function(element){return [(!_vm.data.hide_empty_section || element.elements.length)?_c('SfAccordionItem',{staticClass:"accordion-item",class:[
          element.el_class,
          element.animation_in ? ("animate__animated animate__" + (element.animation_in) + " animate__delay-1s") : '',
          element.animation_delay ? ("animate__delay-" + (element.animation_delay) + "s") : '',
          element.animation_infinite ? "animate__infinite" : '' ],style:({
          '--mag-accordion-item-gap': _vm.data.gap,
          '--mag-accordion-item-content-padding': _vm.data.section_content_padding,
          '--mag-accordion-item-content-border-style': _vm.data.no_fill_content_area ? 'none' : 'solid',
          '--mag-accordion-item-content-bg-color': _vm.data.section_content_background_color
            ? _vm.data.section_content_background_color
            : _vm.data.no_fill_content_area
              ? 'transparent'
              : '#f8f8f8',
          '--mag-accordion-item-heading-spacing': _vm.data.spacing,
          '--mag-accordion-item-heading-color': _vm.data.section_color,
          '--mag-accordion-item-heading-hover-color': _vm.data.section_hover_color,
          '--mag-accordion-item-heading-active-color': _vm.data.section_active_color,
          '--mag-accordion-item-heading-bg-color': _vm.data.section_background_color,
          '--mag-accordion-item-heading-hover-bg-color': _vm.data.section_hover_background_color,
          '--mag-accordion-item-heading-active-bg-color': _vm.data.section_active_background_color,
          '--mag-accordion-item-heading-border-width': _vm.data.section_border_width,
          '--mag-accordion-item-heading-border-style': _vm.data.section_border_style,
          '--mag-accordion-item-heading-border-color': _vm.data.section_border_color,
          '--mag-accordion-item-heading-hover-border-color': _vm.data.section_hover_border_color,
          '--mag-accordion-item-heading-active-border-color': _vm.data.section_active_border_color,
          '--mag-accordion-item-heading-border-radius': _vm.data.section_border_radius,
          '--mag-accordion-item-heading-font-size': _vm.data.title_font_size,
          '--mag-accordion-item-heading-align': _vm.data.section_align,
        }),attrs:{"header":element.title,"id":element.el_id},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var header = ref.header;
        var isOpen = ref.isOpen;
        var accordionClick = ref.accordionClick;
return [_c('div',{staticClass:"accordion-heading mgz-element",class:{ 'is-active': isOpen },on:{"click":accordionClick}},[_c('h4',[_vm._v(_vm._s(header))]),_vm._v(" "),_c('button',{attrs:{"type":"button"}},[_c('i',{staticClass:"icon-chevron-down"})])])]}}],null,true)},[_vm._v(" "),_vm._l((element.elements),function(item,key){return _c('LazyHydrate',{key:key,attrs:{"when-visible":""}},[_c('MagContentRenderer',{class:element.el_inner_class,attrs:{"data":item}})],1)})],2):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }