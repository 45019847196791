import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';

import getProductDetailsDataGql from '~/customQueries/catalog/product/queries/getProductDetailsData.gql';
import getProductManufCatDataGql from '~/customQueries/getProductManufCatData.gql';

import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import type { ProductDetails, ProductList, UseProductErrors, UseProductInterface } from './useProduct';
import type { GetProductBreadcrumb, ProductDetailsQuery } from '~/modules/GraphQL/types';

import { useApi } from '~/composables';
import getProductBreadcrumb from '~/modules/catalog/product/queries/getProductBreadcrumb.gql';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const { query } = useApi();
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
  });

  const context = useContext();

  const getProductList = async (searchParams: GetProductSearchParams): Promise<ProductList | null> => {
    Logger.debug(`useProduct/${id}/getProductList`, searchParams);
    let products: ProductList = null;

    try {
      loading.value = true;
      products = await getProductListCommand.execute(context, searchParams);
      error.value.getProductList = null;
    } catch (err) {
      error.value.getProductList = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductDetails = async (searchParams: GetProductSearchParams): Promise<ProductDetails | null> => {
    Logger.debug(`useProduct/${id}/getProductDetails`, searchParams);
    let products: ProductDetails = null;

    try {
      loading.value = true;

      products = await getProductDetailsCommand.execute(context, searchParams);

      if (products && products?.items?.length > 0) {
        const { data, errors } = await query<ProductDetailsQuery>(getProductManufCatDataGql, searchParams);
        let productManfData = data?.products?.items || [];

        if (productManfData) {
          products.items.forEach((el) => {
            let prodFinded = productManfData.find((val) => val.uid == el.uid);
            if (prodFinded) {
              el['category_type_main_txt'] = prodFinded.category_type_main_txt || '';
              el['category_type_manufacturer_txt'] = prodFinded.category_type_manufacturer_txt || '';
              el['manufacturer_main_category'] = prodFinded.manufacturer_main_category || null;
              el['product_top_category'] = prodFinded.product_top_category || null;
            }
          });
        }
      }

      // if (errors) {
      //   const joinedErrors = errors.map((e) => e.message).join(',');
      //   // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      //   throw new Error(joinedErrors);
      // }

      // products = data.products || null;

      error.value.getProductDetails = null;
    } catch (err) {
      error.value.getProductDetails = err;
      Logger.error(`useProduct/${id}/search`, err);
    } finally {
      loading.value = false;
    }

    return products;
  };

  const getProductBreadcrumbs = async (sku: String): Promise<GetProductBreadcrumb | null> => {
    try {
      const { data, errors } = await query<GetProductBreadcrumb>(getProductBreadcrumb, { sku });
      if (errors && errors.length) {
        return null;
      } else {
        return data;
      }
    } catch (err) {
      return null;
    }
  };

  return {
    getProductList,
    getProductDetails,
    error: readonly(error),
    loading: readonly(loading),
    getProductBreadcrumbs,
  };
}

export * from './useProduct';
export default useProduct;
