import { readonly, ref, useRoute, useContext } from '@nuxtjs/composition-api';
import { useUiHelpers, useApi } from '~/composables';
import { RoutableInterface, ExtRoutableInterface } from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import type { ComposableFunctionArgs, UseUrlResolverErrors, UseUrlResolverInterface } from '~/composables';
import getRouteDataGql from '~/customQueries/getRouteData.gql';
import getCategoryFilterDataGql, { CategoryFilterDataQuery } from '~/customQueries/getCategoryFilterData.gql';

import getCategoryMetaData from '~/customQueries/metaQueries/getCategoryMetaData.gql';
import getProductMetaData from '~/customQueries/metaQueries/getProductMetaData.gql';
import getCmsPageMetaData from '~/customQueries/metaQueries/getCmsPageMetaData.gql';
import type { MetaDataInterface } from '~/customQueries/metaQueries/types';

import { useFilterdata } from '~/stores/filterdata';

import { htmlEntitiesDecoder } from '~/helpers/htmlEntitiesDecoder';

import GetFilterPagesSeoDataFullGql from '~/customQueries/metaQueries/getFilterPagesSeoDataFull.gql';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';

/**
 * Allows searching the resolver for current
 * route path (URL).
 *
 * See the {@link UseUrlResolverInterface} for a list of methods and values available in this composable.
 */
export function useUrlResolver(): UseUrlResolverInterface {
  const route = useRoute();
  const { query } = useApi();
  const { error: nuxtError, app } = useContext();
  const context = app.$vsf;
  const { path } = route.value;
  const loading = ref(false);
  const error = ref<UseUrlResolverErrors>({
    search: null,
    meta: null,
  });
  const uiHelpers = useUiHelpers();

  const filterStore = useFilterdata();

  const search = async (params?: ComposableFunctionArgs<{}>): Promise<RoutableInterface | null> => {
    loading.value = true;
    let results: RoutableInterface | null = null;

    try {
      const clearUrl = path.replace(/^\/[a-z]+\/[cp|]\//gi, '');
      Logger.debug('[Magento] Find information based on URL', { clearUrl });
      const { data } = await context.$magento.api.route(clearUrl, params?.customQuery ?? null);
      results = data?.route ?? null;

      if (!results) nuxtError({ statusCode: 404 });

      Logger.debug('[Result]:', { results });
    } catch (err) {
      error.value.search = err;

      Logger.error('useUrlResolver/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const getUrlData = async (
    slug: string,
    params?: ComposableFunctionArgs<{}>,
  ): Promise<ExtRoutableInterface | null> => {
    loading.value = true;
    let results: ExtRoutableInterface | null = null;

    try {
      // const clearUrl = path.replace(/^\/[a-z]+\/[cp|]\//gi, '');
      // const clearUrl = path.replace(/\/shop\//, "");
      const clearUrl = slug;

      // reset filter data part
      filterStore.cleanup();

      Logger.debug('[Magento] Find information based on URL', { clearUrl });
      // const { data, errors } = await context.$magento.api.route(clearUrl, params?.customQuery ?? null);
      const { data, errors } = await query(getRouteDataGql, { url: clearUrl });

      // @ts-ignore
      results = data?.route ?? null;

      // check for filter urls
      if (!results && clearUrl.endsWith('.html')) {
        const { data: filterData, errors: filterDataError } = await query<CategoryFilterDataQuery>(
          getCategoryFilterDataGql,
          { url: clearUrl },
        );

        if (filterData && filterData?.categoryFilterData?.filters && filterData?.categoryFilterData?.filters != '[]') {
          // let filterJsonData = JSON.parse(filterData?.categoryFilterData?.filters);
          results = {
            redirect_code: 0,
            // @ts-ignore
            // type: "CATEGORY",
            type: 'CATEGORY_FILTER',
            // filterData: filterData?.categoryFilterData?.filters,
            uid: filterData?.categoryFilterData?.category_uid,
            id: filterData?.categoryFilterData?.category_id,
            // category_url: filterData?.categoryFilterData?.category_url
          };

          let catUrl = '/';
          if (filterData?.categoryFilterData?.category_url) {
            catUrl = '/shop/' + filterData?.categoryFilterData?.category_url;
          } else {
            catUrl = '/shop/';
          }

          let filterDataStr = filterData?.categoryFilterData?.filters || '';
          let jsonFilterData = null;
          let jsonSortData = null;
          if (filterDataStr && filterDataStr !== '[]') {
            let jsonData = JSON.parse(filterDataStr) || null;

            // @ts-ignore
            jsonFilterData = jsonData?.filters || null;
            // @ts-ignore
            jsonSortData = jsonData?.sorting || null;

            let temp = {};
            let isPriceAttr = false;
            let priceAttrCode = 'price';
            let priceRange = {
              min: null,
              max: null,
            };
            let priceAttrbId = 0;
            let priceAttrbPos = 0;

            if (jsonFilterData && jsonFilterData.length > 0) {
              jsonFilterData.forEach((el) => {
                if (!(el.attribute_code in temp)) {
                  if (el.attribute_code == priceAttrCode) {
                    // set priceRange
                    isPriceAttr = true;
                  } else {
                    temp[el.attribute_code] = [];
                  }
                }

                if (el.attribute_code == priceAttrCode) {
                  if (el.min_price) {
                    priceRange.min = el.min_price;
                  }
                  if (el.max_price) {
                    priceRange.max = el.max_price;
                  }

                  if (el.attribute_id) {
                    priceAttrbId = el.attribute_id;
                  }
                  if (el.attribute_position) {
                    priceAttrbPos = el.attribute_position;
                  }
                } else {
                  temp[el.attribute_code].push({
                    label: el.option_label,
                    value: el.option_value,
                    attrId: el.attribute_id || 0,
                    pos: el.attribute_position || 0,
                  });
                }
              });

              if (isPriceAttr) {
                let val = (priceRange.min || '0') + '_' + (priceRange.max || '0');
                temp[priceAttrCode] = [
                  {
                    label: val,
                    value: val,
                    attrId: priceAttrbId,
                    pos: priceAttrbPos,
                  },
                ];
              }
            }

            filterStore.setFilterData(temp);
            filterStore.setCatUrl(catUrl);

            if (jsonSortData) {
              filterStore.setSortData(jsonSortData);
            }
          }
        }
      }

      if (!results) nuxtError({ statusCode: 404 });

      Logger.debug('[Result]:', { results });
    } catch (err) {
      error.value.search = err;

      Logger.error('useUrlResolver/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };
  const joinErrorThrow = (errors) => {
    const joinedErrors = errors.map((e) => e.message).join(',');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    throw new Error(joinedErrors);
  };

  const getUrlMetaData = async (type: string, id: string): Promise<MetaDataInterface | null> => {
    loading.value = true;
    let results: MetaDataInterface | null = null;

    try {
      if (type == 'PRODUCT') {
        const { data, errors } = await query(getProductMetaData, { sku: id });

        if (errors) {
          joinErrorThrow(errors);
        }

        // @ts-ignore
        results = data?.products?.items.length > 0 ? data?.products?.items[0] : null;
      } else if (type == 'CATEGORY') {
        const { data, errors } = await query(getCategoryMetaData, { uid: id });

        if (errors) {
          joinErrorThrow(errors);
        }

        // @ts-ignore
        results = data?.categories?.items.length > 0 ? data?.categories?.items[0] : null;

        results.canonical_url = results?.mw_canonical_url?.url || results?.canonical_url || route.value.path;
      } else if (type == 'CATEGORY_FILTER') {
        let catUid = id;
        let facetsObj = {
          ...uiHelpers.getFacetsFromURL(),
          category_uid: catUid,
        };

        let appliedFilters = createProductAttributeFilterInput(facetsObj);

        const filterObjNew = Object.entries(appliedFilters).reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});

        let filterObj = {
          id: catUid,
          filters: filterObjNew,
        };
        const { data, errors } = await query(GetFilterPagesSeoDataFullGql, filterObj);

        // if (errors) {
        // 	const joinedErrors = errors.map((e) => e.message).join(',');
        // 	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        // 	throw new Error(joinedErrors);
        // }

        if (errors) {
          joinErrorThrow(errors);
        }

        // @ts-ignore
        let prodMetaInfo = data?.products?.mw_seo_category_data || null;
        // @ts-ignore
        let catMetaInfo = data?.categories?.items?.[0] || null;

        results = {
          meta_title: htmlEntitiesDecoder(prodMetaInfo?.meta_title || catMetaInfo?.meta_title || ''),
          meta_description: htmlEntitiesDecoder(prodMetaInfo?.meta_description || catMetaInfo?.meta_description || ''),
          meta_keywords: htmlEntitiesDecoder(prodMetaInfo?.meta_keywords || catMetaInfo?.meta_keywords || ''),
          meta_robots: prodMetaInfo?.meta_robots || catMetaInfo?.meta_robots || '',
          canonical_url: prodMetaInfo?.canonical_url || catMetaInfo?.canonical_url || route.value.path,
        };
      } else if (type == 'CMS_PAGE') {
        const { data, errors } = await query(getCmsPageMetaData, { identifier: id });

        if (errors) {
          joinErrorThrow(errors);
        }

        // @ts-ignore
        results = data?.cmsPage || null;

        results.canonical_url = results?.mw_canonical_url?.url || '';
      }
    } catch (err) {
      console.log('getUrlMetaData: Errors: ', err);

      error.value.meta = err;
    } finally {
      loading.value = false;
    }

    return results;
  };

  const isRelativeUrl = (url: string) => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === null || parsedUrl.protocol === '';
    } catch (error) {
      return true;
    }
  };

  const getCanonicalUrl = (url: string, pageType: string) => {
    let isRelUrl = isRelativeUrl(url);

    if (!isRelUrl) {
      return url;
    }

    let newUrl = url ? url : '/';
    if (!newUrl.startsWith('/')) {
      newUrl = '/' + newUrl;
    }

    if ((pageType === 'PRODUCT' || pageType === 'CATEGORY') && !newUrl.startsWith('/shop/')) {
      newUrl = `/shop${newUrl}`;
    }

    let storeUrl = process.env.VSF_STORE_URL;
    storeUrl = storeUrl.replace(/\/+$/, '');
    return storeUrl + decodeURIComponent(newUrl);
  };

  return {
    path,
    search,
    getUrlData,
    getUrlMetaData,
    getCanonicalUrl,
    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './UseUrlResolver';
export default useUrlResolver;
