<template>
  <component :is="`${widgetDataObject.type}`" :data="widgetDataObject" v-if="widgetDataObject"></component>
</template>
<script>
import { defineComponent } from '@nuxtjs/composition-api';
import AtwixWidgetBlockWidgetSliderWidget from './Widgets/AtwixWidgetBlockWidgetSliderWidget';
import AtwixWidgetBlockWidgetIconboxWidget from './Widgets/AtwixWidgetBlockWidgetIconboxWidget';
import AtwixWidgetBlockWidgetHeadingWidget from './Widgets/AtwixWidgetBlockWidgetHeadingWidget';
import AtwixWidgetBlockWidgetCategoryWidget from './Widgets/AtwixWidgetBlockWidgetCategoryWidget';
import AtwixWidgetBlockWidgetContactWidget from './Widgets/AtwixWidgetBlockWidgetContactWidget';
import USPWidget from './Widgets/USPWidget';
import ContentWithLinksAndImage from './Widgets/AtwixWidgetBlockWidgetContentWidget';
import PapierFischerWidgetBlockWidgetIconboxWidget from './Widgets/PapierFischerWidgetBlockWidgetIconboxWidget';
import PapierFischerWidgetBlockWidgetScategoryWidget from './Widgets/PapierFischerWidgetBlockWidgetScategoryWidget';
import PapierFischerWidgetBlockWidgetProductWidget from './Widgets/PapierFischerWidgetBlockWidgetProductWidget';
import PapierFischerWidgetBlockWidgetProductDisplayWidget from './Widgets/PapierFischerWidgetBlockWidgetProductDisplayWidget';
import PapierFischerWidgetBlockWidgetBlogDisplayWidget from './Widgets/PapierFischerWidgetBlockWidgetBlogDisplayWidget';

export default defineComponent({
  name: 'MagCeMagentoWidget',
  components: {
    AtwixWidgetBlockWidgetSliderWidget,
    AtwixWidgetBlockWidgetIconboxWidget,
    AtwixWidgetBlockWidgetHeadingWidget,
    AtwixWidgetBlockWidgetCategoryWidget,
    AtwixWidgetBlockWidgetContactWidget,
    USPWidget,
    ContentWithLinksAndImage,
    PapierFischerWidgetBlockWidgetIconboxWidget,
    PapierFischerWidgetBlockWidgetScategoryWidget,
    PapierFischerWidgetBlockWidgetProductWidget,
    PapierFischerWidgetBlockWidgetProductDisplayWidget,
    PapierFischerWidgetBlockWidgetBlogDisplayWidget,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    let widgetDataObject = props.data.magento_widget;
    if (widgetDataObject && widgetDataObject.type) {
      widgetDataObject.type = widgetDataObject.type.replace(/\\/g, '');
    }

    // let widgetDataStr = props.data.magento_widget;
    // let widgetDataStrParts = [];
    // if(widgetDataStr){
    // 	widgetDataStr = widgetDataStr.replace(/'/gm,'"');
    // 	widgetDataStr = widgetDataStr.replace("{{widget ", "", widgetDataStr);
    // 	widgetDataStr = widgetDataStr.replace('"}}','" ', widgetDataStr);

    // 	widgetDataStrParts = widgetDataStr.split('" ')
    // }

    // let widgetDataObject = new Object();
    // widgetDataStrParts.forEach(function(el) {
    // 	if (!el) return;

    // 	let attrbArr = el.split(/=('|")/);
    // 	if (attrbArr.length >= 2) {
    // 		if(attrbArr[0] === 'type'){
    // 		widgetDataObject[attrbArr[0]] = attrbArr[2].replace(/\\/gm, '')
    // 		}else{
    // 		widgetDataObject[attrbArr[0]] = attrbArr[2];
    // 		}
    // 	} else {
    // 		console.log("MagCeMagentoWidget: cannot parse");
    // 	}
    // });

    return {
      widgetDataObject,
    };
  },
});
</script>
