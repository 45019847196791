/* eslint-disable  */

export default {
  formatting: {
    engraving: {
      engraving_max_length: {
        hint: 'Maximale Zeichenanzahl {length}.',
        error: 'Die Länge Ihres Gravurtextes sollte {length} Zeichen nicht übersteigen'
      }
    },
    config_product: {
      ad: 'Sichern Sie sich {discount}% auf Produkte von {brand}.',
      delivery_time: `Lieferbar in {0}`,
      free_shipping: 'Versandkostenfrei ab {value}',
      discount_code: 'Mit dem Gutscheincode: {0}',
      vat: 'Inkl. {value}% USt., zzgl. Versandkosten',
      vatInclShip: 'Inkl. {value}% USt., inkl. Versandkosten'
    }
  },
  "About us": "Über uns",
  "Account": "Konto",
  "Accepted payment methods": "Wir akzeptieren",
  "Add new address": "Neue Adresse hinzufügen",
  "Add to compare": "Hinzufügen zum vergleichen",
  "Add to Wishlist": "Zur Wunschliste hinzufügen",
  "Additional Information": "Zusätzliche Information",
  "Advisory":"Beratung",
  "All Orders": "Alle Bestellungen",
  "Allow order notifications": "Bestellbenachrichtigungen zulassen",
  "Apply": "Übernehmen",
  "Apply Coupon": "Einlösen",
  "Applied Coupon": "Angewendeter Gutschein",
  "Coupon":"Gutschein",
  "Attention!": "Attention!",
  "Back to home": "Zurück Zur Startseite",
  "Back to homepage": "Zurück zur Homepage",
  "Billing": "Abrechnung",
  "Billing address": "Rechnungsadresse",
  "Blog":"Blog",
  "Branches": "Filialen",
  "Brand": "Marke",
  "Cancel": "Abbrechen",
  "Cart": "Warenkorb",
  "New": "Neu",
  "Engraving": "Gravur",
  "Sale": "Sale %",
  "Categories": "Kategorien",
  "Change": "Bearbeiten",
  "Change password your account": "Wenn Sie das Passwort ändern möchten, um auf Ihr Konto zuzugreifen, geben Sie die folgenden Informationen ein",
  "Change to grid view": "Zur Rasteransicht wechseln",
  "Change to list view": "Zur Listenansicht wechseln",
  "Clear all": "Alles löschen",
  "Color": "Farbe",
  "Commercial information": "und erklären sich damit einverstanden, personalisierte Handelsinformationen vom Markennamen per E-Mail zu erhalten",
  "Contact details updated": "Halten Sie Ihre Adressen und Kontaktdaten auf dem neuesten Stand.",
  "Contact us": "Kontaktiere uns",
  "Continue to billing": "Weiter zur Abrechnung",
  "Continue to payment": "Weiter zur Zahlung",
  "Continue to shipping": "Weiter zum Versand",
  "Continue shopping": "Weiter einkaufen",
  "Continue":"Weiter",
  "Cookies Policy": "Cookie-Richtlinie",
  "Create an account": "Konto erstellen",
  "Customer Reviews": "Kundenbewertungen",
  "Customer service": "Kundendienst",
  "Date": "Datum",
  "Birthday": "Geburtstag",
  "Company": "Firma",
  "Default Billing Address": "Standard-Rechnungsadresse",
  "Default Shipping Address": "Standardlieferadresse",
  "Delete": "Löschen",
  "Departments": "Abteilungen",
  "Description": "Beschreibung",
  "Details and status orders": "Überprüfen Sie die Details und den Status Ihrer Bestellungen im Online-Shop. Sie können Ihre Bestellung auch stornieren oder eine Rücksendung anfordern. ",
  "Discount": "Rabatt",
  "Done": "Fertig",
  "Download": "Herunterladen",
  "Download all": "Alle herunterladen",
  "Edit": "Bearbeiten",
  "Email address": "E-Mail Adresse",
  "Empty": "Es sieht so aus als hätten Sie noch keine Artikel zu Ihrem Warenkorb hinzugefügt.",
  "Empty bag": "Leere Tasche",
  "Events": "Events",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Geben Sie den Promo-Code ein",
  "Feedback": "Ihr Feedback ist uns wichtig. Lassen Sie uns wissen, was wir verbessern können.",
  "Feel free to edit": "Fühlen Sie sich frei, Ihre unten stehenden Daten zu bearbeiten, damit Ihr Konto immer auf dem neuesten Stand ist",
  "Filters": "Filter",
  "Find out more": "Finde mehr heraus",
  "First Name": "Vorname",
  "Fischer Office":"Fischer Office",
  "Forgot Password": "Wenn Sie Ihr Passwort vergessen haben, können Sie es zurücksetzen.",
  "Forgot Password Modal Email": "E-Mail, mit der Sie sich anmelden:",
  "forgotPasswordConfirmation": "Vielen Dank! Wenn ein Konto mit der E-Mail-Adresse {0} registriert ist, finden Sie in Ihrem Posteingang eine Nachricht mit einem Link zum Zurücksetzen des Passworts.",
  "Forgotten password?": "Passwort vergessen?",
  "Go back": "Geh zurück",
  "Go back shopping": "Zurück einkaufen",
  "Go back to shop": "Zurück zum Einkaufen",
  "Go to checkout": "Zur Kasse gehen",
  "Guarantee": "Garantie",
  "Help": "Hilfe",
  "Help & FAQs": "Hilfe & FAQs",
  "hide": "ausblenden",
  "Home": "Startseite",
  "I agree to": "Ich stimme zu",
  "I confirm subscription": "Ich bestätige das Abonnement",
  "I want to create an account": "Ich möchte ein Konto erstellen",
  "Info after order": "Sie können sich mit E-Mail und definiertem Passwort in Ihrem Konto anmelden vorhin. Überprüfen Sie in Ihrem Konto Ihre Profildaten Transaktionsverlauf, Abonnement für Newsletter bearbeiten.",
  "Instruction1": "Um mich kümmern",
  "Instruction2": "Nur hier für die Pflegehinweise?",
  "Instruction3": "Ja, das haben wir uns gedacht",
  "It was not possible to request a new password, please check the entered email address.": "Es war nicht möglich, ein neues Passwort anzufordern, bitte überprüfen Sie die eingegebene E-Mail-Adresse.",
  "Item": "Artikel",
  "Items": "Artikel",
  "Kidswear": "Kinderkleidung",
  "Last Name": "Nachname",
  "Let’s start now – we’ll help you": "Fangen wir jetzt an - wir helfen Ihnen.",
  "Log into your account": "In dein Konto einloggen",
  "Login": "Anmelden",
  "login in to your account": "Anmelden bei Ihrem Konto",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Es sieht so aus als hätten Sie noch keine Artikel zu Ihrem Warenkorb hinzugefügt.",
  "Looks like you haven’t added any items to the Wishlist.": "Sieht so aus als hätten Sie keine Artikel zur Merkliste hinzugefügt.",
  "Make an order": "Bestellung aufgeben",
  "Manage addresses": "Verwalten Sie alle gewünschten Adressen (Arbeitsplatz, Privatadresse...) Auf diese Weise müssen Sie die Adresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage billing addresses": "Alle gewünschten Rechnungsadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Rechnungsadresse nicht bei jeder Bestellung manuell eingeben.",
  "Manage shipping addresses": "Alle gewünschten Versandadressen verwalten (Arbeitsplatz, Privatadresse ...) Auf diese Weise müssen Sie die Versandadresse nicht bei jeder Bestellung manuell eingeben.",
  "Match it with": "Kombiniere es mit",
  "Men fashion": "Herrenmode",
  "Menu": "Menü",
  "My billing and shipping address are the same": "Meine Rechnungs- und Lieferadresse sind identisch",
  "My Cart": "Mein Warenkorb",
  "New Products": "Neue Produkte",
  "No account": "Sie haben noch keinen Account?",
  "or": "oder",
  "or fill the details below": "oder füllen Sie die Details unten",
  "Order ID": "Bestellung ID",
  "Order information": "Bestellinformationen",
  "Order No.": "Bestellnummer",
  "Order summary": "Artikelübersicht",
  "Other products you might like": "Andere Produkte, die Ihnen gefallen könnten",
  "Out of stock": "Ausverkauft",
  "Password": "Passwort",
  "Password Changed": "Passwort erfolgreich geändert. Sie können nun zur Startseite zurückkehren und sich anmelden.",
  "Pay for order": "Für Bestellung bezahlen",
  "Payment": "Zahlung",
  "Payment & Delivery": "Zahlung & Lieferung",
  "Payment Method": "Zahlungsmethode",
  "Payment Methods": "Zahlungsmethoden",
  "Personal details": "Persönliche Daten",
  "Please type your current password to change your email address.": "Bitte geben Sie Ihr aktuelles Passwort ein, um Ihre E-Mail-Adresse zu ändern.",
  "Price": "Preis",
  "Price from": "Ab",
  "Privacy": "Datenschutz",
  "Privacy Policy": "Datenschutzerklärung",
  "Product": "Produkt",
  "Product description": "Das Karissa V-Neck Tee hat eine halb taillierte Form schmeichelhaft für jede Figur. Sie können mit ins Fitnessstudio gehen Vertrauen, während es Kurven umarmt und häufiges \"Problem\" verbirgt Bereiche. Finden Sie atemberaubende Cocktailkleider für Frauen und feiern Sie Kleider.",
  "Product suggestions": "Vorgeschlagene Produkte",
  "Products": "Produkte",
  "Products found": "Produkte gefunden",
  "Purchase terms": "Kaufbedingungen",
  "Quality in the details": "Qualität im Detail",
  "Quantity": "Menge",
  "Read all reviews": "Alle Bewertungen lesen",
  "Read and understand": "Ich habe das gelesen und verstanden",
  "Read reviews": "Bewertungen lesen",
  "Register": "Registrieren",
  "Register today": "Melde dich noch heute an",
  "Remove": "Löschen",
  "Remove Address": "Adresse entfernen",
  "Remove from Wishlist": "Von Wunschliste entfernen",
  "Repeat Password": "Wiederhole das Passwort",
  "Reset Password": "Passwort Zurücksetzen",
  "Review my order": "Meine Bestellung überprüfen",
  "Same as shipping address": "Wie Versandadresse",
  "Save changes": "Änderungen speichern",
  "Save for later": "Für später speichern",
  "Save Password": "Passwort Speichern",
  "Search": "Suche",
  "Search for items": "Nach Artikeln suchen",
  "Search results": "Suchergebnisse",
  "Sections that interest you": "Abschnitte, die Sie interessieren",
  "See all": "Alle anzeigen",
  "See all results": "Alle Ergebnisse anzeigen",
  "See more": "Mehr sehen",
  "Select payment method": "Zahlungsmethode auswählen",
  "Select shipping method": "Versandart auswählen",
  "Send my feedback": "Senden Sie mein Feedback",
  "Set up newsletter": "Richten Sie Ihren Newsletter ein und wir senden Ihnen wöchentlich Informationen zu neuen Produkten und Trends aus den von Ihnen ausgewählten Bereichen",
  "Share your look": "Teile deinen Look",
  "Shipping": "Versand",
  "Shipping address": "Lieferanschrift",
  "Shipping Details": "Versanddetails",
  "Shipping method": "Versandart",
  "Show":"Show",
  "show more": "mehr anzeigen",
  "Show on page": "Auf Seite anzeigen",
  "Sign in": "Einloggen",
  "Size guide": "Größentabelle",
  "Sign Up for Newsletter": "Anmeldung für Newsletter",
  "Sort by": "Sortieren nach",
  "Sort: Default": "Standard",
  "Sort: Name A-Z": "Name aufsteigend A-Z",
  "Sort: Name Z-A": "Name absteigend Z-A",
  "Sort: Price from high to low": "Preis von hoch bis niedrig",
  "Sort: Price from low to high": "Preis von niedrig bis hoch",
  "Start shopping": "Einkaufen starten",
  "Status": "Status",
  "Subscribe": "Abonnieren",
  "Subscribe to newsletter": "Anmeldung zum Newsletter",
  "subscribeToNewsletterModalContent": "Wenn Sie sich für den Newsletter angemeldet haben, erhalten Sie spezielle Angebote und Nachrichten von VSF per E-Mail. Wir werden Ihre E-Mail zu keinem Zeitpunkt an Dritte verkaufen oder weitergeben. Bitte beachten Sie unsere {0}.",
  "Subtotal": "Zwischensumme",
  "Subtotal price": "Zwischensumme Preis",
  "Successful placed order": "Sie haben die Bestellung erfolgreich aufgegeben. Sie können den Status Ihres Bestellen Sie über unsere Lieferstatusfunktion. Sie erhalten eine Bestellung Bestätigungs-E-Mail mit Details Ihrer Bestellung und einem Link zum Verfolgen der Bestellung Fortschritt.",
  "Terms and conditions": "Allgemeine Geschäftsbedingungen",
  "Thank you": "Danke",
  "Thank You Inbox": "Wenn die Nachricht nicht in Ihrem Posteingang ankommt, versuchen Sie es mit einer anderen E-Mail-Adresse, mit der Sie sich möglicherweise registriert haben.",
  "Total": "Gesamt",
  "Order Total": "Auftragssumme",
  "Total items": "Gesamtanzahl",
  "Total price": "Gesamtpreis",
  "Total amount":"Gesamtsumme",
  "Update password": "Passwort aktualisieren",
  "Update personal data": "Speichern",
  "Use your personal data": "Bei Papierfischer legen wir großen Wert auf Datenschutzfragen und verpflichten uns, die persönlichen Daten unserer Benutzer zu schützen. Erfahren Sie mehr darüber, wie wir Ihre persönlichen Daten pflegen und verwenden. Sehen Sie dafür in unsere",
  "User Account": "Benutzerkonto",
  "View": "Ansicht",
  "View details": "Details anzeigen",
  "Vue Storefront Next": "Vue Storefront Next",
  "We haven’t found any results for given phrase": "Wir haben keine Ergebnisse für die angegebene Phrase gefunden",
  "Who we are": "Wer wir sind",
  "Wishlist": "Wunschliste",
  "Women fashion": "Damenmode",
  "You added {product} to your shopping cart.": "Sie haben {product} zu Ihrem Warenkorb hinzugefügt.",
  "You are not authorized, please log in": "Sie sind nicht autorisiert, bitte melden Sie sich an",
  "You can unsubscribe at any time": "Sie können sich jederzeit abmelden",
  "You currently have no orders": "Sie haben derzeit keine Bestellungen",
  "The email field must be a valid email":"Das E-Mail-Feld muss eine gültige E-Mail-Adresse sein",
  "You haven’t searched for items yet": "Sie haben noch nicht nach Artikeln gesucht.",
  "Your bag is empty": "Ihre Tasche ist leer",
  "Your wishlist is empty":"Ihre Merkliste ist leer",
  "Your current email address is": "Ihre aktuelle E-Mail-Adresse lautet",
  "Your email": "Deine E-Mail",
  "You have submitted no reviews": "Sie haben keine Bewertungen abgegeben",
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Die Kontoanmeldung war falsch oder Ihr Konto wurde vorübergehend deaktiviert. Bitte warten Sie und versuchen Sie es später erneut.",
  "A customer with the same email address already exists in an associated website.": "Ein Kunde mit derselben E-Mail-Adresse existiert bereits auf einer verknüpften Website.",
  "Invalid email": "Ungültige E-Mail",
  "SUMMER COLLECTION {year}": "SOMMERKOLLEKTION {year}",
  "Colorful summer dresses are already in store": "Bunte Sommerkleider sind bereits im Store",
  "Learn more": "Mehr erfahren",
  "Dresses": "Kleider",
  "Cocktail & Party": "Cocktailparty",
  "Linen Dresses": "Leinenkleider",
  "T-Shirts": "T-Shirts",
  "The Office Life": "Das Büroleben",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Finden Sie atemberaubende Cocktailkleider für Damen und Cocktailkleider für Party-D…c von all Ihren Lieblingsmarken.",
  "Shop now": "Jetzt einkaufen",
  "Summer Sandals": "Sommersandalen",
  "Eco Sandals": "Öko-Sandalen",
  "Subscribe to Newsletters": "Newsletter abonnieren",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Informieren Sie sich über bevorstehende Verkäufe und Veranstaltungen. Erhalten Sie Geschenke und Sonderangebote",
  "Fashion to take away": "Mode zum Mitnehmen",
  "Download our application to your mobile": "Laden Sie unsere Anwendung auf Ihr Handy herunter",
  "Share Your Look": "Teilen Sie Ihren Look",
  "My Account": "Ihr Nutzerprofil",
  "My profile": "Kundendaten",
  "Personal Details": "Persönliche Daten",
  "Addresses details": "Versand",
  "My newsletter": "Newsletter",
  "Log out": "Ausloggen",
  "My reviews": "Bewertungen",
  "Order history": "Bestellungen",
  "Order details": "Bestelldetails",
  "My wishlist": "Merkliste",
  "Password change": "Passwortänderung",
  "Personal data": "Persönliche Daten",
  "Your e-mail": "Deine E-Mail",
  "Current Password": "Jetziges Passwort",
  "You are not authorized, please log in.": "Sie sind nicht autorisiert, bitte melden Sie sich an.",
  "Go To Product": "Zum Produkt",
  "Returns":"Rücklauf",
  "My orders": "Ihre Bestellungen",
  "Add the address": "Fügen Sie die Adresse hinzu",
  "Set as default shipping": "Als Standardversand festlegen",
  "Set as default billing": "Als Standardabrechnung festlegen",
  "House/Apartment number": "Haus-/Wohnungsnummer",
  "Street Name": "Straßenname",
  "City": "Stadt / Ort",
  "State/Province": "Staat/Provinz",
  "Zip-code": "PLZ",
  "Country": "Land",
  "Phone number": "Telefonnummer",
  "Please select a country first": "Bitte wählen Sie zuerst ein Land aus",
  "This field is required": "Dieses Feld ist erforderlich",
  "The field should have at least 2 characters": "Das Feld sollte mindestens 2 Zeichen lang sein",
  "The field should have at least 4 characters": "Das Feld sollte mindestens 4 Zeichen lang sein",
  "The field should have at least 8 characters": "Das Feld sollte mindestens 8 Zeichen lang sein",
  "New Password": "Neues Passwort",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Für dieses Land sind keine Versandarten verfügbar. Es tut uns leid, bitte versuchen Sie es mit einem anderen Land oder später.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "Beim Versuch, Versandarten abzurufen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit anderen Versanddetails oder später.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "Beim Versuch, diese Versandart auszuwählen, ist ein Fehler aufgetreten. Es tut uns leid, bitte versuchen Sie es mit einer anderen Versandart.",
  "We can't find products matching the selection.":"Wir können keine Produkte finden, die der Auswahl entsprechen.",
  'Page not found': 'Seite nicht gefunden',
  'Back to Home page': 'Zurück zur Startseite',
  'An error occurred': 'Ein Fehler ist aufgetreten',
  'Successfully added': 'Erfolgreich hinzugefügt',
  "AllProductsFromCategory": "Alle {categoryName}",
  "Show more": "Zeig mehr",
  "Show less": "Zeige weniger",
  "Yes": "Ja",
  "No": "Nein",
  "Apply filters": "Filter anwenden",
  "The coupon code isn't valid. Verify the code and try again.": "Der Gutscheincode ist ungültig. Überprüfen Sie den Code und versuchen Sie es erneut.",
  "From": "Aus",
  "To": "Zu",
  "Your customization": "Ihre Anpassung",
  "Passwords don't match":"Passwörter stimmen nicht überein",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"Das Passwort muss mindestens 8 Zeichen lang sein und muss mindestens enthalten: 1 Groß- oder Kleinbuchstabe, 1 Ziffer oder ein Sonderzeichen (z. B. , . _ & ? usw.)",
  "Show all products":"Alle Produkte anzeigen",
  "Select previously saved address":"Zuvor gespeicherte Adresse auswählen",
  "Enter different address":"Geben Sie eine andere Adresse ein",
  "You must confirm your account. Please check your email for the confirmation link.": "Sie müssen Ihr Konto bestätigen. Bitte überprüfen Sie Ihre E-Mail auf den Bestätigungslink.",
  "Change Store":"Laden Wechseln",
  "Choose Currency":"Währung wählen",
  "Add a review":"Bewertung hinzufügen",
  "Title":"Titel",
  "Name":"Name",
  "Review":"Rezension",
  "Add review":"Bewertung hinzufügen",
  "Are you sure you would like to remove this item from the shopping cart?":"Bist Du sicher, dass Du diesen Artikel aus dem Warenkorb entfernen möchtest?",
  "Your cart is empty":"Ihr Warenkorb ist leer",
  "Are you sure?":"Bist Du dir sicher?",
  "{0} has been successfully removed from your cart":"{0} wurde erfolgreich aus Ihrem Warenkorb entfernt",
  "Amount":"Menge",
  "Thank you for your order!":"Vielen Dank für Ihre Bestellung!",
  "Your Purchase":"Ihr Einkauf",
  "Primary contacts for any questions":"Primäre Ansprechpartner für alle Fragen",
  "Your Account":"Ihr Konto",
  "What can we improve":"Was können wir verbessern",
  "Payment date":"Zahlungsdatum",
  "The user password was changed successfully updated!":"Das Benutzerpasswort wurde erfolgreich geändert aktualisiert!",
  "The user account data was successfully updated!":"Die Benutzerkontodaten wurden erfolgreich aktualisiert!",
  "Sale":"Sale",
  "Logout":"Abmelden",
  "Product Details" : "Produktdetails",
  "Next" : "Weiter",
  "Previous" : "Zurück",
  "Article":"Artikel",
  "Your shopping cart":"Ihr Warenkorb",
  "VAT":'Inkl. 19% USt., zzgl. Versandkosten',
  "There are no shipping methods available for this country. We are sorry, please try with a different country.":"Es gibt keine Versandmöglichkeiten für das ausgewählte Land. Es tut uns leid. Bitte wähle ein anderes Land aus.",
  "There was some error while trying to select this shipping method. We are sorry, please try with a different shipping method.":"Es gab einen Fehler für die Versandart. Bitte wähle eine andere Versandart aus.",
  "All" : "Alle",
  "Load more":"Mehr laden",
  "Proceed Checkout":"Zur Kasse",
  "Shopping cart":"Warenkorb",
  "Quality since 140 years.":"Qualität seit über 140 Jahren.",
  "Continue as guest":"Als Gast fortfahren",
  "Imprint":"Impressum",
  "Data security":"Datenschutz",
  "Terms of conditions":"AGB",
  "FAQ":"FAQ",
  "Add to Cart":"In den Warenkorb",
  "Notice":"Merken",
  "Standard shipping":"Standard-Versand",
  "Keep shopping":"Jetzt weiter einkaufen",
  "Left": "Links",
  "Right": "Rechts",
  "Engraving text": "Gravurtext",
  "Font": "Schriftart",
  "Engraving place": "Gravurort",
  "Personalization has been added": "Gravur hinzugefügt",
  "Add personalization": "Gravur hinzufügen",
  "Notify Me": "Benachrichtigen Sie mich, wenn wieder auf Lager",
  "You've succesfully unsubscribed from this product.": "Sie haben sich erfolgreich von diesem Produkt abgemeldet.",
  "Alert subscription has been saved": "Das Abonnement wurde gespeichert",
  "Add engraving": "Gravur hinzufügen",
  "Add free engraving": "Gratis Gravur hinzufügen",
  "The pictured product is a sample, which does not correspond to the selected product. The example shown is for illustration of the font selected for engraving. There may be deviations between the image preview and the final product." : "Bei dem abgebildeten Produkt handelt es sich um ein Musterbeispiel, welches nicht dem ausgewählten Produkt entspricht. Das gezeigte Beispiel dient zur Veranschaulichung der ausgewählten Schriftart für die Gravur. Es können Abweichungen zwischen Bildvorschau und Produkt entstehen.",
  "Do you have Question about your engraving?": "Sie haben Fragen zu Ihrer Gravur?",
  "Fast delivery": "Schnelle Lieferung",
  "Purchase on account": "Kauf auf Rechnung",
  "Specialist dealer since 1878": "Fachhändler seit 1878",
  "Your engraving": "Ihre Gravur",
  "days": "tagen",
  "Totals": "Summe",
  "Hand": "Hand",
  "Engravings": "Gravur",
  "blog-sortby": {
    "asc":"Neueste zuerst",
    "desc":"Älteste zuerst"
  },
  "Pay and order":"Bezahlen und bestellen",
  "Top searches": "Häufigste Suchanfragen",
  "Search at PapierFischer": "Suche bei PapierFischer",
  "Find your product at PapierFischer": "Finden Sie Ihr Produkt bei PapierFischer",
  "First name": "Vorname",
  "Last name": "Nachname",
  "Email": "E-Mail",
  "Phone": "Telefon",
  "Your concern": "Ihr Anliegen",
  "Your message": "Ihre Nachricht",
  "Send message": "Nachricht absenden",
  "Please set a new password": "Bitte setze ein neues Passwort",
  "Set a new password": "Neues Passwort setzen",
  "Bürobedarf": "Bürobedarf",
  "Lieferbar in ": "Lieferbar in ",
  "Account confirmed successfully.": "Account erfolgreich bestätigt.",
  "Something wrong... account confirmation failed!": "Etwas stimmt nicht... Kontobestätigung fehlgeschlagen!",
  "I agree to the terms of the privacy policy": "Ich willige in die <a href='/datenschutz' target='_blank'>Datenschutzbestimmungen</a> ein",
  "Missing": "Fehlt",
  "Damaged": "Beschädigt",
  "Other": "Sonstiges",
  "Update the address": "Adresse aktualisieren",
  "Number": "Nummer",
  "Sign in / Create account": "Anmelden / Account erstellen",
  "Extended right of return until January 7th, 2024": "Verlängertes Rückgaberecht bis 07.01.2024*",
  "Customers also bought": "Kunden kauften ebenfalls"
};
