var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeIcon",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[_c(_vm.data.link_url ? 'a' : 'div',_vm._b({tag:"component",staticClass:"mag-icon-wrapper",class:[_vm.data.el_inner_class, ("mgz-icon-size-" + (_vm.data.icon_size))],style:({
      '--icon-color': _vm.data.icon_color,
      '--icon-background-color': _vm.data.icon_background_color,
      '--icon-border-color': _vm.data.icon_border_color,
      '--icon-border-width': _vm.data.icon_border_width,
      '--icon-border-style': _vm.data.icon_border_style,
      '--icon-hover-color': _vm.data.icon_hover_color,
      '--icon-hover-background-color': _vm.data.icon_hover_background_color,
      '--icon-hover-border-color': _vm.data.icon_hover_border_color,
    }),attrs:{"href":_vm.link.url}},'component',_vm.link,false),[_c('i',{staticClass:"mgz-icon-element mag-icon",class:_vm.data.icon})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }