import { defineStore } from 'pinia';
import axios from 'axios';
import { useUiState } from '~/composables';
import { useContext } from '@nuxtjs/composition-api';

export const useBlogStore = defineStore({
  id: 'useBlogStore',
  state: () => ({
    loaded: false,
    posts: [],
    post: {},
    events: [],
    event: {},
    categories: [],
    tags: [],
    order: 'desc',
    postsToReload: 9,
    featuredID: 0,
    featuredImage: '',
    page: 1,
    totalPosts: 0,
    postMetadata: {},
    currentCategory: {
      id: 0,
      slug: '',
    },
  }),
  actions: {
    // set
    setPosts(loadedPosts, type) {
      const postType = type ? type : 'posts';
      this.loaded = true;
      if (postType === 'posts') {
        this.posts = loadedPosts;
      }
      if (postType === 'tribe_events') {
        this.events = loadedPosts;
      }
    },

    setPost(loadedPost, type) {
      const postType = type ? type : 'posts';
      this.featuredID = loadedPost?.featured_media;
      this.loaded = true;
      this.postMetadata = loadedPost.yoast_head_json;

      if (postType === 'posts') {
        this.post = loadedPost;
      }
      if (postType === 'tribe_events') {
        this.event = loadedPost;
      }
      // console.log("setPost")
    },

    setCategories(loadedCategories) {
      if (loadedCategories) {
        const cats = [];
        const t = this;
        const currentCategorySlug = t.currentCategory?.slug;
        loadedCategories.forEach(function (item, index) {
          if (item['count']) {
            const returnObj = { id: item['id'], slug: item['slug'], name: item['name'], count: item['count'] };
            cats.push(returnObj);

            if (item['slug'] == currentCategorySlug) {
              t.setCurrentCategory(item['slug'], item['id']);
            }
          }
        });

        this.categories = cats;
      }
    },

    setTags(loadedTags) {
      if (loadedTags) {
        const tags = [];
        const t = this;
        loadedTags.forEach(function (item, index) {
          if (item['count']) {
            const returnObj = {
              id: item['id'],
              slug: item['slug'],
              name: item['name'].replace('&amp;', '&'),
              count: item['count'],
            };
            tags.push(returnObj);
          }
        });

        this.tags = tags;
      }
    },

    setLoaded(val) {
      this.loaded = val;
    },

    async setTotalPosts(val) {
      this.totalPosts = Number(val);
    },

    setCurrentCategory(cat, id) {
      this.currentCategory['slug'] = cat;
      if (id) {
        this.currentCategory['id'] = id;
      }
    },

    async setOrder(order, type) {
      const postType = type ? type : 'posts';
      this.order = order === 'asc' ? 'desc' : 'asc';
      if (postType === 'posts') {
        this.reloadPosts(this.posts.length, this.order, type);
      } else {
        this.reloadPosts(this.events.length, this.order, type);
      }
    },

    // get
    async getFeaturedImage() {
      this.loaded = false;

      try {
        const response = await axios.get('/blog-api/media/' + this.featuredID);

        this.loaded = true;
        if (response) {
          this.featuredImage = response.data?.guid?.rendered;
        }
      } catch (error) {
        this.loaded = true;
      } finally {
        this.loaded = true;
      }
    },

    // get
    // async imageSrcByID(imageID) {
    //   this.loaded = false
    //   try {
    //     const response = await axios.get("/blog-api/media/" + imageID);

    //     if(response) {
    //       this.loaded = true
    //       return response.data?.link;
    //     }
    //   } catch (error) {
    //       this.loaded = true
    //   } finally {
    //       this.loaded = true
    //   }
    // },

    getCategoryById(id) {
      if (id) {
        if (this.categories) {
          return this.categories.find((cat) => cat.id === id);
        } else {
          // console.log('cats empty')
        }
      }
    },

    getCategoryBySlug(slug) {
      if (slug) {
        // console.log(this.categories)
        if (this.categories) {
          // this.currentCategory = this.categories.find(cat => cat.slug === slug)
        } else {
          // console.log('cats empty')
        }
      }
    },

    async getCategories() {
      // console.log('categories')
      try {
        const response = await axios.get('/blog-api/' + 'categories/');
        if (response) {
          this.categories = response.data;
        }
        // console.log(response)
      } catch (error) {
        // this.loaded = true
      } finally {
        // this.loaded = true
        // this.searchLoading = false;
      }
    },

    getTagById(id) {
      if (id) {
        if (this.tags) {
          return this.tags.find((tag) => tag.id === id);
        } else {
          // console.log('tags empty')
        }
      }
    },

    getTagBySlug(slug) {
      if (slug) {
        if (this.tags) {
          return this.tags.find((tag) => tag.slug === slug);
        } else {
          // console.log('tags empty')
        }
      }
    },

    // async getTags() {
    //   // console.log('categories')
    //   try {
    //     const response = await axios.get('/blog-api/' + 'tags/');
    //     if(response) {
    //       console.log(response)
    //     }
    //     // console.log(response)
    //   } catch (error) {
    //     // this.loaded = true
    //   } finally {
    //     // this.loaded = true
    //     // this.searchLoading = false;
    //   }
    // },

    // load
    async reloadPosts(postsToLoad, order, type, q = '') {
      const postType = type ? type : 'posts';
      const searchQuery = q ? q : '';
      let fetchUrl = '';
      if (postType === 'posts') {
        fetchUrl = '/blog-api/' + postType + '/' + '?per_page=' + postsToLoad + '&order=' + order;
      }
      if (postType === 'search') {
        fetchUrl = '/blog-api/posts/' + '?search=' + q + '&per_page=' + postsToLoad + '&order=' + order;
      }
      if (postType === 'tribe_events') {
        fetchUrl = '/event-api/events/' + '?per_page=' + postsToLoad;
      }
      if (postType === 'posts' && this.currentCategory['slug'] && this.currentCategory['id'] > 2) {
        fetchUrl = fetchUrl + '&categories=' + this.currentCategory['id'];
      }
      this.loaded = false;
      try {
        const response = await axios.get(fetchUrl);
        this.loaded = true;
        if (response) {
          await this.setTotalPosts(response?.headers['x-wp-total']);
          if (postType === 'posts' || postType === 'search') {
            this.posts = response.data;
          }
          if (postType === 'tribe_events') {
            this.events = response.data.events;
          }
        }
      } catch (error) {
        this.loaded = true;
      } finally {
        this.loaded = true;
      }
    },

    async loadmorePosts(postsToReload, order, type, q = '') {
      const postType = type ? type : 'posts';
      const searchQuery = q ? q : '';
      this.postsToReload = postsToReload;
      this.loaded = false;
      let fetchUrl = '';
      if (postType === 'posts') {
        fetchUrl =
          '/blog-api/' +
          postType +
          '/' +
          '?per_page=' +
          this.postsToReload +
          '&offset=' +
          this.posts.length +
          '&order=' +
          order;
      }
      if (postType === 'search') {
        fetchUrl =
          '/blog-api/posts/' +
          '?per_page=' +
          this.postsToReload +
          '&offset=' +
          this.posts.length +
          '&order=' +
          order +
          '&search=' +
          q;
      }
      if (postType === 'tribe_events') {
        this.page++;
        fetchUrl = '/event-api/events/' + '?page=' + this.page + '&per_page=9';
      }
      if (postType === 'posts' && this.currentCategory['slug'] && this.currentCategory['id'] > 2) {
        fetchUrl = fetchUrl + '&categories=' + this.currentCategory['id'];
      }

      try {
        const response = await axios.get(fetchUrl);

        // console.log(response)

        this.loaded = true;
        if (response) {
          // this.setTotalPosts(response?.headers['x-wp-total'])
          if (postType === 'posts' || postType === 'search') {
            this.posts = this.posts.concat(response.data);
          }
          if (postType === 'tribe_events') {
            this.events = this.events.concat(response.data.events);
          }
        }
      } catch (error) {
        // console.log(error)
        this.loaded = true;
      } finally {
        this.loaded = true;
      }
    },
  },
});
