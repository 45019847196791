import { ProductAttributeFilterInput } from '~/modules/GraphQL/types';
import type { ComposableFunctionArgs } from '~/composables';
import type { FacetSearchParams } from '../useFacet';
import base64 from 'base-64';

export const rangeFilters = ['price'];
interface CatFilterType {
  category_uid: {
    eq?: string;
    in?: any[];
  };
}

export function createProductAttributeFilterInput(
  params: ComposableFunctionArgs<FacetSearchParams>,
): ProductAttributeFilterInput {
  const attributeFilter: Record<
    string,
    { from: number; to: number } | { is_in_stock: unknown } | { eq: unknown } | { in: unknown }
  > = {};
  const inputFilters = params?.filters ?? {};

  const categoryFilter = <CatFilterType>{
    category_uid: { eq: params.category_uid },
  };

  let multipleCats = false;

  if (inputFilters.category_uid?.length > 0) {
    multipleCats = true;
    categoryFilter.category_uid = {
      in: [params.category_uid, ...(inputFilters.category_uid ?? [])],
    };
  }

  Object.keys(inputFilters).forEach((key: string) => {
    if ('category_id' == key) {
      let filterArr = [];
      inputFilters[key].forEach((el) => {
        filterArr.push(base64.encode(el));
      });

      if (!multipleCats) {
        categoryFilter.category_uid = { in: [categoryFilter.category_uid.eq] };
      }
      categoryFilter.category_uid.in = [...categoryFilter.category_uid.in, ...filterArr];
    } else if (key == 'stock_status') {
      if (typeof inputFilters[key] === 'object' && Object.values(inputFilters[key]).length) {
        attributeFilter[key] = { is_in_stock: Object.values(inputFilters[key])[0] };
      }
    } else if (rangeFilters.includes(key)) {
      const range = { from: 0, to: 0 };
      const flatValue = inputFilters[key]
        .flatMap((inputFilter) => inputFilter.split('_'))
        .map((str: string) => Number.parseFloat(str))
        .sort((a, b) => a - b);

      [range.from] = flatValue;
      range.to = flatValue[flatValue.length - 1];

      attributeFilter[key] = range;
    } else if (typeof inputFilters[key] === 'string') {
      attributeFilter[key] = { eq: inputFilters[key] };
    } else if (inputFilters[key].length === 1) {
      attributeFilter[key] = { eq: inputFilters[key][0] };
    } else {
      attributeFilter[key] = { in: inputFilters[key] };
    }
  });

  return { ...attributeFilter, ...categoryFilter };
}
