import { ref, useContext } from '@nuxtjs/composition-api';
import { dataElement, mgzLink, useLinksInterface } from './useLinks';
import useProduct from '~/modules/catalog/product/composables/useProduct';

export function useLinks(): useLinksInterface {
  const { app, localeRoute } = useContext();
  const paths = ref<Array<mgzLink>>([]);
  const loading = ref<boolean>(false);
  const { getProductList } = useProduct();

  const getLink = async (elements: Array<dataElement>) => {
    loading.value = true;
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i].custom_link;
      const widgetDataObject = stripLink(element);

      if (widgetDataObject) {
        let link = '';
        switch (widgetDataObject.type) {
          case 'custom':
            link = widgetDataObject.url;
            break;
          case 'category':
            link = localeRoute('/' + (await getCategoryLink(widgetDataObject.id))).path;
            link = link.replace(/\/$/, '.html');
            break;
          case 'product':
            break;
          case 'page':
            break;
        }

        paths.value.push(constructObject(element, link));
      }
    }

    loading.value = false;
  };

  const getCategoryLink = async (id: string): Promise<string> => {
    const { data } = await app.context.$vsf.$magento.api.categorySearch({ filters: { ids: { eq: id } } });

    return data.categoryList[0].url_path;
  };

  const getProductLink = (id: string): string => {
    getProductList({ filter: { name: { match: id } } }).then(({ items }) => {});

    return '';
  };

  const getPageLink = (mgzlink: string): string => {
    return '';
  };

  const stripLink = (strLink: string) => {
    if (!strLink) return;

    let widgetDataStr = strLink;
    let widgetDataStrParts = [];
    widgetDataStr = widgetDataStr.replace(/'/gm, '"');
    widgetDataStr = widgetDataStr.replace('{{mgzlink ', '');
    widgetDataStr = widgetDataStr.replace('"}}', '" ');

    widgetDataStrParts = widgetDataStr.split('" ');

    let widgetDataObject = {} as any;
    widgetDataStrParts.forEach(function (el) {
      if (!el) return;

      let attrArr = el.split(/=('|")/);
      if (attrArr.length >= 2) {
        if (attrArr[0] === 'type') {
          widgetDataObject[attrArr[0]] = attrArr[2].replace(/\\/gm, '');
        } else {
          widgetDataObject[attrArr[0]] = attrArr[2];
        }
      }
    });

    return widgetDataObject;
  };

  const constructObject = (strLink: string, link: string): mgzLink => {
    let linkDict = {} as any;
    if (strLink) {
      const matches = strLink.match(/url=".*?"|blank=\w+|title=".*?"|nofollow=\w+/gm);

      for (let i = 0; i < matches.length; i++) {
        const keyValuePair = matches[i].split('=');
        switch (keyValuePair[0]) {
          case 'url':
            linkDict.href = JSON.parse(keyValuePair[1]) || link;
            break;
          case 'blank':
            if (keyValuePair[1] === 'true') linkDict.target = '_blank';
            break;
          case 'nofollow':
            if (keyValuePair[1] === 'true') linkDict.rel = 'nofollow';
            break;
          default:
            linkDict[keyValuePair[0]] = keyValuePair[1].replace(/"/gi, '');
            break;
        }
      }
    }

    return linkDict;
  };

  return {
    paths,
    getLink,
    loading,
  };
}

export * from './useLinks';
