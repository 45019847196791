<template>
  <div
    class="MagCeButton"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
  >
    <template v-if="isAnchor(link.url)">
      <a
        :href="link.url"
        @click.prevent="scrollToAnchor(link.url)"
        :class="[
          data.el_inner_class ? data.el_inner_class : 'btn-primary',
          data.display_as_link ? 'as-link' : '',
          `mgz-btn-size-${data.button_size}`,
          `mgz-btn-style-${data.button_style}`,
          data.full_width ? 'w-100' : '',
          'btn',
        ]"
      >
        <template v-if="data.add_icon && data.icon_position === 'left'">
          <i :class="data.icon"></i>
        </template>
        {{ data.title }}
        <template v-if="data.add_icon && data.icon_position !== 'left'">
          <i :class="data.icon"></i>
        </template>
      </a>
    </template>
    <template v-else>
      <SfButton
        :class="[
          data.el_inner_class ? data.el_inner_class : 'btn-primary',
          data.display_as_link ? 'as-link' : '',
          `mgz-btn-size-${data.button_size}`,
          `mgz-btn-style-${data.button_style}`,
          data.full_width ? 'w-100' : '',
        ]"
        :link="link.url"
        v-bind="link"
        :disabled="data.disable_element"
        class="btn"
      >
        <template v-if="data.add_icon && data.icon_position === 'left'">
          <i :class="data.icon"></i>
        </template>
        {{ data.title }}
        <template v-if="data.add_icon && data.icon_position !== 'left'">
          <i :class="data.icon"></i>
        </template>
      </SfButton>
    </template>
    <div v-html="embeddedStyles"></div>
  </div>
</template>

<script>
import 'animate.css';
import { SfButton } from '@storefront-ui/vue';
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeButton',
  components: {
    SfButton,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    const isAnchor = (url) => {
      return url.startsWith('#');
    };

    const scrollToAnchor = (anchor) => {
      const element = document.getElementById(anchor.replace('#', ''));
      if (element) element.scrollIntoView({ behavior: 'smooth' });
    };

    return {
      baseUrl,
      isAnchor,
      scrollToAnchor,
    };
  },
  computed: {
    embeddedStyles() {
      return this.data.button_css ? `<style>${this.data.button_css.replace(/<br.*?>/gm, '')}</style>` : '';
    },
    link() {
      const link = this.data.link;
      let linkDict = {};
      if (link) {
        // const matches = link.match(/url=".*?"|blank=\w+|title=".*?"|nofollow=\w+/gm)

        // for (let i = 0; i < matches.length; i++) {
        //   const keyValuePair = matches[i].split("=");
        //   switch (keyValuePair[0]) {
        //     case 'blank':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.target = '_blank'
        //       break
        //     case 'nofollow':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.rel = 'nofollow'
        //       break
        //     default:
        //       linkDict[keyValuePair[0]] = keyValuePair[1].replace(/"/gi, "")
        //       break
        //   }
        // }

        linkDict.url = link?.url ?? '';
        linkDict.title = link?.title ?? '';
        linkDict.target = link?.target && link?.target == 'true' ? '_blank' : '';
        linkDict.rel = link?.nofollow && link?.nofollow == 'true' ? 'nofollow' : '';
      }

      return linkDict;
    },
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-button-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-button-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-button-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-button-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-button-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-button-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-button-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-button-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-button-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-button-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-button-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-button-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-button-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-button-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-button-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-button-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-button-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-button-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-button-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-button-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-button-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-button-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-button-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-button-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-button-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-button-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-button-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~/static/css/fontawesome5.css';
@import '~/static/css/openiconic.min.css';

@mixin gradient($color1, $color2) {
  background-image: -webkit-linear-gradient(left, $color1 0, $color2 50%, $color1 100%);
  background-image: -o-linear-gradient(left, $color1 0, $color2 50%, $color1 100%);
  background-image: linear-gradient(to right, $color1 0, $color2 50%, $color1 100%);
  background-repeat: repeat-x;
  background-size: 200% 100%;
  background-position: 0 0;
}

.btn {
  border-radius: 0 !important;
}

.as-link {
  background: transparent;
  color: var(--button-color);
  padding: 2px 4px;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: none;
    background: transparent;
    color: $primary;
  }

  i {
    &:before {
      @extend .iconElement;
      content: $icon-arrow-right;
      margin-left: 10px;
      font-size: 1.25rem;
    }
  }
}

.sf-link {
  box-sizing: content-box;
}

.MagCeButton {
  @media (max-width: $lg) {
    margin-bottom: 20px;
  }
}
</style>
