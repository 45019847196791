import { defineStore } from 'pinia';
import { ExtRoutableInterface } from '~/modules/GraphQL/types';

interface UrlDataState {
  resolverUrl: ExtRoutableInterface | null;
}

export const useUrlData = defineStore({
  id: 'useUrlData',
  state: (): UrlDataState => ({
    resolverUrl: null,
  }),
  getters: {
    getResolverPath(state) {
      return state.resolverUrl.relative_url;
    },
    getResolverType(state) {
      return state.resolverUrl.type;
    },
    getResolverData(state) {
      return state.resolverUrl;
    },
  },
  actions: {
    setResolverData(data: ExtRoutableInterface) {
      this.resolverUrl = data;
    },
  },
});
