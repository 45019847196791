import { render, staticRenderFns } from "./AtwixWidgetBlockWidgetContactWidget.vue?vue&type=template&id=9a1e1288&scoped=true&"
import script from "./AtwixWidgetBlockWidgetContactWidget.vue?vue&type=script&lang=ts&"
export * from "./AtwixWidgetBlockWidgetContactWidget.vue?vue&type=script&lang=ts&"
import style0 from "./AtwixWidgetBlockWidgetContactWidget.vue?vue&type=style&index=0&id=9a1e1288&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9a1e1288",
  null
  
)

export default component.exports