export default {
  name: 'MagCeMissing',
  functional: true,
  render(createElement, ctx) {
    console.log(ctx.props.data);
    return createElement(
      'p',
      {
        style: {
          background: 'yellow',
        },
      },
      [
        createElement(
          'strong',
          {
            style: {
              padding: '5px',
            },
          },
          `Missing CE Type: ${ctx.props.data.type}`,
        ),
      ],
    );
  },
};
