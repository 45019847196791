/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        sku
        name
        stock_status
        is_new
        engraving_enabled
        is_on_sale
        category_type_manufacturer_txt
        manufacturer
        only_x_left_in_stock
        custom_attributes {
          attribute_metadata {
            code
            data_type
            entity_type
            label
            uid
          }
          selected_attribute_options {
            attribute_option {
              label
            }
          }
        }
        
        categories {
          uid
          name
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        small_image {
          disabled
          label
          position
          url
        }
        url_key
        url_rewrites {
          url
        }
        ... on ConfigurableProduct {
          configurable_options {
            id
            attribute_id_v2
            label
            position
            use_default
            attribute_code
            values {
              value_index
              label
              swatch_data{
                value
              }
            }
            product_id
          }
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
