<template>
  <div
    class="MagCeTabs"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id || data.id"
    :style="{
      ...containerStyles(data, 'icon-list'),
    }"
  >
    <div
      class="pf-list"
      :class="[data.el_inner_class, `orientation__${data.layout}`]"
      :style="{
        '--mag-ce-icon-list-spacing': data.spacing,
        '--mag-ce-icon-list-icon-size': data.icon_size,
        '--mag-ce-icon-list-text-size': data.text_size,
        '--mag-ce-icon-list-text-font-weight': data.text_font_weight,
        '--mag-ce-icon-list-icon-background-color': data.icon_background_color,
        '--mag-ce-icon-list-icon-hover-background-color': data.icon_hover_background_color,
        '--mag-ce-icon-list-icon-color': data.icon_color || '#E31927',
        '--mag-ce-icon-list-icon-hover-color': data.icon_hover_color,
        '--mag-ce-icon-list-text-color': data.text_color,
        '--mag-ce-icon-list-text-hover-color': data.text_hover_color,
      }"
    >
      <div class="pf-list__item" v-for="(item, idx) in data.items" :key="idx">
        <component :is="link(item).url ? 'a' : 'div'" v-bind="link(item)">
          <i class="pf-list__icon" :class="item.icon"></i>
          {{ item.link_text }}
        </component>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MagCeIconList',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  methods: {
    link(item) {
      const link = item.link_url;
      let linkDict = {};
      if (link) {
        // const matches = link.match(/url=".*?"|blank=\w+|title=".*?"|nofollow=\w+|type=".*?"|id=".*?"/gm)

        // for (let i = 0; i < matches.length; i++) {
        //   const keyValuePair = matches[i].split("=");
        //   switch (keyValuePair[0]) {
        //     case 'blank':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.target = '_blank'
        //       break
        //     case 'nofollow':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.rel = 'nofollow'
        //       break
        //     default:
        //       linkDict[keyValuePair[0]] = keyValuePair[1].replace(/"/gi, "")
        //       break
        //   }
        // }

        linkDict.href = link?.url ?? '';
        linkDict.title = link?.title ?? '';
        linkDict.type = link?.type ?? '';
        linkDict.id = link?.id ?? '';
        linkDict.target = link?.target && link?.target == 'true' ? '_blank' : '';
        linkDict.rel = link?.nofollow && link?.nofollow == 'true' ? 'nofollow' : '';

        // const extra = link.match(/extra=".*?"/gm);
        const extra = link?.extra ?? '';
        let query = {};
        if (extra) {
          const stringQuery = extra.match(/\w+=(\w+|,)/gm);

          if (stringQuery) {
            for (let i = 0; i < stringQuery.length; i++) {
              const keyValuePair = stringQuery[i].split('=');
              const valueArray = keyValuePair[1].split(',');
              if (valueArray.length > 1) {
                query[keyValuePair[0]] = {
                  in: valueArray,
                };
              } else {
                query[keyValuePair[0]] = {
                  eq: keyValuePair[1],
                };
              }
            }
          }
        }
      }

      linkDict.href = linkDict.url;

      return linkDict.url ? linkDict : {};
    },
    containerStyles(data, key) {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result[`--mag-ce-${key}-z-index`] = data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-ce-${key}-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_top`] || data.padding_top || 0
        }px`;
        result[`--mag-ce-${key}-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_right`] || data.padding_right || 0
        }px`;
        result[`--mag-ce-${key}-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_bottom`] || data.padding_bottom || 0
        }px`;
        result[`--mag-ce-${key}-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_left`] || data.padding_left || 0
        }px`;
        result[`--mag-ce-${key}-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_top`] || data.margin_top || 0
        }px`;
        result[`--mag-ce-${key}-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_right`] || data.margin_right || 0
        }px`;
        result[`--mag-ce-${key}-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_bottom`] || data.margin_bottom || 0
        }px`;
        result[`--mag-ce-${key}-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_left`] || data.margin_left || 0
        }px`;
        result[`--mag-ce-${key}-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_width`] || data.border_top_width || 0
        }px`;
        result[`--mag-ce-${key}-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_right_width`] || data.border_right_width || 0
        }px`;
        result[`--mag-ce-${key}-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_width`] || data.border_bottom_width || 0
        }px`;
        result[`--mag-ce-${key}-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_left_width`] || data.border_left_width || 0
        }px`;
        result[`--mag-ce-${key}-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}border_style`] || data.border_style || 'none';
        result[`--mag-ce-${key}-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}border_color`] || data.border_color || '#222';
        result[`--mag-ce-${key}-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_left_radius`] || data.border_top_left_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_right_radius`] || data.border_top_right_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_left_radius`] || data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_right_radius`] || data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-ce-${key}-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = data.min_height
          ? `${data[`${responsiveStyles}min_height`] || data.min_height || 0}px`
          : '';
        result[`--mag-ce-${key}-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}el_float`] || data.el_float || 'none';
        result[`--mag-ce-${key}-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}align`] || data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-ce-${key}-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${data[`${responsiveStyles}background_image`] || data.background_image}')`;
          result[`--mag-ce-${key}-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_color`] || data.background_color || 'transparent';
          result[`--mag-ce-${key}-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_position`] || data.background_position
              ? (data[`${responsiveStyles}background_position`] || data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-ce-${key}-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_style`] || data.background_style || 'auto';
        } else {
          result[`--mag-ce-${key}-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_color`] || data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
@import '~/app/code/Magezon/Core/view/base/web/css/fontawesome5.css';
@import '~/app/code/Magezon/Builder/view/base/web/css/openiconic.min.css';

.MagCeTabs {
  @include DesignOptions(icon-list);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(icon-list, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(icon-list, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(icon-list, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(icon-list, -xs_);
  }

  .pf-list {
    display: flex;

    &.orientation__vertical {
      flex-direction: column;

      .pf-list__item {
        margin-bottom: 10px;
      }
    }

    &.orientation__horizontal {
      flex-direction: row;

      .pf-list__item {
        margin-right: 10px;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    &__item {
      color: var(--mag-ce-icon-list-text-color, #222);
      font-size: var(--mag-ce-icon-list-text-size, 16px);
      font-weight: var(--mag-ce-icon-list-text-font-weight, normal);

      &:hover {
        color: var(--mag-ce-icon-list-text-color, #222);
      }
    }

    &__icon {
      border-radius: 3px;
      padding: 3px;
      font-size: var(--mag-ce-icon-list-icon-size, 16px);
      color: var(--mag-ce-icon-list-icon-color, #222);
      background-color: var(--mag-ce-icon-list-icon-background-color, transparent);

      &:hover {
        color: var(--mag-ce-icon-list-icon-hover-color, #222);
        background-color: var(--mag-ce-icon-list-icon-hover-background-color, transparent);
      }

      // &::before {
      //   color: $primary;
      // }
    }

    @media (max-width: $sm) {
      margin-bottom: 1rem;
    }
  }
}
</style>
