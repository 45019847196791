var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeVideo",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[(_vm.data.title)?_c('div',{staticClass:"pf-separator-content",style:({
      '--divider-border-position': _vm.data.line_position === 'bottom' ? 'end' : 'center',
      '--mag-video-widget-align': _vm.data.title_align,
    })},[(_vm.data.title_align !== 'left' && _vm.data.show_line)?_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-weight': _vm.data.line_weight,
      })}):_vm._e(),_vm._v(" "),_c(_vm.data.title_tag,{tag:"component",staticClass:"pf-separator-label",class:_vm.data.el_inner_class},[_c('div',{staticClass:"mag-ce-video-title",style:({
          '--mag-video-widget-title-color': _vm.data.title_color,
          '--mag-video-widget-title-spacing': _vm.data.video_description_spacing,
          '--mag-video-widget-title-font-weight': _vm.data.video_description_font_weight,
          '--mag-video-widget-title-font-size': _vm.data.video_description_font_size,
        })},[_vm._v("\n        "+_vm._s(_vm.data.title)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"mag-ce-video-description"},[_vm._v("\n        "+_vm._s(_vm.data.description)+"\n      ")])]),_vm._v(" "),(_vm.data.title_align !== 'right' && _vm.data.show_line)?_c('SfDivider',{style:({
        '--divider-border-color': _vm.data.color,
        '--divider-border-weight': _vm.data.line_weight,
      })}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('PfVideo',{style:({
      '--video-player-title-color': _vm.data.video_title_color,
      '--video-player-title-margin': _vm.data.video_title_spacing,
      '--video-player-title-size': _vm.data.video_title_font_size,
      '--video-player-title-weight': _vm.data.video_title_font_weight,
      '--video-player-description-color': _vm.data.video_description_color,
      '--video-player-description-margin': _vm.data.video_description_spacing,
      '--video-player-description-size': _vm.data.video_description_font_size,
      '--video-player-description-weight': _vm.data.video_description_font_weight,
    }),attrs:{"src":_vm.data.link,"autoplay":_vm.data.autoplay == '1' ? true : false,"mute":_vm.data.mute == '1' ? true : false,"loop":Boolean(_vm.data.loop),"relatedvideos":_vm.data.related_videos,"modestbranding":_vm.data.modest_branding,"youtubeprivacy":_vm.data.youtube_privacy,"start":_vm.data.start_at,"end":_vm.data.end_at,"preview-enabled":_vm.data.show_preview_image,"preview-url":_vm.data.preview_image,"show-play-icon":_vm.data.show_play_icon,"play-icon":_vm.data.play_icon,"play-icon-size":_vm.data.play_icon_size,"play-icon-color":_vm.data.play_icon_color,"title":_vm.data.video_title,"description":_vm.data.video_description}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }