<template>
  <div
    class="MagCeTabs"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id || data.id"
  >
    <PfTabs
      :position="data.tab_position"
      :align="parseAlignment(data.tab_align)"
      :active="data.active_tab"
      :titles="tabTitles"
      :mobile-accordion="data.mobile_accordion"
      :active-on-hover="data.hover_active"
      :no-fill-content-area="data.no_fill_content_area"
    >
      <PfTabTitle
        v-for="(item, index) in data.elements"
        v-if="!data.hide_empty_tab || item.elements.length"
        :key="item.id"
        :title="item.title"
        :index="index"
        :id="item.el_id || item.id"
      >
        <template #title="{ title, isOpen, tabClick, tabHover }">
          <div class="pf-tabs__title" :class="{ 'is-active': isOpen }" @click="tabClick" @mouseenter="tabHover">
            <template v-if="item.add_icon && item.icon_position === 'left'">
              <i :class="item.icon"></i>
            </template>
            {{ title }}
            <template v-if="item.add_icon && item.icon_position !== 'left'">
              <i :class="item.icon"></i>
            </template>
          </div>
        </template>
      </PfTabTitle>

      <template #content>
        <PfTabContent
          v-for="(item, index) in data.elements"
          :key="item.id"
          :index="index"
          :class="[
            item.el_class,
            item.animation_in ? `animate__animated animate__${item.animation_in} animate__delay-1s` : '',
            item.animation_delay ? `animate__delay-${item.animation_delay}s` : '',
            item.animation_infinite ? `animate__infinite` : '',
          ]"
        >
          <LazyHydrate when-visible v-for="(element, key) in item.elements" :key="key">
            <MagContentRenderer :data="element" :class="element.el_inner_class" />
          </LazyHydrate>
        </PfTabContent>
      </template>

      <template #mobile>
        <SfAccordionItem
          v-for="item in data.elements"
          :key="item.id"
          :header="item.title"
          v-if="!data.hide_empty_tab || item.elements.length"
          class="accordion-item"
          :id="item.el_id"
          :class="[
            item.el_class,
            item.animation_in ? `animate__animated animate__${item.animation_in} animate__delay-1s` : '',
            item.animation_delay ? `animate__delay-${item.animation_delay}s` : '',
            item.animation_infinite ? `animate__infinite` : '',
          ]"
        >
          <template #header="{ header, isOpen, accordionClick }">
            <div class="accordion-heading mgz-element" :class="{ 'is-active': isOpen }" @click="accordionClick">
              <template v-if="item.add_icon && item.icon_position === 'left'">
                <i :class="item.icon"></i>
              </template>
              {{ header }}
              <template v-if="item.add_icon && item.icon_position !== 'left'">
                <i :class="item.icon"></i>
              </template>
              <button type="button">
                <i class="icon-chevron-down"></i>
              </button>
            </div>
          </template>
          <div class="accordion-body">
            <LazyHydrate when-visible v-for="(element, key) in item.elements" :key="key">
              <MagContentRenderer :data="element" :class="item.el_inner_class" />
            </LazyHydrate>
          </div>
        </SfAccordionItem>
      </template>
    </PfTabs>
  </div>
</template>
<script>
import 'animate.css';
import { useContext } from '@nuxtjs/composition-api';
import { SfTabs } from '@storefront-ui/vue';
import LazyHydrate from 'vue-lazy-hydration';
import PfTabs from '~/components/organisms/PfTabs';
export default {
  name: 'MagCeTabs',
  components: {
    SfTabs,
    PfTabs,
    LazyHydrate,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    return {
      baseUrl,
    };
  },
  computed: {
    sourceData() {
      let result = this.data.content;
      const img = new RegExp(/<img .*? \/>/gm);
      const pattern = new RegExp(/url=[A-Za-z0-9@:%._\\+~#?&\/=]+/gm);
      let imageTagMatch = result.match(img);

      if (imageTagMatch) {
        for (let i = 0; i < imageTagMatch.length; i++) {
          let imageTag = imageTagMatch[i];

          const urlMatch = imageTag.match(pattern);
          if (urlMatch) {
            const url = this.baseUrl + urlMatch[0].split('=')[1];
            const newImageTag = imageTag.replace(/src=".*?"/gm, `src="${url}"`);
            result = result.replace(imageTag, newImageTag);
          }
        }
      }

      return result;
    },
    tabTitles() {
      return this.data?.elements?.map((x) => x.title) || [];
    },
  },
  methods: {
    parseAlignment(align) {
      let result = '';

      switch (align) {
        case 'left':
        case 'top':
          result = 'start';
          break;
        case 'right':
        case 'bottom':
          result = 'end';
          break;
        default:
          result = 'center';
          break;
      }
      return result;
    },
    tabsContentStyles(data) {
      return {
        '--mag-ce-tabs-item-gap': data.gap,
        '--mag-ce-tabs-item-content-border-style': data.no_fill_content_area ? 'none' : 'solid',
        '--mag-ce-tabs-item-content-bg-color': data.tab_content_background_color
          ? data.tab_content_background_color
          : data.no_fill_content_area
            ? 'transparent'
            : '#f8f8f8',
        '--mag-ce-tabs-item-heading-spacing': data.spacing,
        '--mag-ce-tabs-item-heading-color': data.tab_color,
        '--mag-ce-tabs-item-heading-hover-color': data.tab_hover_color,
        '--mag-ce-tabs-item-heading-active-color': data.tab_active_color,
        '--mag-ce-tabs-item-heading-bg-color': data.tab_background_color,
        '--mag-ce-tabs-item-heading-hover-bg-color': data.tab_hover_background_color,
        '--mag-ce-tabs-item-heading-active-bg-color': data.tab_active_background_color,
        '--mag-ce-tabs-item-heading-border-width': data.tab_border_width,
        '--mag-ce-tabs-item-heading-border-style': data.tab_border_style,
        '--mag-ce-tabs-item-heading-border-color': data.tab_border_color,
        '--mag-ce-tabs-item-heading-hover-border-color': data.tab_hover_border_color,
        '--mag-ce-tabs-item-heading-active-border-color': data.tab_active_border_color,
        '--mag-ce-tabs-item-heading-border-radius': data.tab_border_radius,
        '--mag-ce-tabs-item-heading-font-size': data.title_font_size,
      };
    },
    containerStyles(data, key) {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result[`--mag-ce-${key}-z-index`] = data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-ce-${key}-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_top`] || data.padding_top || 0
        }px`;
        result[`--mag-ce-${key}-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_right`] || data.padding_right || 0
        }px`;
        result[`--mag-ce-${key}-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_bottom`] || data.padding_bottom || 0
        }px`;
        result[`--mag-ce-${key}-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}padding_left`] || data.padding_left || 0
        }px`;
        result[`--mag-ce-${key}-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_top`] || data.margin_top || 0
        }px`;
        result[`--mag-ce-${key}-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_right`] || data.margin_right || 0
        }px`;
        result[`--mag-ce-${key}-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_bottom`] || data.margin_bottom || 0
        }px`;
        result[`--mag-ce-${key}-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}margin_left`] || data.margin_left || 0
        }px`;
        result[`--mag-ce-${key}-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_width`] || data.border_top_width || 0
        }px`;
        result[`--mag-ce-${key}-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_right_width`] || data.border_right_width || 0
        }px`;
        result[`--mag-ce-${key}-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_width`] || data.border_bottom_width || 0
        }px`;
        result[`--mag-ce-${key}-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_left_width`] || data.border_left_width || 0
        }px`;
        result[`--mag-ce-${key}-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}border_style`] || data.border_style || 'none';
        result[`--mag-ce-${key}-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}border_color`] || data.border_color || '#222';
        result[`--mag-ce-${key}-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_left_radius`] || data.border_top_left_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_top_right_radius`] || data.border_top_right_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_left_radius`] || data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-ce-${key}-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          data[`${responsiveStyles}border_bottom_right_radius`] || data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-ce-${key}-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = data.min_height
          ? `${data[`${responsiveStyles}min_height`] || data.min_height || 0}px`
          : '';
        result[`--mag-ce-${key}-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}el_float`] || data.el_float || 'none';
        result[`--mag-ce-${key}-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          data[`${responsiveStyles}align`] || data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-ce-${key}-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${data[`${responsiveStyles}background_image`] || data.background_image}')`;
          result[`--mag-ce-${key}-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_color`] || data.background_color || 'transparent';
          result[`--mag-ce-${key}-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_position`] || data.background_position
              ? (data[`${responsiveStyles}background_position`] || data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-ce-${key}-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_style`] || data.background_style || 'auto';
        } else {
          result[`--mag-ce-${key}-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            data[`${responsiveStyles}background_color`] || data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'mixins';
@import '~/static/css/fontawesome5.css';
@import '~/static/css/openiconic.min.css';

@mixin heading($state: '', $bgColor: #ebebeb, $borderColor: #e3e3e3, $color: #666666) {
  border-color: var(--mag-ce-tabs-item-heading#{$state}-border-color, $borderColor);
  background: var(--mag-ce-tabs-item-heading#{$state}-bg-color, $bgColor);
  color: var(--mag-ce-tabs-item-heading#{$state}-color, $color);
}

.MagCeTabs {
  @include DesignOptions(tabs);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(tabs, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(tabs, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(tabs, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(tabs, -xs_);
  }
}

::v-deep {
  .pf-tabs {
    &.has-position {
      &__top,
      &__bottom {
        .sf-tabs {
          &___trigger {
            &:not(:last-child) {
              margin-right: var(--mag-ce-tabs-item-heading-spacing, 2rem);
            }
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      &__left,
      &__right {
        .pf-tabs {
          &__header {
            border-bottom: none;
          }
          &__trigger {
            margin: calc(var(--mag-ce-tabs-item-heading-spacing, 2rem) / 2) 0;
          }
        }
      }
    }

    &__header {
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: 1px solid $border-color;
    }

    &__trigger {
      position: relative;
      padding: 0 20px;
      margin-right: 0 !important;

      &::after {
        content: '';
        width: 100%;
        height: 2px;
        background: $primary;
        display: block;
        position: absolute;
        bottom: -20px;
        left: 0;
        opacity: 0;
      }

      &.is-active {
        &::after {
          opacity: 1;
        }

        .pf-tabs {
          &__title {
            background: transparent;
            font-weight: 500;
          }
        }
      }
      &:hover {
        .pf-tabs {
          &__title {
            background: transparent;
          }
        }
      }
    }

    &__title {
      border: 0 none;
      padding: 0;
      background: transparent !important;
      font-size: 1rem;
    }

    &__body {
      background: transparent !important;
      border: 0 none !important;
    }

    &__content {
      .MagCeText {
        // background: $white !important;
        // border: 0 none !important;
      }
    }
  }

  .sf-accordion-item {
    @media (max-width: $lg) {
      padding: 10px 0;
    }
    .accordion-heading {
      border-width: var(--mag-ce-tabs-item-heading-border-width, 1px);
      border-style: var(--mag-ce-tabs-item-heading-border-style, solid);
      font-size: var(--mag-ce-tabs-item-heading-font-size, 12px);

      @include heading();

      &.is-active {
        @include heading('-active', #f8f8f8);
        i {
          color: var(--mag-ce-tabs-item-heading-active-color, #666666);
        }
      }

      &:hover {
        @include heading('-hover', #dcdcdc);
        i {
          color: var(--mag-ce-tabs-item-heading-hover-color, #666666);
        }
      }

      i {
        color: var(--mag-ce-tabs-item-heading-color, #666666);
      }
    }

    .accordion-body {
      @include DesignOptions(tabs-content);

      @include mq('tablet-wide', 'max') {
        @include DesignOptions(tabs-content, -lg_);
      }

      @include mq('tablet', 'max') {
        @include DesignOptions(tabs-content, -md_);
      }

      @include mq('phablet', 'max') {
        @include DesignOptions(tabs-content, -sm_);
      }

      @include mq('phone', 'max') {
        @include DesignOptions(tabs-content, -xs_);
      }

      margin: 0 !important;
    }

    &__content {
      border-width: var(--mag-ce-tabs-item-heading-border-width, 1px);
      border-color: var(--mag-ce-tabs-item-heading-border-color, #e3e3e3);
      border-style: var(--mag-ce-tabs-item-content-border-style, solid);
      background: var(--mag-ce-tabs-item-content-bg-color);
    }
  }
}
</style>
