import { render, staticRenderFns } from "./MagCeSlider.vue?vue&type=template&id=92037f42&scoped=true&"
import script from "./MagCeSlider.vue?vue&type=script&lang=js&"
export * from "./MagCeSlider.vue?vue&type=script&lang=js&"
import style0 from "./MagCeSlider.vue?vue&type=style&index=0&id=92037f42&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92037f42",
  null
  
)

export default component.exports