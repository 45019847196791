export default function ({ route, redirect }) {
  const htmlPostfix = /\.html$/.test(route.path);
  const hasTrailingSlash = /\/$/.test(route.path);
  if (!hasTrailingSlash && !htmlPostfix) {
    if (route.fullPath.includes('?')) {
      let url = route.fullPath.replace('?', '/?');
      redirect(301, `${url}`);
    } else {
      redirect(301, `${route.path}/`);
    }
  }
}
