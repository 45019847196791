export default `
query ($cartId: String!) {
  cart(
    cart_id: $cartId
  ) {
    items {
      uid
      ... on SimpleCartItem {
         engraving_options {
            label
            value
        }
      }
      ... on ConfigurableCartItem {
        engraving_options {
            label
            value
        }
      }
      ... on ConfigurableCartItem {
        configured_variant {
            sku
            name
        }
        configurable_options {
          configurable_product_option_uid
          configurable_product_option_value_uid
          id
          value_id
          option_label
          value_label
          swatch_data {
            value
            __typename
          }
        }
      }
    }
    mp_free_gifts {
      total_added
      gifts {
        id
        sku
      }
    }
  }
}`;
