<template>
  <div :class="getPostsClass(index)">
    <article :key="post.id" :id="`article-${post.id}`" class="blogpost bg-white mb-0 h-100" :data-index="index">
      <div class="blogpost--item">
        <div class="row">
          <div :class="getPostsColClass(index, 'img')" class="">
            <figure class="figure p-0 m-0">
              <nuxt-img :src="featuredImage" :alt="getTitle(post)" class="img-fluid w-100" />
            </figure>
          </div>
          <div class="p-4" :class="getPostsColClass(index, 'text')">
            <div class="blogpost--header">
              <p class="text-end date" v-html="getPostDate(post)" />
              <nav v-if="postType == 'posts'">
                <ul class="blanklist tag">
                  <li v-for="category in getCategories(post)" :key="category">
                    <button
                      @click="changeCategory(getCategoryById(category).slug, getCategoryById(category).slug)"
                      class=""
                    >
                      {{ getCategoryById(category).name }}
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
            <h3 v-html="getTitle(post)" />
            <div v-html="getTeaser(post)" />
            <p>
              <nuxt-link :to="`/${linkBase}/${post.slug}/`" class="btn btn-blank btn-arrow">Weiterlesen</nuxt-link>
            </p>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { useBlogStore } from '~/stores/blog';
import { useContext, computed, useRouter } from '@nuxtjs/composition-api';
import axios from 'axios';

export default {
  name: 'PostCard',
  props: {
    post: {
      type: Object,
      required: true,
    },
    postType: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      featuredImage: '',
    };
  },
  setup() {
    const blogStore = useBlogStore();
    const categories = computed(() => blogStore.categories);
    const order = computed(() => blogStore.order);
    const router = useRouter();

    const getCategoryById = (id) => {
      return blogStore.getCategoryById(id);
    };
    const changeCategory = async (slug, id) => {
      blogStore.setCurrentCategory(slug);
      blogStore.setLoaded(false);
      blogStore.setCategories(categories.value);

      await blogStore.reloadPosts(10, order.value);

      if (slug) {
        await router.push({ path: '/blog/', query: { c: slug } });
      } else {
        await router.push({ path: '/blog/' });
      }
    };

    return {
      getCategoryById,
      changeCategory,
    };
  },
  computed: {
    linkBase() {
      if (this.postType === 'tribe_events') {
        return 'blog/events';
      }
      return 'blog';
    },
  },
  methods: {
    getPostDate(post) {
      let showDate = '';
      let startTime = '';
      let endTime = '';
      if (this.postType === 'tribe_events') {
        showDate = post['start_date'];
        const endDate = new Date(post['end_date']);
        const startDate = new Date(post['start_date']);
        startTime = ' | ' + startDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
        endTime = ' - ' + endDate.toLocaleTimeString('de-DE', { hour: '2-digit', minute: '2-digit' });
      } else {
        showDate = post.date;
      }

      const date = new Date(showDate),
        options = {
          year: 'numeric',
          month: '2-digit',
          day: 'numeric',
        };

      return date.toLocaleString('de-DE', options) + ' ' + startTime + endTime;
    },
    getPostsClass(i) {
      if (i === 0 || i === 1) {
        return 'col-12';
      }
      if (i === 2 || i === 3) {
        return 'col-12 col-sm-6 blogpost--item-alt';
      }
      return 'col-12 col-sm-6 col-lg-4 blogpost--item-alt';
    },
    getPostsColClass(i, type) {
      if (i === 0 || i === 1) {
        if (i === 1 && type === 'img') {
          return 'col-12 col-sm-6 col-lg-8 order-sm-2';
        }
        if (i === 1 && type === 'text') {
          return 'col-12 col-sm-6 col-lg-4 order-sm-1';
        }
        if (type === 'img') {
          return 'col-12 col-sm-6 col-lg-8';
        }
        return 'col-12 col-sm-6 col-lg-4';
      }
      return 'col-12';
    },
    getCategories(post) {
      return post?.categories;
    },
    getTeaser(post) {
      return post?.excerpt?.rendered;
    },
    getTitle(post) {
      if (this.postType === 'tribe_events') {
        return post?.title;
      }
      return post?.title?.rendered;
    },
  },
  async created() {
    this.featuredImage = '/imgs/placeholder-blog-grid.png';
    if (this.post?.jetpack_featured_media_url) {
      this.featuredImage = this.post?.jetpack_featured_media_url;
      return;
    }
    if (this.post?.image?.url) {
      this.featuredImage = this.post?.image?.url;
      return;
    }

    const fetchUrl = '/blog-api/media/' + this.post['featured_media'];
    // console.log(fetchUrl);
    try {
      const response = await axios.get(fetchUrl);

      if (response) {
        if (response.data['source_url']) {
          this.featuredImage = response.data['source_url'];
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/blog/blogStyles.scss';
</style>
