export default `
query getRouteData($url: String!){
  route(url: $url) {
    __typename
    relative_url
    redirect_code
    type
    ... on CategoryInterface {
        uid
        id
    }

    ... on ProductInterface {
      sku
      url_key
      uid
      stock_status
      show_product_page
    }
    ... on CmsPage {
      identifier
    }
  }
}`;
