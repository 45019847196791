<template>
  <div
    class="MagCeImageCarousel"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.id"
    :style="{
      ...containerStyles,
    }"
  >
    <SfModal
      :title="selectedSlide.popup_title"
      :visible="isImageModalOpened"
      @close="isImageModalOpened = false"
      :persistent="false"
      overlay
      :style="{
        '--modal-content-padding': 0,
        '--modal-index': 9999,
      }"
    >
      <div class="img_popup_title" v-if="selectedSlide.popup_title">
        {{ selectedSlide.popup_title }}
      </div>
      <nuxt-img
        :src="`${baseUrl + selectedSlide.popup_image}`"
        :alt="selectedSlide.popup_title"
        :style="{ width: '100%' }"
      />
    </SfModal>

    <div
      class="pf-separator-content"
      v-if="data.title"
      :style="{
        '--divider-border-position': data.line_position === 'bottom' ? 'end' : 'center',
        '--mag-image-carousel-widget-align': data.title_align,
      }"
    >
      <SfDivider
        v-if="data.title_align !== 'left' && data.show_line"
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-weight': data.line_weight,
        }"
      />
      <component :is="data.title_tag" class="pf-separator-label" :class="data.el_inner_class">
        <div class="mag-ce-image-carousel-title">{{ data.title }}</div>
        <div class="mag-ce-image-carousel-description">
          {{ data.description }}
        </div>
      </component>
      <SfDivider
        v-if="data.title_align !== 'right' && data.show_line"
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-weight': data.line_weight,
        }"
      />
    </div>
    <PfOwlCarousel
      :config="{
        dots: data.owl_dots || false,
        infinite: data.owl_loop || false,
        speed: 500,
        autoplay: data.owl_autoplay || false,
        autoplaySpeed: data.owl_autoplay_speed || 2000,
        slidesToShow: Number(data.owl_item_xl) || 5,
        slidesToScroll: Number(data.owl_slide_by) || 1,
        arrows: data.owl_nav || false,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: data.owl_item_lg || data.owl_item_xl || 5,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: data.owl_item_md || data.owl_item_lg || data.owl_item_xl || 5,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: data.owl_item_sm || data.owl_item_md || data.owl_item_lg || data.owl_item_xl || 5,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow:
                data.owl_item_xs || data.owl_item_sm || data.owl_item_md || data.owl_item_lg || data.owl_item_xl || 5,
            },
          },
        ],
      }"
      :class="[
        `mgz-carousel-nav-size-${data.owl_nav_size}`,
        `mgz-carousel-nav-position-${data.owl_nav_position}`,
        data.el_inner_class,
        extraClasses,
      ]"
      :style="{
        '--owl-color': data.owl_color,
        '--owl-background-color': data.owl_background_color,
        '--owl-hover-color': data.owl_hover_color,
        '--owl-hover-background-color': data.owl_hover_background_color,
        '--owl-active-color': data.owl_active_color,
        '--owl-active-background-color': data.owl_active_background_color,
        '--owl-margin': `${data.owl_margin}px` || '0px',
      }"
      @changed="changeSlide"
    >
      <template v-for="(item, index) in data.items">
        <div :key="index" class="slider-item" :style="{ ...position(data) }">
          <component
            :is="item.custom_link ? 'a' : 'div'"
            class="slider-item__content"
            @click="openImageModal(item, !item.custom_link)"
            v-bind="paths[index]"
          >
            <div
              class="slider-item__content__outer"
              :style="{
                '--mag-ce-image-slider-item-padding': data.content_padding,
                '--mag-ce-image-slider-item-width': data.content_fullwidth ? '100%' : 'auto',
                '--mag-ce-image-slider-item-color': data.content_color,
                '--mag-ce-image-slider-item-background-color': data.content_background,
                '--mag-ce-image-slider-item-border-width': data.image_border_width,
                '--mag-ce-image-slider-item-border-radius': data.image_border_radius,
                '--mag-ce-image-slider-item-border-style': data.image_border_style,
                '--mag-ce-image-slider-item-border-color': data.image_border_color,
              }"
            >
              <div class="slider-item__content__inner" :class="[data.hover_effect]">
                <div class="slide-item-background">
                  <nuxt-img :src="`${baseUrl + item.image}`" alt="" />
                </div>

                <div
                  class="slider-item__content__wrapper"
                  :class="{
                    'display-on-hover': data.display_on_hover,
                  }"
                  v-if="data.content_position !== 'below'"
                >
                  <div
                    class="slider-item__heading"
                    v-if="item.title"
                    :style="{
                      '--mag-ce-image-slider-item-title-font-size': data.title_font_size,
                      '--mag-ce-image-slider-item-title-font-weight': data.title_font_weight,
                    }"
                  >
                    {{ item.title }}
                  </div>
                  <div
                    class="slider-item__description"
                    :style="{
                      '--mag-ce-image-slider-item-description-font-size': data.description_font_size,
                      '--mag-ce-image-slider-item-description-font-weight': data.description_font_weight,
                    }"
                    v-if="item.description"
                  >
                    {{ item.description }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="slider-item__content__overlay"
              :style="{
                '--mag-ce-image-slider-item-overlay': data.overlay_color,
              }"
            ></div>
          </component>
          <div
            class="slider-item__wrapper"
            :class="{
              'display-on-hover': data.display_on_hover,
            }"
            v-if="data.content_position === 'below'"
          >
            <div
              class="slider-item__heading"
              v-if="item.title"
              :style="{
                '--mag-ce-image-slider-item-title-font-size': data.title_font_size,
                '--mag-ce-image-slider-item-title-font-weight': data.title_font_weight,
              }"
            >
              {{ item.title }}
            </div>
            <div
              class="slider-item__description"
              :style="{
                '--mag-ce-image-slider-item-description-font-size': data.description_font_size,
                '--mag-ce-image-slider-item-description-font-weight': data.description_font_weight,
              }"
              v-if="item.description"
            >
              {{ item.description }}
            </div>
          </div>
        </div>
      </template>
    </PfOwlCarousel>
  </div>
</template>

<script>
import 'animate.css';
import { SfDivider, SfModal } from '@storefront-ui/vue';
import PfOwlCarousel from '~/components/organisms/PfOwlCarousel.vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useContext, useFetch } from '@nuxtjs/composition-api';
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { useLinks } from '~/composables/useLinks';

export default defineComponent({
  name: 'MagCeImageCarousel',
  components: {
    LazyHydrate,
    SfModal,
    SfDivider,
    PfOwlCarousel,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup(props) {
    const context = useContext();
    const { getLink, paths, loading } = useLinks();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    const extraClasses = ref([]);

    if (props.data.owl_auto_height) extraClasses.value.push('autoHeight');

    useFetch(() => {
      if (props.data && props.data.items) {
        getLink(props.data.items);
      }
    });

    return {
      extraClasses,
      baseUrl,
      paths,
      loading,
    };
  },
  data() {
    return {
      activeSlideIndex: 0,
      selectedSlide: {},
      isImageModalOpened: false,
    };
  },
  computed: {
    containerStyles() {
      let result = {};
      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-image-carousel-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-image-carousel-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-image-carousel-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-image-carousel-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-image-carousel-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-image-carousel-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-image-carousel-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-image-carousel-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-image-carousel-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-image-carousel-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-image-carousel-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-image-carousel-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-image-carousel-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-image-carousel-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-image-carousel-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-image-carousel-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-image-carousel-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-image-carousel-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-image-carousel-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-image-carousel-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data
          .min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-image-carousel-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-image-carousel-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-image-carousel-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-image-carousel-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-image-carousel-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-image-carousel-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-image-carousel-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
  methods: {
    openImageModal(item, enabled) {
      if (item.popup_image && enabled) {
        this.selectedSlide = item;
        this.isImageModalOpened = true;
      }
    },
    changeSlide(e) {
      this.activeSlideIndex = e.page.index;
    },
    animations(animation, delay, rowIndex) {
      let result = '';
      if (this.activeSlideIndex === rowIndex) {
        if (animation) result += `animate__animated animate__${animation}`;

        if (delay) result += ` animate__delay-${delay}s`;
      }

      return result;
    },
    position(item) {
      let result = {};
      if (!item.content_position) {
        result['--slider-item-content-justify-content'] = 'center';
        result['--slider-item-content-align-items'] = 'center';
        return result;
      }

      const parts = item.content_position
        .replace(/top|left/gm, 'flex-start')
        .replace(/bottom|right/gm, 'flex-end')
        .replace('middle', 'center')
        .split(/-|_/gm);

      if (parts.length) {
        result['--slider-item-content-justify-content'] = parts[0];
        result['--slider-item-content-align-items'] = parts[1];
      }

      return result;
    },
  },
});
</script>

<style lang="scss" scoped>
@import 'mixins';

.MagCeImageCarousel {
  @include DesignOptions(image-carousel);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(image-carousel, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(image-carousel, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(image-carousel, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(image-carousel, -xs_);
  }

  @media (max-width: $lg) {
    margin: 20px 0;
  }
}

.pf-separator-content {
  display: flex;
  justify-content: center;
  align-items: var(--divider-border-position);
  flex-direction: row;
  margin: 0 auto;
  > .sf-divider {
    flex: 1 1 0;
  }

  .pf-separator-label {
    text-align: var(--mag-image-carousel-widget-align);
  }

  .mag-ce-image-carousel-description {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.slider-item {
  &__content {
    position: relative;

    &__wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: var(--slider-item-content-justify-content, center);
      align-items: var(--slider-item-content-align-items, center);

      &.display-on-hover {
        opacity: 0;
        transition: all 0.4s;
      }
    }

    &__overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: var(--mag-ce-image-slider-item-overlay, transparent);
    }

    &__outer,
    &__inner {
      overflow: hidden;
    }

    &__inner {
      transform: none;
      transition: all 0.4s;
      opacity: 1;
      border-radius: var(--mag-ce-image-slider-item-border-radius, 0);
      border-width: var(--mag-ce-image-slider-item-border-width, 0);
      border-style: var(--mag-ce-image-slider-item-border-style, none);
      border-color: var(--mag-ce-image-slider-item-border-color, transparent);
    }

    &:hover {
      .slider-item {
        &__content {
          &__inner {
            opacity: 0.9;
            &.zoomin {
              transform: scale3d(1.1, 1.1, 1);
            }

            &.zoomout {
              transform: scale3d(0.9, 0.9, 1);
            }
          }

          &__wrapper {
            &.display-on-hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  &__heading,
  &__description {
    width: var(--mag-ce-image-slider-item-width);
    text-align: center;
    padding: var(--mag-ce-image-slider-item-padding, 10px 20px);
  }

  &__description {
    margin-top: 5px;
    color: var(--mag-ce-image-slider-item-color, #fff);
    background-color: var(--mag-ce-image-slider-item-background-color, rgba(10, 10, 10, 0.6));
    font-size: var(--mag-ce-image-slider-item-description-font-size, 14px);
    font-weight: var(--mag-ce-image-slider-item-description-font-weight, normal);
  }
  &__heading {
    color: var(--mag-ce-image-slider-item-color, #fff);
    background-color: var(--mag-ce-image-slider-item-background-color, rgba(10, 10, 10, 0.6));
    font-size: var(--mag-ce-image-slider-item-title-font-size, 14px);
    font-weight: var(--mag-ce-image-slider-item-title-font-weight, normal);
  }
}

::v-deep {
  .mgz-carousel-nav-size {
    &-mini {
      .owl-nav {
        [class*='owl-'] {
          width: 20px;
          height: 20px;
          line-height: 20px;
        }
      }
    }
    &-small {
      .owl-nav {
        [class*='owl-'] {
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
    &-normal {
      .owl-nav {
        [class*='owl-'] {
          width: 40px;
          height: 40px;
          line-height: 40px;
        }
      }
    }
    &-large {
      .owl-nav {
        [class*='owl-'] {
          width: 50px;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  .mgz-carousel-nav-position {
    &-top_left,
    &-top_right,
    &-top_split {
      .owl-nav {
        margin-top: 0;
        position: absolute;
      }

      &.mgz-carousel-nav-size {
        &-mini {
          padding-top: 30px;
          .owl-nav {
            top: -30px;
          }
        }
        &-small {
          padding-top: 40px;
          .owl-nav {
            top: -40px;
          }
        }
        &-normal {
          padding-top: 50px;
          .owl-nav {
            top: -50px;
          }
        }
        &-large {
          padding-top: 60px;
          .owl-nav {
            top: -60px;
          }
        }
      }
    }
    &-top_left {
      .owl-nav {
        left: 0;
      }
    }
    &-top_right {
      .owl-nav {
        right: 0;
      }
    }
    &-bottom_left {
      .owl-nav {
        text-align: left;
        margin-top: 10px;
      }
    }
    &-bottom_right {
      .owl-nav {
        text-align: right;
        margin-top: 10px;
      }
    }
    &-bottom_center {
      .owl-nav {
        margin-top: 10px;
        text-align: center;
        .owl-prev {
          margin-right: 5px;
        }
      }
    }

    &-top_split,
    &-bottom_split,
    &-center_split {
      .owl-nav {
        position: absolute;
        width: 100%;
        left: 0;
        height: 0;
        margin-top: 0;

        .owl-prev {
          float: left;
        }

        .owl-next {
          float: right;
        }
      }
    }

    &-center_split {
      .owl-nav {
        opacity: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      &:hover {
        .owl-nav {
          opacity: 1;
        }
      }

      &.mgz-carousel-nav-size {
        &-mini {
          .owl-nav {
            margin-top: -10px;
          }
        }
        &-small {
          .owl-nav {
            margin-top: -15px;
          }
        }
        &-normal {
          .owl-nav {
            margin-top: -20px;
          }
        }
        &-large {
          .owl-nav {
            margin-top: -25px;
          }
        }
      }
    }

    &-bottom_split {
      .owl-nav {
        bottom: 0;
      }
      &.mgz-carousel-nav-size {
        &-mini {
          padding-bottom: 30px;
        }
        &-small {
          padding-bottom: 40px;
        }
        &-normal {
          padding-bottom: 50px;
        }
        &-large {
          padding-bottom: 60px;
        }
      }
    }
  }

  .pf-owl-carousel-wrapper {
    .slick-list {
      margin: 0 -var(--owl-margin);
    }
    .slick-slide > div {
      padding: 0 var(--owl-margin);
    }
    .slick-prev:before,
    .slick-next:before {
      color: #000;
    }
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
  }
}
.pf-owl-carousel-wrapper {
  &.autoHeight {
    .slider-item__content {
      .slide-item-background {
        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
}
.img_popup_title {
  padding: 10px;
}
</style>
