import { defineStore } from 'pinia';
import { AggregationOption } from '~/modules/GraphQL/types';

interface FilterParams {
  [key: string]: (null | string | AggregationOption)[];
}
interface SortParams {
  sort_by: string;
  sort_direction: string;
}
interface FilterDataState {
  catUrl: string | null;
  filterData: FilterParams | null;
  sortData: SortParams | null;
}
export const useFilterdata = defineStore({
  id: 'useFilterdata',
  state: (): FilterDataState => ({
    catUrl: null,
    filterData: null,
    sortData: null,
  }),

  getters: {
    getFilterDataState(state) {
      return state;
    },
  },
  actions: {
    cleanup() {
      this.sortData = null;
      this.filterData = null;
      this.catUrl = null;
    },
    setFilterData(data: FilterParams) {
      this.filterData = data;
    },
    setSortData(data: SortParams) {
      this.sortData = data;
    },
    setCatUrl(url: string) {
      this.catUrl = url;
    },
  },
});
