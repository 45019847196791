var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeButton",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],attrs:{"id":_vm.data.el_id}},[(_vm.isAnchor(_vm.link.url))?[_c('a',{class:[
        _vm.data.el_inner_class ? _vm.data.el_inner_class : 'btn-primary',
        _vm.data.display_as_link ? 'as-link' : '',
        ("mgz-btn-size-" + (_vm.data.button_size)),
        ("mgz-btn-style-" + (_vm.data.button_style)),
        _vm.data.full_width ? 'w-100' : '',
        'btn' ],attrs:{"href":_vm.link.url},on:{"click":function($event){$event.preventDefault();return _vm.scrollToAnchor(_vm.link.url)}}},[(_vm.data.add_icon && _vm.data.icon_position === 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e(),_vm._v("\n      "+_vm._s(_vm.data.title)+"\n      "),(_vm.data.add_icon && _vm.data.icon_position !== 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e()],2)]:[_c('SfButton',_vm._b({staticClass:"btn",class:[
        _vm.data.el_inner_class ? _vm.data.el_inner_class : 'btn-primary',
        _vm.data.display_as_link ? 'as-link' : '',
        ("mgz-btn-size-" + (_vm.data.button_size)),
        ("mgz-btn-style-" + (_vm.data.button_style)),
        _vm.data.full_width ? 'w-100' : '' ],attrs:{"link":_vm.link.url,"disabled":_vm.data.disable_element}},'SfButton',_vm.link,false),[(_vm.data.add_icon && _vm.data.icon_position === 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e(),_vm._v("\n      "+_vm._s(_vm.data.title)+"\n      "),(_vm.data.add_icon && _vm.data.icon_position !== 'left')?[_c('i',{class:_vm.data.icon})]:_vm._e()],2)],_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.embeddedStyles)}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }