export default `
query categoryDataQuery($filters: CategoryFilterInput) {
	categories(
		filters: $filters
	) {
		items {
			uid
			name
			url_path
			url_key
		}
	}
}`;

export declare type filterMaybe<T> = T | null;
export interface CatFilterEqualTypeInput {
  eq?: filterMaybe<string>;
  in?: filterMaybe<Array<filterMaybe<string>>>;
}
export interface CategoryFilterInput {
  ids?: filterMaybe<CatFilterEqualTypeInput>;
  category_uid?: filterMaybe<CatFilterEqualTypeInput>;
}
export interface CatDataQueryResponseItemInterface {
  uid?: filterMaybe<string>;
  name?: filterMaybe<string>;
  url_path?: filterMaybe<string>;
  url_key?: filterMaybe<string>;
}
export interface CatDataQueryResponseInterface {
  categories?: {
    items?: filterMaybe<Array<filterMaybe<CatDataQueryResponseItemInterface>>>;
  };
}
