export default `
query getProductUrlData($filters: ProductAttributeFilterInput!){
	products(filter: $filters) {
	  items {
            uid
            sku
            name

            url_key
            url_suffix
        }
	}
}
`;
