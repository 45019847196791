export default `
query getProductManufCatData($filter: ProductAttributeFilterInput!){
	products(filter: $filter) {
	  items {
            uid
            sku
            name

            product_top_category
            manufacturer_main_category
            category_type_main_txt
            category_type_manufacturer_txt
        }
	}
}
`;
