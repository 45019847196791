<template>
  <div>
    <VueSlickCarousel
      :slidesToShow="1"
      ref="c1"
      :asNavFor="c2"
      :focusOnSelect="true"
      :arrows="data.arrows || false"
      class="photo-slider"
    >
      <div class="slide-item" v-for="item in data.items" :key="item.caption">
        <figure class="image mb-0" v-if="item.image">
          <nuxt-img :src="`${baseUrl + item.image}`" :alt="item.type" />
          <figcaption v-html="item.caption" class="photo-slider--caption" />
        </figure>
        <figure class="image mb-0" v-else>
          <nuxt-img :src="item.link" :alt="item.type" />
          <figcaption v-html="item.caption" class="photo-slider--caption" />
        </figure>
      </div>
    </VueSlickCarousel>

    <template v-if="data.items.length < 4">
      <VueSlickCarousel
        ref="c2"
        :asNavFor="c1"
        class="photo-slider--nav"
        :slidesToShow="data.items.length"
        :focusOnSelect="true"
      >
        <div class="slide-item" v-for="item in data.items" :key="item.caption">
          <figure class="image mb-0" v-if="item.image">
            <nuxt-img :src="`${baseUrl + item.image}`" :alt="item.type" />
          </figure>
          <figure class="image mb-0" v-else>
            <nuxt-img :src="item.link" :alt="item.type" />
          </figure>
        </div>
      </VueSlickCarousel>
    </template>
    <template v-else>
      <VueSlickCarousel ref="c2" :asNavFor="c1" class="photo-slider--nav" :slidesToShow="4" :focusOnSelect="true">
        <div class="slide-item" v-for="item in data.items" :key="item.caption">
          <figure class="image mb-0" v-if="item.image">
            <nuxt-img :src="`${baseUrl + item.image}`" :alt="item.type" />
          </figure>
          <figure class="image mb-0" v-else>
            <nuxt-img :src="item.link" :alt="item.type" />
          </figure>
        </div>
      </VueSlickCarousel>
    </template>
  </div>
</template>

<script>
import { useContext } from '@nuxtjs/composition-api';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
  name: 'MagCeImageGallery',
  components: {
    VueSlickCarousel,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    return {
      baseUrl,
    };
  },
  data() {
    return {
      slideSettings: {
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
        speed: 3000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              autoplay: true,
              autoplaySpeed: 4500,
            },
          },
        ],
      },

      items: [1, 2, 3, 4, 5],
      c1: undefined,
      c2: undefined,
    };
  },
  computed: {},
  methods: {
    slide(item) {
      return `<nuxt-img src="${item.link}" width="${this.data.thumbwidth}" height="${this.data.thumbheight}" data-caption="${item.caption}"/> /`;
    },
  },

  mounted() {
    this.c1 = this.$refs.c1;
    this.c2 = this.$refs.c2;
  },
};
</script>

<style lang="scss" scoped>
.photo-slider {
  .slide-item {
    figure.image {
      height: 100%;
      width: 100%;
      position: relative;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &--caption {
    padding: 10px 40px;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: max-content;
    z-index: 1;
    background: $white;
    border-bottom: 1px solid $border-color;
    border-right: 1px solid $border-color;

    ::v-deep {
      p {
        margin: 0;
        text-align: center;
        color: $grey-900;
        font-style: italic;
      }
    }
  }

  &--nav {
    margin-top: 1rem;

    ::v-deep {
      .slick-track {
        display: flex;
        justify-content: center;

        .slick-slide {
          width: auto !important;
          max-width: inherit;
          position: relative;
          margin: 0 10px;
          cursor: pointer;

          &::before {
            @extend .iconElement;
            content: $icon-search;
            font-size: 22px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: $white;
            z-index: 1;
            transition: $transition-base;
            opacity: 0;
          }

          &::after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: $black;
            opacity: 0;
            transition: $transition-base;
          }

          &.slick-current {
            &::before {
              opacity: 1;
            }

            &::after {
              opacity: 0.75;
            }
          }
        }
      }
    }

    .slide-item {
      height: 100%;
      display: flex !important;
      justify-content: center;
    }

    figure.image {
      img {
        border: 1px solid $border-color;
        width: auto;
        height: 100%;
        max-height: 60px;
        min-height: 60px;
        object-fit: cover;
        padding: 5px;
      }
    }
  }
  ::v-deep {
    .slick-prev:before,
    .slick-next:before {
      color: #000;
    }
    .slick-prev {
      left: -20px;
    }
    .slick-next {
      right: -20px;
    }
  }
}
</style>
