import Vue from 'vue';

import MagContentRenderer from '~/components/Magento/MagContentRenderer.vue';
import MagCeDefault from '~/components/Magento/MagCeDefault';
import MagCeMissing from '~/components/Magento/MagCeMissing';
import MagCeRow from '~/components/Magento/MagCeRow.vue';
import MagCeColumn from '~/components/Magento/MagCeColumn.vue';
import MagCeText from '~/components/Magento/MagCeText.vue';
import MagCeHeading from '~/components/Magento/MagCeHeading.vue';
import MagCeWidget from '~/components/Magento/MagCeWidget.vue';
import MagCeMagentoWidget from '~/components/Magento/MagCeMagentoWidget.vue';
import MagCeStaticBlock from '~/components/Magento/MagCeStaticBlock.vue';
import MagCeSingleImage from '~/components/Magento/MagCeSingleImage.vue';

import MagCeContactForm from '~/components/Magento/MagCeContactForm.vue';
import MagCeSection from '~/components/Magento/MagCeSection.vue';
import MagCeButton from '~/components/Magento/MagCeButton.vue';
import MagCeSeparator from '~/components/Magento/MagCeSeparator.vue';
import MagCeImageGallery from '~/components/Magento/MagCeImageGallery.vue';
import MagCeVideo from '~/components/Magento/MagCeVideo.vue';
import MagCeIcon from '~/components/Magento/MagCeIcon.vue';
import MagCeSlider from '~/components/Magento/MagCeSlider.vue';
import MagCeSocialIcons from '~/components/Magento/MagCeSocialIcons.vue';
import MagCeAccordion from '~/components/Magento/MagCeAccordion.vue';
import MagCeRawHtml from '~/components/Magento/MagCeRawHtml.vue';
import MagCeGmaps from '~/components/Magento/MagCeGMaps.vue';
import MagCeTabs from '~/components/Magento/MagCeTabs.vue';
import MagCeIconList from '~/components/Magento/MagCeIconList.vue';
import MagCeImageCarousel from '~/components/Magento/MagCeImageCarousel.vue';

const components = {
  MagContentRenderer,
  MagCeDefault,
  MagCeMissing,
  MagCeRow,
  MagCeColumn,
  MagCeText,
  MagCeHeading,
  MagCeWidget,
  MagCeMagentoWidget,
  MagCeStaticBlock,
  MagCeSingleImage,
  MagCeContactForm,
  MagCeSection,
  MagCeButton,
  MagCeSeparator,
  MagCeImageGallery,
  MagCeVideo,
  MagCeIcon,
  MagCeSlider,
  MagCeSocialIcons,
  MagCeAccordion,
  MagCeRawHtml,
  MagCeGmaps,
  MagCeTabs,
  MagCeIconList,
  MagCeImageCarousel,
};

Object.keys(components).forEach((key) => {
  Vue.component(key, components[key]);
});
