<template>
  <div
    class="MagCeIcon"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      ...containerStyles,
    }"
  >
    <component
      :is="data.link_url ? 'a' : 'div'"
      :href="link.url"
      v-bind="link"
      class="mag-icon-wrapper"
      :class="[data.el_inner_class, `mgz-icon-size-${data.icon_size}`]"
      :style="{
        '--icon-color': data.icon_color,
        '--icon-background-color': data.icon_background_color,
        '--icon-border-color': data.icon_border_color,
        '--icon-border-width': data.icon_border_width,
        '--icon-border-style': data.icon_border_style,
        '--icon-hover-color': data.icon_hover_color,
        '--icon-hover-background-color': data.icon_hover_background_color,
        '--icon-hover-border-color': data.icon_hover_border_color,
      }"
    >
      <i class="mgz-icon-element mag-icon" :class="data.icon"></i>
    </component>
  </div>
</template>

<script>
import 'animate.css';
export default {
  name: 'MagCeIcons',
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    link() {
      const link = this.data.link_url;
      let linkDict = {};
      if (link) {
        // const matches = link.match(/url=".*?"|blank=\w+|title=".*?"|nofollow=\w+/gm)

        // for (let i = 0; i < matches.length; i++) {
        //   const keyValuePair = matches[i].split("=");
        //   switch (keyValuePair[0]) {
        //     case 'blank':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.target = '_blank'
        //       break
        //     case 'nofollow':
        //       if(keyValuePair[1] === 'true')
        //         linkDict.rel = 'nofollow'
        //       break
        //     default:
        //       linkDict[keyValuePair[0]] = keyValuePair[1].replace(/"/gi, "")
        //       break
        //   }
        // }

        linkDict.url = link?.url ?? '';
        linkDict.title = link?.title ?? '';
        linkDict.target = link?.target && link?.target == 'true' ? '_blank' : '';
        linkDict.rel = link?.nofollow && link?.nofollow == 'true' ? 'nofollow' : '';
      }

      return linkDict;
    },
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-icon-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-icon-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-icon-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-icon-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-icon-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-icon-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-icon-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-icon-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-icon-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-icon-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-icon-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-icon-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-icon-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-icon-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-icon-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-icon-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-icon-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-icon-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-icon-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-icon-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-icon-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-icon-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-icon-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-icon-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-icon-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-icon-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-icon-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~/static/css/fontawesome5.css';
@import '~/static/css/openiconic.min.css';

.MagCeIcon {
  display: flex;
  z-index: var(--mag-icon-z-index);
  float: var(--mag-icon-float);
  background-color: var(--mag-icon-background-color);
  background-image: var(--mag-icon-background-image);
  background-position: var(--mag-icon-background-position);
  background-size: var(--mag-icon-background-size);

  padding: var(--mag-icon-padding-top) var(--mag-icon-padding-right) var(--mag-icon-padding-bottom)
    var(--mag-icon-padding-left);

  margin: var(--mag-icon-margin-top) var(--mag-icon-margin-right) var(--mag-icon-margin-bottom)
    var(--mag-icon-margin-left);

  border-width: var(--mag-icon-border-top) var(--mag-icon-border-right) var(--mag-icon-border-bottom)
    var(--mag-icon-border-left);

  border-radius: var(--mag-icon-border-radius-top-left) var(--mag-icon-border-radius-top-right)
    var(--mag-icon-border-radius-bottom-left) var(--mag-icon-border-radius-bottom-right);

  border-style: var(--mag-icon-border-style);
  border-color: var(--mag-icon-border-color);

  justify-content: var(--mag-icon-align);

  a {
    text-decoration: none;
  }

  @include mq('tablet-wide', 'max') {
    float: var(--mag-icon-float-lg_);
    background-color: var(--mag-icon-background-color-lg_);
    background-image: var(--mag-icon-background-image-lg_);
    background-position: var(--mag-icon-background-position-lg_);
    background-size: var(--mag-icon-background-size-lg_);
    padding: var(--mag-icon-padding-top-lg_) var(--mag-icon-padding-right-lg_) var(--mag-icon-padding-bottom-lg_)
      var(--mag-icon-padding-left-lg_);

    margin: var(--mag-icon-margin-top-lg_) var(--mag-icon-margin-right-lg_) var(--mag-icon-margin-bottom-lg_)
      var(--mag-icon-margin-left-lg_);

    border-width: var(--mag-icon-border-top-lg_) var(--mag-icon-border-right-lg_) var(--mag-icon-border-bottom-lg_)
      var(--mag-icon-border-left-lg_);

    border-radius: var(--mag-icon-border-radius-top-left-lg_) var(--mag-icon-border-radius-top-right-lg_)
      var(--mag-icon-border-radius-bottom-left-lg_) var(--mag-icon-border-radius-bottom-right-lg_);

    border-style: var(--mag-icon-border-style-lg_);
    border-color: var(--mag-icon-border-color-lg_);

    justify-content: var(--mag-icon-align-lg_);
  }

  @include mq('tablet', 'max') {
    float: var(--mag-icon-float-md_);
    background-color: var(--mag-icon-background-color-md_);
    background-image: var(--mag-icon-background-image-md_);
    background-position: var(--mag-icon-background-position-md_);
    background-size: var(--mag-icon-background-size-md_);
    padding: var(--mag-icon-padding-top-md_) var(--mag-icon-padding-right-md_) var(--mag-icon-padding-bottom-md_)
      var(--mag-icon-padding-left-md_);

    margin: var(--mag-icon-margin-top-md_) var(--mag-icon-margin-right-md_) var(--mag-icon-margin-bottom-md_)
      var(--mag-icon-margin-left-md_);

    border-width: var(--mag-icon-border-top-md_) var(--mag-icon-border-right-md_) var(--mag-icon-border-bottom-md_)
      var(--mag-icon-border-left-md_);

    border-radius: var(--mag-icon-border-radius-top-left-md_) var(--mag-icon-border-radius-top-right-md_)
      var(--mag-icon-border-radius-bottom-left-md_) var(--mag-icon-border-radius-bottom-right-md_);

    border-style: var(--mag-icon-border-style-md_);
    border-color: var(--mag-icon-border-color-md_);

    justify-content: var(--mag-icon-align-md_);
  }

  @include mq('phablet', 'max') {
    float: var(--mag-icon-float-sm_);
    background-color: var(--mag-icon-background-color-sm_);
    background-image: var(--mag-icon-background-image-sm_);
    background-position: var(--mag-icon-background-position-sm_);
    background-size: var(--mag-icon-background-size-sm_);
    padding: var(--mag-icon-padding-top-sm_) var(--mag-icon-padding-right-sm_) var(--mag-icon-padding-bottom-sm_)
      var(--mag-icon-padding-left-sm_);

    margin: var(--mag-icon-margin-top-sm_) var(--mag-icon-margin-right-sm_) var(--mag-icon-margin-bottom-sm_)
      var(--mag-icon-margin-left-sm_);

    border-width: var(--mag-icon-border-top-sm_) var(--mag-icon-border-right-sm_) var(--mag-icon-border-bottom-sm_)
      var(--mag-icon-border-left-sm_);

    border-radius: var(--mag-icon-border-radius-top-left-sm_) var(--mag-icon-border-radius-top-right-sm_)
      var(--mag-icon-border-radius-bottom-left-sm_) var(--mag-icon-border-radius-bottom-right-sm_);

    border-style: var(--mag-icon-border-style-sm_);
    border-color: var(--mag-icon-border-color-sm_);

    justify-content: var(--mag-icon-align-sm_);
  }

  @include mq('phone', 'max') {
    float: var(--mag-icon-float-xs_);
    background-color: var(--mag-icon-background-color-xs_);
    background-image: var(--mag-icon-background-image-xs_);
    background-position: var(--mag-icon-background-position-xs_);
    background-size: var(--mag-icon-background-size-xs_);
    padding: var(--mag-icon-padding-top-xs_) var(--mag-icon-padding-right-xs_) var(--mag-icon-padding-bottom-xs_)
      var(--mag-icon-padding-left-xs_);

    margin: var(--mag-icon-margin-top-xs_) var(--mag-icon-margin-right-xs_) var(--mag-icon-margin-bottom-xs_)
      var(--mag-icon-margin-left-xs_);

    border-width: var(--mag-icon-border-top-xs_) var(--mag-icon-border-right-xs_) var(--mag-icon-border-bottom-xs_)
      var(--mag-icon-border-left-xs_);

    border-radius: var(--mag-icon-border-radius-top-left-xs_) var(--mag-icon-border-radius-top-right-xs_)
      var(--mag-icon-border-radius-bottom-left-xs_) var(--mag-icon-border-radius-bottom-right-xs_);

    border-style: var(--mag-icon-border-style-xs_);
    border-color: var(--mag-icon-border-color-xs_);

    justify-content: var(--mag-icon-align-xs_);
  }
}

.mag-icon-wrapper {
  background-color: var(--icon-background-color);
  border-width: var(--icon-border-width);
  border-color: var(--icon-border-color);
  border-style: var(--icon-border-style);

  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;

  .mag-icon {
    color: var(--icon-color);
  }

  &:hover {
    background-color: var(--icon-hover-background-color);
    border-color: var(--icon-hover-border-color);

    .mag-icon {
      color: var(--icon-hover-color);
    }
  }
}

.mgz-icon-size {
  &-xs {
    width: 2.5em;
    height: 2.5em;
    .mgz-icon-element {
      font-size: 1.2em;
    }
  }

  &-sm {
    width: 3.15em;
    height: 3.15em;
    .mgz-icon-element {
      font-size: 1.6em;
    }
  }

  &-md {
    width: 4em;
    height: 4em;
    .mgz-icon-element {
      font-size: 2.15em;
    }
  }

  &-lg {
    width: 5em;
    height: 5em;
    .mgz-icon-element {
      font-size: 2.85em;
    }
  }

  &-xl {
    width: 7.15em;
    height: 7.15em;
    .mgz-icon-element {
      font-size: 5em;
    }
  }
}
</style>
