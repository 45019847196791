<template>
  <div
    class="MagCeSeparator"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      '--mag-ce-separator-width': data.el_width ? `${data.el_width || 0}px` : '100%',
      '--mag-ce-separator-label-align': data.title_align,
      '--mag-ce-separator-label-color': data.title_color,

      ...containerStyles,
    }"
  >
    <div class="pf-separator-content">
      <SfDivider
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-style': data.style,
          'border-width': data.line_weight + 'px',
        }"
      />
      <component :is="data.title_tag" v-if="data.title" class="pf-separator-label" :class="data.el_inner_class">
        <template v-if="data.add_icon && data.icon_position === 'left'">
          <i :class="data.icon"></i>
        </template>
        {{ data.title }}
        <template v-if="data.add_icon && data.icon_position !== 'left'">
          <i :class="data.icon"></i>
        </template>
      </component>
      <SfDivider
        v-if="data.title || data.icon"
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-style': data.style,
          'border-width': data.line_weight + 'px',
        }"
      />
    </div>
  </div>
</template>

<script>
import 'animate.css';
import { SfDivider } from '@storefront-ui/vue';
import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeSeparator',
  components: {
    SfDivider,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    return {
      baseUrl,
    };
  },
  computed: {
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-ce-separator-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-ce-separator-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-ce-separator-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-ce-separator-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-ce-separator-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-ce-separator-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-ce-separator-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-ce-separator-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-ce-separator-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-ce-separator-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-ce-separator-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-ce-separator-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-ce-separator-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-ce-separator-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-ce-separator-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-ce-separator-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-ce-separator-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-ce-separator-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-ce-separator-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-ce-separator-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-ce-separator-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-ce-separator-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-ce-separator-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-ce-separator-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-ce-separator-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-ce-separator-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-ce-separator-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
@import '~/static/css/fontawesome5.css';
@import '~/static/css/openiconic.min.css';

.MagCeSeparator {
  @include DesignOptions(separator);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(separator, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(separator, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(separator, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(separator, -xs_);
  }

  .pf-separator-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: var(--mag-ce-separator-width);
    margin: 0 auto;

    > .sf-divider {
      flex: 1 1 0;
    }
  }

  .pf-separator-label {
    margin: 0 15px;

    color: var(--mag-ce-separator-label-color);
    text-align: var(--mag-ce-separator-label-align);
  }
}
</style>
