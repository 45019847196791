export default `
query getCmsPageMetaData($identifier: String!){
	cmsPage(identifier: $identifier) {
		identifier

		meta_title
		meta_description
		meta_keywords
		meta_robots

		mw_canonical_url {
				url
				code
		}
		mw_hreflangs {
			items {
				url
				code
			}
		}

		mw_seo_markup {
			social_markup
			rich_snippets {
					website
					seller
					webpage
			}

		}

	}
}`;
