<template>
  <div
    class="MagCeVideo"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      ...containerStyles,
    }"
  >
    <div
      class="pf-separator-content"
      v-if="data.title"
      :style="{
        '--divider-border-position': data.line_position === 'bottom' ? 'end' : 'center',
        '--mag-video-widget-align': data.title_align,
      }"
    >
      <SfDivider
        v-if="data.title_align !== 'left' && data.show_line"
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-weight': data.line_weight,
        }"
      />
      <component :is="data.title_tag" class="pf-separator-label" :class="data.el_inner_class">
        <div
          class="mag-ce-video-title"
          :style="{
            '--mag-video-widget-title-color': data.title_color,
            '--mag-video-widget-title-spacing': data.video_description_spacing,
            '--mag-video-widget-title-font-weight': data.video_description_font_weight,
            '--mag-video-widget-title-font-size': data.video_description_font_size,
          }"
        >
          {{ data.title }}
        </div>
        <div class="mag-ce-video-description">
          {{ data.description }}
        </div>
      </component>
      <SfDivider
        v-if="data.title_align !== 'right' && data.show_line"
        :style="{
          '--divider-border-color': data.color,
          '--divider-border-weight': data.line_weight,
        }"
      />
    </div>
    <PfVideo
      :src="data.link"
      :autoplay="data.autoplay == '1' ? true : false"
      :mute="data.mute == '1' ? true : false"
      :loop="Boolean(data.loop)"
      :relatedvideos="data.related_videos"
      :modestbranding="data.modest_branding"
      :youtubeprivacy="data.youtube_privacy"
      :start="data.start_at"
      :end="data.end_at"
      :preview-enabled="data.show_preview_image"
      :preview-url="data.preview_image"
      :show-play-icon="data.show_play_icon"
      :play-icon="data.play_icon"
      :play-icon-size="data.play_icon_size"
      :play-icon-color="data.play_icon_color"
      :title="data.video_title"
      :description="data.video_description"
      :style="{
        '--video-player-title-color': data.video_title_color,
        '--video-player-title-margin': data.video_title_spacing,
        '--video-player-title-size': data.video_title_font_size,
        '--video-player-title-weight': data.video_title_font_weight,
        '--video-player-description-color': data.video_description_color,
        '--video-player-description-margin': data.video_description_spacing,
        '--video-player-description-size': data.video_description_font_size,
        '--video-player-description-weight': data.video_description_font_weight,
      }"
    />
  </div>
</template>

<script>
import 'animate.css';
import { SfDivider } from '@storefront-ui/vue';
import PfVideo from '~/components/atoms/PfVideo.vue';

export default {
  name: 'MagCeVideo',
  components: {
    PfVideo,
    SfDivider,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  computed: {
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-video-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-video-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-video-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-video-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-video-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-video-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-video-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-video-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-video-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-video-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-video-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-video-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-video-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-video-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-video-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-video-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-video-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-video-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-video-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-video-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-video-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-video-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-video-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-video-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-video-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-video-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-video-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';

.MagCeVideo {
  @include DesignOptions(video);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(video, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(video, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(video, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(video, -xs_);
  }
}

.pf-separator-content {
  display: flex;
  justify-content: center;
  align-items: var(--divider-border-position);
  flex-direction: row;
  margin: 0 auto;

  > .sf-divider {
    flex: 1 1 0;
  }

  .pf-separator-label {
    text-align: var(--mag-video-widget-align);
  }
}

.mag-ce-video-title {
  color: var(--mag-video-widget-title-color);
}

.mag-ce-video-description {
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

::v-deep {
  .pf-video-player-container .cmppreviewroot {
    margin-bottom: -56.25% !important;
  }
}
</style>
