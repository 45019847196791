export default `
query getProductMetaData($sku: String!){
	products(filter: { sku: { eq: $sku } }) {
		items {
			sku
			meta_title
			meta_description
			meta_keyword
			canonical_url
			meta_robots
			manufacturer
			categories {
				uid
				name
			}

			mw_canonical_url {
				url
				code
			}
			mw_hreflangs {
				items {
					url
					code
				}
			}

			mw_seo_markup {
				social_markup
				rich_snippets {
						website
						seller
						product
				}
			}

		}
	}
}`;
