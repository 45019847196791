<template>
  <div
    class="MagCeSocialIcon"
    :class="[
      data.el_class,
      data.animation_in ? `animate__animated animate__${data.animation_in} animate__delay-1s` : '',
      data.animation_delay ? `animate__delay-${data.animation_delay}s` : '',
      data.animation_infinite ? `animate__infinite` : '',
    ]"
    :id="data.el_id"
    :style="{
      '--mag-social-icon-radius': `${data.icon_radius || 3}px`,
      '--mag-social-icon-font-size': `${data.icon_size || 14}px`,
      ...containerStyles,
    }"
  >
    <div class="social-icons-wrapper" :class="data.el_inner_class">
      <template v-for="item in data.items">
        <div class="social-icons-item">
          <a
            :href="item.link"
            :target="data.link_target"
            rel="nofollow"
            :style="{
              '--social-icon-background-color': item.background_color,
              '--social-icon-hover-background-color': item.hover_background_color,
            }"
          >
            <i :class="getIconClasses(item.icon)"></i>
          </a>

          <a
            :href="item.link"
            :target="data.link_target"
            v-if="data.follow_button"
            class="social-icons-follow-button"
            rel="nofollow"
          >
            {{ $t('Follow') }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import 'animate.css';
import '~/app/code/Magezon/Core/view/base/web/css/font-awesome.all.min.css';
import { SfButton } from '@storefront-ui/vue';

import { useContext } from '@nuxtjs/composition-api';

export default {
  name: 'MagCeSocialIcon',
  components: {
    SfButton,
  },
  props: {
    /**
     * Content elements data props
     */
    data: {
      type: [Object, Array],
      required: true,
    },
  },
  setup() {
    const context = useContext();
    const { magentoBaseUrl } = context.$vsf.$magento.config;

    const baseUrl = magentoBaseUrl + 'media/';

    const getIconClasses = (classes) => {
      let newClasses = classes;
      let cPart = classes.split(' ');
      cPart.forEach((el) => {
        if (el.startsWith('mgz-')) {
          newClasses += ' ' + el.replace('mgz-', '');
        }
      });

      return newClasses;
    };

    return {
      getIconClasses,
      baseUrl,
    };
  },
  computed: {
    containerStyles() {
      let result = {};

      const responsive = ['', 'lg_', 'md_', 'sm_', 'xs_'];

      result['--mag-social-icon-z-index'] = this.data.z_index;
      for (let i = 0; i < responsive.length; i++) {
        const responsiveStyles = responsive[i];

        result[`--mag-social-icon-padding-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_top`] || this.data.padding_top || 0
        }px`;
        result[`--mag-social-icon-padding-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_right`] || this.data.padding_right || 0
        }px`;
        result[`--mag-social-icon-padding-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_bottom`] || this.data.padding_bottom || 0
        }px`;
        result[`--mag-social-icon-padding-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}padding_left`] || this.data.padding_left || 0
        }px`;
        result[`--mag-social-icon-margin-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_top`] || this.data.margin_top || 0
        }px`;
        result[`--mag-social-icon-margin-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_right`] || this.data.margin_right || 0
        }px`;
        result[`--mag-social-icon-margin-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_bottom`] || this.data.margin_bottom || 0
        }px`;
        result[`--mag-social-icon-margin-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}margin_left`] || this.data.margin_left || 0
        }px`;
        result[`--mag-social-icon-border-top${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_width`] || this.data.border_top_width || 0
        }px`;
        result[`--mag-social-icon-border-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_right_width`] || this.data.border_right_width || 0
        }px`;
        result[`--mag-social-icon-border-bottom${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_width`] || this.data.border_bottom_width || 0
        }px`;
        result[`--mag-social-icon-border-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_left_width`] || this.data.border_left_width || 0
        }px`;
        result[`--mag-social-icon-border-style${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_style`] || this.data.border_style || 'none';
        result[`--mag-social-icon-border-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}border_color`] || this.data.border_color || '#222';
        result[`--mag-social-icon-border-radius-top-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_left_radius`] || this.data.border_top_left_radius || 0
        }px`;
        result[`--mag-social-icon-border-radius-top-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_top_right_radius`] || this.data.border_top_right_radius || 0
        }px`;
        result[`--mag-social-icon-border-radius-bottom-left${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_left_radius`] || this.data.border_bottom_left_radius || 0
        }px`;
        result[`--mag-social-icon-border-radius-bottom-right${responsiveStyles ? '-' + responsiveStyles : ''}`] = `${
          this.data[`${responsiveStyles}border_bottom_right_radius`] || this.data.border_bottom_right_radius || 0
        }px`;
        result[`--mag-social-icon-min-height${responsiveStyles ? '-' + responsiveStyles : ''}`] = this.data.min_height
          ? `${this.data[`${responsiveStyles}min_height`] || this.data.min_height || 0}px`
          : '';
        result[`--mag-social-icon-float${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}el_float`] || this.data.el_float || 'none';
        result[`--mag-social-icon-align${responsiveStyles ? '-' + responsiveStyles : ''}`] =
          this.data[`${responsiveStyles}align`] || this.data.align;

        if (this.data[`${responsiveStyles}background_image`]) {
          result[`--mag-social-icon-background-image${responsiveStyles ? '-' + responsiveStyles : ''}`] = `url('${
            this.baseUrl
          }/${this.data[`${responsiveStyles}background_image`] || this.data.background_image}')`;
          result[`--mag-social-icon-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
          result[`--mag-social-icon-background-position${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_position`] || this.data.background_position
              ? (this.data[`${responsiveStyles}background_position`] || this.data.background_position).replace('_', ' ')
              : 'top center';
          result[`--mag-social-icon-background-size${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_style`] || this.data.background_style || 'auto';
        } else {
          result[`--mag-social-icon-background-color${responsiveStyles ? '-' + responsiveStyles : ''}`] =
            this.data[`${responsiveStyles}background_color`] || this.data.background_color || 'transparent';
        }
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'mixins';
.MagCeSocialIcon {
  @include DesignOptions(social-icon);

  @include mq('tablet-wide', 'max') {
    @include DesignOptions(social-icon, -lg_);
  }

  @include mq('tablet', 'max') {
    @include DesignOptions(social-icon, -md_);
  }

  @include mq('phablet', 'max') {
    @include DesignOptions(social-icon, -sm_);
  }

  @include mq('phone', 'max') {
    @include DesignOptions(social-icon, -xs_);
  }

  .social-icons-wrapper {
    display: flex;
    justify-content: center;

    .social-icons-item {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;
      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    i {
      font-size: var(--mag-social-icon-font-size);
      transition: all 0.3s;
      border-radius: var(--mag-social-icon-radius);
      box-sizing: content-box;
      width: calc(var(--mag-social-icon-font-size) * 2);
      height: calc(var(--mag-social-icon-font-size) * 2);
      padding: 0;
      position: relative;
      text-align: center;
      text-decoration: none;
      line-height: 32px;
      color: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }

    a {
      text-decoration: none;
    }

    .social-icons-follow-button {
      transition: all 0.3s;
      border-radius: 3px;
      align-self: center;
      margin-right: 25px;
      padding: 0 12px;
      color: rgba(0, 0, 0, 0.5);
      background: rgba(0, 0, 0, 0.1);
      font-size: 14px;
      line-height: 1.6em;

      &:hover {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.6);
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }

  .mgz-fa-facebook-f {
    background: var(--social-icon-background-color, #3b5998);

    &:hover {
      background: var(--social-icon-hover-background-color, #3b5998);
    }
  }

  .mgz-fa-twitter {
    background: var(--social-icon-background-color, #00aced);

    &:hover {
      background: var(--social-icon-hover-background-color, #00aced);
    }
  }

  .mgz-fa-linkedin-in {
    background: var(--social-icon-background-color, #007bb6);

    &:hover {
      background: var(--social-icon-hover-background-color, #007bb6);
    }
  }

  .mgz-fa-instagram {
    background: var(--social-icon-background-color, #517fa4);

    &:hover {
      background: var(--social-icon-hover-background-color, #517fa4);
    }
  }

  .mgz-fa-youtube {
    background: var(--social-icon-background-color, #a82400);

    &:hover {
      background: var(--social-icon-hover-background-color, #a82400);
    }
  }

  .mgz-fa-pinterest-p {
    background: var(--social-icon-background-color, #cb2027);

    &:hover {
      background: var(--social-icon-hover-background-color, #cb2027);
    }
  }

  .mgz-fa-tumblr {
    background: var(--social-icon-background-color, #32506d);

    &:hover {
      background: var(--social-icon-hover-background-color, #32506d);
    }
  }

  .mgz-fa-skype {
    background: var(--social-icon-background-color, #12a5f4);

    &:hover {
      background: var(--social-icon-hover-background-color, #12a5f4);
    }
  }

  .mgz-fa-flickr {
    background: var(--social-icon-background-color, #ff0084);

    &:hover {
      background: var(--social-icon-hover-background-color, #ff0084);
    }
  }

  .mgz-fa-dribbble {
    background: var(--social-icon-background-color, #ea4c8d);

    &:hover {
      background: var(--social-icon-hover-background-color, #ea4c8d);
    }
  }

  .mgz-fa-vimeo-v {
    background: var(--social-icon-background-color, #45bbff);

    &:hover {
      background: var(--social-icon-hover-background-color, #45bbff);
    }
  }

  .mgz-fa-rss {
    background: var(--social-icon-background-color, #ff8a3c);

    &:hover {
      background: var(--social-icon-hover-background-color, #ff8a3c);
    }
  }

  .mgz-fa-behance {
    background: var(--social-icon-background-color, #191919);

    &:hover {
      background: var(--social-icon-hover-background-color, #191919);
    }
  }
}
</style>
