export default `
	query getCmsBlockData($identifiers: [String]!) {
		cmsBlocks(identifiers: $identifiers) {
			items {
				page_builder_content
				identifier
				content
			}
		}
	}
`;

export interface CmsBlockContentQueryResponseInterface {
  cmsBlocks?: {
    items?: {
      page_builder_content?: string;
      identifier?: string;
      content?: string;
    };
  };
}
