var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MagCeSocialIcon",class:[
    _vm.data.el_class,
    _vm.data.animation_in ? ("animate__animated animate__" + (_vm.data.animation_in) + " animate__delay-1s") : '',
    _vm.data.animation_delay ? ("animate__delay-" + (_vm.data.animation_delay) + "s") : '',
    _vm.data.animation_infinite ? "animate__infinite" : '' ],style:(Object.assign({}, {'--mag-social-icon-radius': ((_vm.data.icon_radius || 3) + "px"),
    '--mag-social-icon-font-size': ((_vm.data.icon_size || 14) + "px")},
    _vm.containerStyles)),attrs:{"id":_vm.data.el_id}},[_c('div',{staticClass:"social-icons-wrapper",class:_vm.data.el_inner_class},[_vm._l((_vm.data.items),function(item){return [_c('div',{staticClass:"social-icons-item"},[_c('a',{style:({
            '--social-icon-background-color': item.background_color,
            '--social-icon-hover-background-color': item.hover_background_color,
          }),attrs:{"href":item.link,"target":_vm.data.link_target,"rel":"nofollow"}},[_c('i',{class:_vm.getIconClasses(item.icon)})]),_vm._v(" "),(_vm.data.follow_button)?_c('a',{staticClass:"social-icons-follow-button",attrs:{"href":item.link,"target":_vm.data.link_target,"rel":"nofollow"}},[_vm._v("\n          "+_vm._s(_vm.$t('Follow'))+"\n        ")]):_vm._e()])]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }