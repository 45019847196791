import { render, staticRenderFns } from "./PfContactForm.vue?vue&type=template&id=180a2e1e&scoped=true&"
import script from "./PfContactForm.vue?vue&type=script&lang=ts&"
export * from "./PfContactForm.vue?vue&type=script&lang=ts&"
import style0 from "./PfContactForm.vue?vue&type=style&index=0&id=180a2e1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "180a2e1e",
  null
  
)

export default component.exports