<template>
  <div class="pf-tabs__body" v-if="isActive">
    <div class="pf-tabs__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PfTabContent',
  inject: ['activeTab'],
  props: {
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isActive() {
      return this.activeTab.value === this.index;
    },
  },
};
</script>

<style lang="scss" scoped>
.pf-tabs {
  &__body {
    background: #f8f8f8;
    flex: 1 1 0;
  }
  &__content {
    height: 100%;
  }
}
</style>
