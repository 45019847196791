export function htmlEntitiesDecoder(input: string) {
  const convertHtml = () => {
    try {
      var txt = document.createElement('textarea');
      txt.innerHTML = input;
      return txt.value;
    } catch {
      return input;
    }
  };
  const html = convertHtml();
  return html === 'undefined' ? '' : html;
}
