import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _760025b0 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _321775f4 = () => interopDefault(import('../pages/CustomerAccountConfirm.vue' /* webpackChunkName: "pages/CustomerAccountConfirm" */))
const _55e8e2cc = () => interopDefault(import('../pages/FfSearchPage.vue' /* webpackChunkName: "pages/FfSearchPage" */))
const _6928c942 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _4c5574d4 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _8d44421c = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7581bcf0 = () => interopDefault(import('../pages/PaypalOrder.vue' /* webpackChunkName: "pages/PaypalOrder" */))
const _986f29bc = () => interopDefault(import('../pages/PaypalOrderCancel.vue' /* webpackChunkName: "pages/PaypalOrderCancel" */))
const _73ce7566 = () => interopDefault(import('../pages/ResetPasswordPage.vue' /* webpackChunkName: "pages/ResetPasswordPage" */))
const _1d7860a5 = () => interopDefault(import('../pages/RoutePage.vue' /* webpackChunkName: "pages/RoutePage" */))
const _3f771c7a = () => interopDefault(import('../pages/SearchPage.vue' /* webpackChunkName: "pages/SearchPage" */))
const _14884558 = () => interopDefault(import('../pages/ShipmentTracking.vue' /* webpackChunkName: "pages/ShipmentTracking" */))
const _00164076 = () => interopDefault(import('../pages/blog/events/index.vue' /* webpackChunkName: "pages/blog/events/index" */))
const _3f3d970a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _c9705e2c = () => interopDefault(import('../modules/checkout/pages/Checkout/ShippingBillingAddresses.vue' /* webpackChunkName: "" */))
const _23a1c861 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _895f12f6 = () => interopDefault(import('../modules/checkout/pages/Checkout/ShippingSelectionStep.vue' /* webpackChunkName: "" */))
const _42a4568a = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _72539abd = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _e8347dd2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _0960d385 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _5d16bc72 = () => interopDefault(import('../modules/customer/pages/MyAccount/CustomerSupport.vue' /* webpackChunkName: "" */))
const _559cf282 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _35aa7cc1 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _ab40f7f6 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _be74156a = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _0a759ec5 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _df893fde = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _f1949a2e = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _04c5570e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _f5c22e86 = () => interopDefault(import('../modules/checkout/pages/MainCart.vue' /* webpackChunkName: "" */))
const _fc2c33b2 = () => interopDefault(import('../pages/blog/events/_event.vue' /* webpackChunkName: "pages/blog/events/_event" */))
const _07fe917f = () => interopDefault(import('../pages/blog/s/_query.vue' /* webpackChunkName: "pages/blog/s/_query" */))
const _7b0feeaa = () => interopDefault(import('../pages/blog/tag/_slug.vue' /* webpackChunkName: "pages/blog/tag/_slug" */))
const _0d57c751 = () => interopDefault(import('../pages/blog/_article.vue' /* webpackChunkName: "pages/blog/_article" */))
const _39a1e3b1 = () => interopDefault(import('../pages/blog/_cat.vue' /* webpackChunkName: "pages/blog/_cat" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog/",
    component: _760025b0,
    pathToRegexpOptions: {"strict":true},
    name: "blog___shop"
  }, {
    path: "/CustomerAccountConfirm/",
    component: _321775f4,
    pathToRegexpOptions: {"strict":true},
    name: "CustomerAccountConfirm___shop"
  }, {
    path: "/FfSearchPage/",
    component: _55e8e2cc,
    pathToRegexpOptions: {"strict":true},
    name: "FfSearchPage___shop"
  }, {
    path: "/Home/",
    component: _6928c942,
    pathToRegexpOptions: {"strict":true},
    name: "Home___shop"
  }, {
    path: "/Login/",
    component: _4c5574d4,
    pathToRegexpOptions: {"strict":true},
    name: "Login___shop"
  }, {
    path: "/Page/",
    component: _8d44421c,
    pathToRegexpOptions: {"strict":true},
    name: "Page___shop"
  }, {
    path: "/PaypalOrder/",
    component: _7581bcf0,
    pathToRegexpOptions: {"strict":true},
    name: "PaypalOrder___shop"
  }, {
    path: "/PaypalOrderCancel/",
    component: _986f29bc,
    pathToRegexpOptions: {"strict":true},
    name: "PaypalOrderCancel___shop"
  }, {
    path: "/ResetPasswordPage/",
    component: _73ce7566,
    pathToRegexpOptions: {"strict":true},
    name: "ResetPasswordPage___shop"
  }, {
    path: "/RoutePage/",
    component: _1d7860a5,
    pathToRegexpOptions: {"strict":true},
    name: "RoutePage___shop"
  }, {
    path: "/SearchPage/",
    component: _3f771c7a,
    pathToRegexpOptions: {"strict":true},
    name: "SearchPage___shop"
  }, {
    path: "/ShipmentTracking/",
    component: _14884558,
    pathToRegexpOptions: {"strict":true},
    name: "ShipmentTracking___shop"
  }, {
    path: "/blog/events/",
    component: _00164076,
    pathToRegexpOptions: {"strict":true},
    name: "blog-events___shop"
  }, {
    path: "/shipping/tracking/",
    component: _14884558,
    name: "shipping-tracking___shop"
  }, {
    path: "/shop/checkout/",
    component: _3f3d970a,
    name: "checkout___shop",
    children: [{
      path: "addresses/",
      component: _c9705e2c,
      name: "addresses___shop"
    }, {
      path: "payment/",
      component: _23a1c861,
      name: "payment___shop"
    }, {
      path: "shipping/",
      component: _895f12f6,
      name: "shipping___shop"
    }, {
      path: "thank-you/",
      component: _42a4568a,
      name: "thank-you___shop"
    }, {
      path: "user-account/",
      component: _72539abd,
      name: "user-account___shop"
    }]
  }, {
    path: "/shop/customer-account-confirm/",
    component: _321775f4,
    name: "customer-account-confirm___shop"
  }, {
    path: "/shop/customer-reset-password/",
    component: _73ce7566,
    name: "reset-password___shop"
  }, {
    path: "/shop/customer/",
    component: _e8347dd2,
    meta: {"titleLabel":"My Account"},
    name: "customer___shop",
    children: [{
      path: "addresses-details/",
      component: _0960d385,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___shop"
    }, {
      path: "customer-support/",
      component: _5d16bc72,
      meta: {"titleLabel":"Support"},
      name: "customer-support___shop"
    }, {
      path: "my-newsletter/",
      component: _559cf282,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___shop"
    }, {
      path: "my-profile/",
      component: _35aa7cc1,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___shop"
    }, {
      path: "my-reviews/",
      component: _ab40f7f6,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___shop"
    }, {
      path: "my-wishlist/",
      component: _be74156a,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___shop"
    }, {
      path: "order-history/",
      component: _0a759ec5,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___shop"
    }, {
      path: "addresses-details/create/",
      component: _df893fde,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___shop"
    }, {
      path: "addresses-details/edit/:addressId/",
      component: _f1949a2e,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___shop"
    }, {
      path: "order-history/:orderId/",
      component: _04c5570e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___shop"
    }]
  }, {
    path: "/shop/paypal-order-cancel/",
    component: _986f29bc,
    name: "paypal-order-cancel___shop"
  }, {
    path: "/shop/paypal-order/",
    component: _7581bcf0,
    name: "paypal-order___shop"
  }, {
    path: "/shop/catalogsearch/result/",
    component: _55e8e2cc,
    name: "search-page___shop"
  }, {
    path: "/shop/checkout/cart/",
    component: _f5c22e86,
    name: "checkout-cart___shop"
  }, {
    path: "/blog/events/:event/",
    component: _fc2c33b2,
    pathToRegexpOptions: {"strict":true},
    name: "blog-events-event___shop"
  }, {
    path: "/blog/s/:query?/",
    component: _07fe917f,
    pathToRegexpOptions: {"strict":true},
    name: "blog-s-query___shop"
  }, {
    path: "/blog/tag/:slug?/",
    component: _7b0feeaa,
    pathToRegexpOptions: {"strict":true},
    name: "blog-tag-slug___shop"
  }, {
    path: "/blog/:article/",
    component: _0d57c751,
    pathToRegexpOptions: {"strict":true},
    name: "blog-article___shop"
  }, {
    path: "/blog/:cat/",
    component: _39a1e3b1,
    pathToRegexpOptions: {"strict":true},
    name: "blog-cat___shop"
  }, {
    path: "/shop/:slug+/",
    component: _1d7860a5,
    name: "route-page-products___shop"
  }, {
    path: "/",
    component: _1d7860a5,
    name: "home___shop"
  }, {
    path: "/:slug+/",
    component: _1d7860a5,
    name: "route-page___shop"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
