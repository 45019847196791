export default function scrollBehavior(_to, _from, savedPosition) {
  if (_to.hash) {
    this.app.$scrollTo(_to.hash, 700);
    return { selector: _to.hash };
  } else if (savedPosition) {
    return savedPosition;
  } else {
    this.app.$scrollTo('#app', 700);
    return { x: 0, y: 0 };
  }
}
